import React from "react";
import search from "../../Assets/Images/search.svg";
import Botton from "../Button/Button";
import SkeletonLoader from "../Pages/Loader/Skeleton loader/SkeletonLoader";

const FindMyVehicleMob = (props) => {
    const {
        ShowMakeBottom,
        showBottomContent,
        handleSearchQuery,
        SearchedTerm,
        backToHome,
        ContentType,
        handleMakeData,
        SearchedTermModel,
        ManualInpValue,
        setContentType,
        reorderedCarModels,
        handleModelData,
        setShowModelBottom,
        ShowModelBottom,
        reorderedCarBrands,
        fuelList,
        handleFuelTypeValue,
        SearchedTermVarient,
        reorderedCarVarients,
        handleVarientData,
        rtoArray,
        handleRtoValue,
        SearchedTermRto,
        setShowRtoBottom,
        ShowRtoBottom,
        yearOfReg,
        handleRegYearValue,
        currentYear,
        vehicleType,
    } = props;

    const makeCount = reorderedCarBrands?.length;
    const modelCount = reorderedCarModels?.length;
    const rtoCount = rtoArray?.length;

    console.log(reorderedCarModels, "ddddd");

    let vehicleTypeName = vehicleType === "4W" ? "car" : "bike";

    let loaderState = false;

    return (
        <div className="find-my-car-mob-container">
            {ContentType === "Make" && (
                <div style={{ position: "relative" }}>
                    <div className="content-block-fmv">
                        <h2>
                            What is your {`${vehicleTypeName}`} <br /> brand?
                        </h2>
                        <div className="brandLogoContainer-input">
                            <img src={search} className="search-fmc" />
                            <input
                                type="text"
                                className="fmcInput"
                                id="fmcInput"
                                name=""
                                value={SearchedTerm}
                                placeholder={`Search your ${vehicleTypeName} brand`}
                                onChange={(e) => handleSearchQuery(e.target.value, "make")}
                            />
                        </div>
                        <p>Popular Brands</p>
                    </div>
                    {
                        !reorderedCarBrands ? (
                            <div className="skeleton-container-fmc">
                                <SkeletonLoader height={60} width={20} />
                                <SkeletonLoader height={60} width={20} />
                                <SkeletonLoader height={60} width={20} />
                                <SkeletonLoader height={60} width={20} />
                                <SkeletonLoader height={60} width={20} />
                                <SkeletonLoader height={60} width={20} />
                                <SkeletonLoader height={60} width={20} />
                                <SkeletonLoader height={60} width={20} />
                                <SkeletonLoader height={60} width={20} />
                                <SkeletonLoader height={60} width={20} />
                            </div>
                        ) : (
                            <div className="content-block-fmv-tab">
                                {!ShowMakeBottom ? (
                                    <>
                                        {Array.isArray(reorderedCarBrands) &&
                                            reorderedCarBrands?.map(
                                                (data, key) =>
                                                    key < 12 && (
                                                        <button
                                                            key={key}
                                                            className={
                                                                ManualInpValue?.make === data.make
                                                                    ? "mob-fmv-btn mob-fmv-btn-active"
                                                                    : "mob-fmv-btn"
                                                            }
                                                            onClick={() => handleMakeData(data.make, data.make)}
                                                        >
                                                            {data.make}
                                                        </button>
                                                    )
                                            )}
                                    </>
                                ) : (
                                    <>
                                        {Array.isArray(reorderedCarBrands) &&
                                            reorderedCarBrands?.map((data, key) => (
                                                <button
                                                    key={key}
                                                    className={
                                                        ManualInpValue?.make === data.make
                                                            ? "mob-fmv-btn mob-fmv-btn-active"
                                                            : "mob-fmv-btn"
                                                    }
                                                    onClick={() => handleMakeData(data.make, data.make)}
                                                >
                                                    {data.make}
                                                </button>
                                            ))}
                                    </>
                                )}
                            </div>
                        )
                    }

                    <div className="button-container-fmv">
                        <Botton
                            text="Back"
                            className="btn-fmv secondry_btn"
                            event={backToHome}
                        />
                        <Botton
                            text={
                                !ShowMakeBottom
                                    ? `View all ${makeCount ? makeCount : ""} brands`
                                    : "Hide"
                            }
                            className="btn-fmv btn-fmv-primary"
                            event={() => showBottomContent("Make")}
                        />
                    </div>
                </div>
            )}

            {ContentType === "Model" && (
                <div style={{ position: "relative" }}>
                    <div className="content-block-fmv">
                        {/* <img src={backIcon} onClick={() => setContentType("Make")} /> */}
                        <h2>
                            Which {ManualInpValue?.make} <br /> {`${vehicleTypeName}`}?
                        </h2>
                        <div className="brandLogoContainer-input">
                            <img src={search} className="search-fmc" />
                            <input
                                type="text"
                                className="fmcInput"
                                id="fmcInput"
                                name=""
                                value={SearchedTermModel}
                                placeholder={`Search your ${vehicleTypeName} model`}
                                onChange={(e) => handleSearchQuery(e.target.value, "model")}
                            />
                        </div>
                        <p> Popular Models </p>
                    </div>

                    {
                        !reorderedCarModels ? (
                            <div className="skeleton-container-fmc">
                                <SkeletonLoader height={60} width={20} />
                                <SkeletonLoader height={60} width={20} />
                                <SkeletonLoader height={60} width={20} />
                                <SkeletonLoader height={60} width={20} />
                                <SkeletonLoader height={60} width={20} />
                                <SkeletonLoader height={60} width={20} />
                                <SkeletonLoader height={60} width={20} />
                                <SkeletonLoader height={60} width={20} />
                                <SkeletonLoader height={60} width={20} />
                                <SkeletonLoader height={60} width={20} />
                            </div>
                        ) : (
                            <div className="content-block-fmv-tab">
                                {!ShowModelBottom ? (
                                    <>
                                        {Array.isArray(reorderedCarModels) &&
                                            reorderedCarModels?.map(
                                                (data, key) =>
                                                    key < 12 && (
                                                        <button
                                                            key={key}
                                                            className={
                                                                vehicleType === "4W" ? (
                                                                    ManualInpValue?.model === data.Model
                                                                        ? "mob-fmv-btn mob-fmv-btn-active"
                                                                        : "mob-fmv-btn"
                                                                ) : (
                                                                    ManualInpValue?.model === data.model
                                                                        ? "mob-fmv-btn mob-fmv-btn-active"
                                                                        : "mob-fmv-btn"
                                                                )
                                                            }
                                                            onClick={() =>
                                                                handleModelData(vehicleType === "4W" ? data.Model : data.model, vehicleType === "4W" ? data.Model : data.model)
                                                            }
                                                        >
                                                            {vehicleType === "4W" ? data.Model : data.model}
                                                        </button>
                                                    )
                                            )}
                                    </>
                                ) : (
                                    <>
                                        {Array.isArray(reorderedCarModels) &&
                                            reorderedCarModels?.map((data, key) => (
                                                <button
                                                    key={key}
                                                    className={
                                                        vehicleType === "4W" ? (
                                                            ManualInpValue?.model === data.Model
                                                                ? "mob-fmv-btn mob-fmv-btn-active"
                                                                : "mob-fmv-btn"
                                                        ) : (ManualInpValue?.model === data.model
                                                            ? "mob-fmv-btn mob-fmv-btn-active"
                                                            : "mob-fmv-btn")}
                                                    onClick={() => handleModelData(vehicleType === "4W" ? data.Model : data.model, vehicleType === "4W" ? data.Model : data.model)}
                                                >
                                                    {vehicleType === "4W" ? data.Model : data.model}
                                                </button>
                                            ))}
                                    </>
                                )}
                            </div>
                        )
                    }

                    <div className="button-container-fmv">
                        <Botton
                            text="Back"
                            className="btn-fmv secondry_btn"
                            event={() => setContentType("Make")}
                        />
                        <Botton
                            text={
                                !ShowModelBottom
                                    ? `View all ${modelCount ? modelCount : ""} model's`
                                    : "Hide"
                            }
                            className="btn-fmv btn-fmv-primary"
                            event={() => setShowModelBottom(!ShowModelBottom)}
                        />
                    </div>
                </div>
            )}

            {ContentType === "FuelType" && (
                <div style={{ position: "relative" }}>
                    <div className="content-block-fmv content-block-fmv-fuel">
                        <h2>
                            What Is The Fuel <br /> Type?
                        </h2>
                    </div>

                    {
                        !fuelList ? (
                            <div className={`skeleton-container-fmc skeleton-container-fuelList ${!fuelList && 'fuelList_loader_width'} `}>
                                <SkeletonLoader height={60} width={20} />
                                <SkeletonLoader height={60} width={20} />
                                <SkeletonLoader height={60} width={20} />
                                <SkeletonLoader height={60} width={20} />
                                <SkeletonLoader height={60} width={20} />
                            </div>
                        ) : (
                            <div className="content-block-fmv-tab content-block-fmv-tab-fuel">
                                {Array.isArray(fuelList) &&
                                    fuelList?.map((data, key) => (
                                        <button
                                            key={key}
                                            className={
                                                vehicleType === "4W" ? (
                                                    ManualInpValue?.fuelType === data.Fuel_Type
                                                        ? "mob-fmv-btn mob-fmv-btn-fuel mob-fmv-btn-active"
                                                        : "mob-fmv-btn mob-fmv-btn-fuel"
                                                ) : (
                                                    ManualInpValue?.fuelType === data.fuel_type
                                                        ? "mob-fmv-btn mob-fmv-btn-fuel mob-fmv-btn-active "
                                                        : "mob-fmv-btn mob-fmv-btn-fuel"
                                                )
                                            }
                                            onClick={() =>
                                                handleFuelTypeValue(vehicleType === "4W" ? data.Fuel_Type : data.fuel_type)
                                            }
                                        >
                                            {data.Fuel_Type === "INTERNAL_LPG_CNG"
                                                ? "PETROL + CNG/LPG"
                                                : vehicleType === "4W" ? data.Fuel_Type : data.fuel_type}
                                        </button>
                                    ))}
                            </div>
                        )
                    }
                    <div className="button-container-fmv">
                        <Botton
                            text="Back"
                            className="btn-fmv secondry_btn"
                            event={() => setContentType("Model")}
                        />
                    </div>
                </div>
            )}

            {ContentType === "Varient" && (
                <div style={{ position: "relative" }}>
                    <div className="content-block-fmv">
                        <h2>
                            Which {ManualInpValue?.make} {`${vehicleTypeName}`} <br /> Varient?
                        </h2>
                        <div className="brandLogoContainer-input">
                            <img src={search} className="search-fmc" />
                            <input
                                type="text"
                                className="fmcInput"
                                id="fmcInput"
                                name=""
                                value={SearchedTermVarient}
                                placeholder={`Search your ${vehicleTypeName} variant`}
                                onChange={(e) => handleSearchQuery(e.target.value, "variant")}
                            />
                        </div>
                        <p> All Variants </p>
                    </div>

                    {
                        !reorderedCarVarients ? (
                            <div className={`skeleton-container-fmc skeleton-container-fuelList ${!reorderedCarVarients && 'fuelList_loader_width'} `}>
                                <SkeletonLoader height={60} width={20} />
                                <SkeletonLoader height={60} width={20} />
                                <SkeletonLoader height={60} width={20} />
                                <SkeletonLoader height={60} width={20} />
                                <SkeletonLoader height={60} width={20} />
                            </div>
                        ) : (
                            <div
                                className="content-block-fmv-tab"
                                style={{ flexDirection: "column" }}
                            >
                                {Array.isArray(reorderedCarVarients) &&
                                    reorderedCarVarients?.map((data, key) => (
                                        <button
                                            key={key}
                                            className={
                                                ManualInpValue?.varient === data.Variant
                                                    ? "mob-fmv-btn mob-fmv-btn-fuel mob-fmv-btn-active"
                                                    : "mob-fmv-btn mob-fmv-btn-fuel"
                                            }
                                            onClick={() => handleVarientData(data.Variant, data.Cubic_Capacity)}
                                        >
                                            <p>
                                                {data.Variant} <span>{data.Cubic_Capacity}{" "}CC</span>
                                            </p>
                                        </button>
                                    ))}
                            </div>
                        )
                    }

                    <div className="button-container-fmv">
                        <Botton
                            text="Back"
                            className="btn-fmv secondry_btn"
                            event={() => setContentType("FuelType")}
                        />
                    </div>
                </div>
            )}

            {ContentType === "RTO" && (
                <>
                    <div className="content-block-fmv " style={{ paddingBottom: "2.5rem" }} >
                        <h2>
                            Where Was It <br /> Registered?
                        </h2>
                        <div className="brandLogoContainer-input">
                            <img src={search} className="search-fmc" />
                            <input
                                type="text"
                                className="fmcInput"
                                id="fmcInput"
                                value={SearchedTermRto?.toUpperCase()}
                                placeholder="MH-02"
                                onChange={(e) => handleSearchQuery(e.target.value, "rto")}
                            />
                        </div>
                    </div>

                    {
                        !rtoArray ? (
                            <div className={`skeleton-container-fmc skeleton-container-fuelList ${!rtoArray && 'fuelList_loader_width'} `}>
                                <SkeletonLoader height={60} width={20} />
                                <SkeletonLoader height={60} width={20} />
                                <SkeletonLoader height={60} width={20} />
                                <SkeletonLoader height={60} width={20} />
                                <SkeletonLoader height={60} width={20} />
                            </div>
                        ) : (
                            <div
                                className="content-block-fmv-tab"
                                style={{ flexDirection: "column" }}
                            >
                                {!ShowRtoBottom ? (
                                    <>
                                        {Array.isArray(rtoArray) &&
                                            rtoArray.map((data, key) => {
                                                const [code, city] = data.value.split("-");
                                                const formattedCode = code.replace(/.{2}(?!$)/g, "$&-");
                                                return key < 12 ? (
                                                    <button
                                                        key={key}
                                                        className={
                                                            ManualInpValue?.rto === data.value
                                                                ? "mob-fmv-btn mob-fmv-btn-rto mob-fmv-btn-active"
                                                                : "mob-fmv-btn mob-fmv-btn-rto"
                                                        }
                                                        onClick={() => handleRtoValue(data.value, "mobileView")}
                                                    >
                                                        <p>
                                                            {formattedCode}
                                                            <span>{city}</span>
                                                        </p>
                                                    </button>
                                                ) : null;
                                            })}
                                    </>
                                ) : (
                                    <>
                                        {Array.isArray(rtoArray) &&
                                            rtoArray?.map((data, key) => {
                                                const [code, city] = data.value.split("-");
                                                const formattedCode = code.replace(/.{2}(?!$)/g, "$&-");
                                                return (
                                                    <button
                                                        key={key}
                                                        className="mob-fmv-btn mob-fmv-btn-rto"
                                                        onClick={() => handleRtoValue(data.value, "mobileView")}
                                                    >
                                                        <p>
                                                            {formattedCode}
                                                            <span>{city}</span>
                                                        </p>
                                                    </button>
                                                );
                                            })}
                                    </>
                                )}
                            </div>
                        )
                    }

                    <div className="button-container-fmv">
                        <Botton
                            text="Back"
                            className="btn-fmv secondry_btn"
                            event={() => setContentType("Varient")}
                        />
                        <Botton
                            text={
                                !ShowRtoBottom
                                    ? `View all ${rtoCount ? rtoCount : ""} RTO`
                                    : "Hide"
                            }
                            className="btn-fmv btn-fmv-primary"
                            event={() => setShowRtoBottom(!ShowRtoBottom)}
                        />
                    </div>
                </>
            )}

            {ContentType === "regYear" && (
                <>
                    <div className="content-block-fmv content-block-fmv-fuel">
                        <h2>
                            When Was It <br /> Registered?
                        </h2>
                    </div>

                    <div className="content-block-fmv-tab ">
                        {Array.isArray(yearOfReg) &&
                            yearOfReg?.map((data, key) => (
                                <button
                                    key={key}
                                    className={
                                      Number(ManualInpValue.regn_dt) === data.value
                                            ? "mob-fmv-btn mob-fmv-btn-fuel mob-fmv-btn-active"
                                            : "mob-fmv-btn mob-fmv-btn-fuel"
                                    }
                                    onClick={() => handleRegYearValue(data.value, "mobile")}
                                >
                                    {data.value}
                                </button>
                            ))}
                    </div>

                    <div className="button-container-fmv">
                        <Botton
                            text="Back"
                            className="btn-fmv secondry_btn"
                            event={() => setContentType("RTO")}
                        />
                    </div>
                </>
            )}

        </div>
    );
};

export default FindMyVehicleMob;
