import React, { useEffect, useState } from "react";
import "./ProfileCard.css";
import pic from "../../../../Assets/Images/picture.svg";
import deelete from "../../../../Assets/Images/delete.svg";
import InputFields from "../../../InputFields/InputFields";
import Botton from "../../../Button/Button";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import loader2 from "../../../../Assets/Loader/Loader.gif";
import { updateProfileAction } from "../../../../redux/Actions/LoginAction"
import { json } from "react-router-dom";
import ButtonLoader from "../../Loader/Button Loader/ButtonLoader"

const ProfileCard = (props) => {
  const { FormValue, handleInputChange, ClientData, setContentType,setLoading,loading } = props;
 
  const [errors, setErrors] = useState({});
  let updatedDataLocal = localStorage.getItem("updatedDataData");
  let obj = JSON.parse(updatedDataLocal);

  const [updatedData, setUpdatedData] = useState({
    fullName: obj?.fullName ? obj?.fullName : FormValue.fullName,
    email: obj?.email ? obj?.email : FormValue.email,
  });

  const dispatch = useDispatch();
  const token = useSelector((state) => state.getLoggedInStatus.data.loginData.token);

  const handleChange = (e) => {
    handleInputChange(e);
    const { name, value } = e.target;

    let valLow = value.toLowerCase()
    setUpdatedData((prevData) => ({ ...prevData, [name]: name == 'email' ? valLow : value }));

  };

  const handleUpdateClick = async () => {
    const valid = ValidateProfile()
    if (valid) {
   
      let payload = {
        token: token,
        data: { name: updatedData.fullName, email: updatedData.email }
      }
      setLoading(true)
      setContentType("policySection")

      let data = await dispatch(updateProfileAction(payload));
    
     
      if (data?.payload?.status == 1) {
        let obj = {
          name: updatedData.fullName,
          email: updatedData.email
        }
        localStorage.setItem('updatedDataData', JSON.stringify(updatedData))
        setContentType("profileSection")
        setLoading(false)
        toast("Your Profile has been updated successfully", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
    
       
      }else{

      }
    }

  };

  const ValidateProfile = () => {
    const newErrors = {};
    if (!updatedData.fullName) {
      newErrors.name = "Name is Required";
    }
    if (updatedData.fullName && updatedData.fullName?.length < 3) {
      newErrors.name = "Name must be atleast 3  characters ";
    }
    if (!updatedData.email) {
      newErrors.email = "Email is Required";
    }
    let regexEmail = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,3}$/;
    if (updatedData.email && !regexEmail.test(updatedData.email)) {
      newErrors.email = "Email is Invalid";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  return (
    <>
    <ToastContainer/>
     <div id="cover-spin" style={{ display: loading ? "" : "none" }}>
        <img src={loader2} className="gifLoader"></img>
      </div>
      <div className="ProfileCard-container">
        <div className="input_group_pform">
          <div className="inputGropOne_form inputGrop ">
            <InputFields
              value={updatedData.fullName}
              placeholder="Full Name"
              id="fullName"
              type="text"
              name="fullName"
              errState={errors?.name ? true : false}
              errMsg={errors?.name}
              errClassName="input-err"
              onChangeEvent={(e) => handleChange(e)}
            />

          </div>

          <div className="inputGropOne_form inputGrop ">
            <InputFields
              value={FormValue.phoneNumber}
              placeholder="Phone Number"
              id="phoneNumber"
              type="number"
              name="phoneNumber"
              isDisabled={true}
              onChangeEvent={(e) => handleInputChange(e)}
            />
          </div>
        </div>

        <div className="input_group_pform">
          <div className="inputGropOne_form inputGrop ">
            <InputFields
              value={(updatedData.email)}
              placeholder="Email ID"
              id="email"
              type="text"
              name="email"
              errState={errors?.email ? true : false}
              errMsg={errors?.email}
              errClassName="input-err"
              onChangeEvent={(e) => handleChange(e)}
            />
          </div>

          <div className="inputGropOne_form inputGrop "></div>
        </div>

        {/* <div className="heading_rightSide_findMycar">
          <p>My car Documents</p>
          <p className="info_block_left_desc">
            We make sure your documents are confidential with us. <br />
            You can upload your Car RC, Driving Licence, PAN Card Aadhar Card,
            etc
          </p>
        </div>

        <div className="input_group_pform">
          <div className="inputGropOne_form inputGrop inputGrop-profile ">
            <div className="inputGropOne_form inputGrop inputGropKyc-custom ">
              <p>Upload Document</p>
              <input
                type="file"
                className="hideRealUploadBtn"
                id="uploadImg-Aadhar"
                accept="image/*, application/pdf"
                name="userUploadedDocument"
                onChange={(e) => handleInputChange(e)}
                hidden
              />
              <label className="customLabel-kyc" htmlFor="uploadImg-Aadhar">
                <p className="btn-kyc-">upload</p>
              </label>
            </div>
            <p>File cannot be more then 1MB. (JPG or PNG)</p>
          </div>
          <div className="inputGropOne_form inputGrop "></div>
        </div>

        <div className="upload-msg-profile">
          <div className="pic-square">
            <img src={pic} alt="" srcSet="" />
          </div>
          <div className="delete-container">
            <img src={deelete} alt="" srcSet="" />
            <p>RC Copy</p>
          </div>
        </div> */}

        <section className="btnContainer_pform">
          <Botton text="Reset"
            event={console.log("hu")}
            // event={() => setUpdatedData(FormValue)}
            isDisabledButton={true} className="button btn_pForm  secondry_btn" />
          {!loading ?
            <Botton text="Update" event={handleUpdateClick}
              className="button btn_pForm" /> : <ButtonLoader className="button btn_pForm" />
          }
        </section>
      </div>
    </>
  );
};

export default ProfileCard;
