import { createSlice } from "@reduxjs/toolkit";
import { partnerPnaKycAction } from "../Actions/LoginAction";
import { updateProfileAction } from "../Actions/LoginAction";

export const loginReducer = createSlice({
    name: "login",
    initialState: {
        data: {
            "isLoggedIn": false
        }
    },
    reducers: {
        isLoggedInAction: (state, action) => {
            state.data = action.payload;
        },
    },
});
export const partnerPnaKycReducer = createSlice({
    name: "getPartnerPanKycReducer",
    initialState: {
      isLoading: false,
      data: null,
      isError: false,
    },
    extraReducers: (builder) => {
      builder.addCase(partnerPnaKycAction.pending, (state, action) => {
        state.isLoading = true;
      });
      builder.addCase(partnerPnaKycAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      });
      builder.addCase(partnerPnaKycAction.rejected, (state, action) => {
        console.error("Error", action.payload);
        state.isError = true;
        state.isLoading = false;
      });
    },
    reducers: {
        partnerRestPnaKycAction: (state, action) => {
        state.data = undefined
      }
    }
  });

  // update profile

  export const updateProfileReducer = createSlice({
    name: "updateProfile",
    initialState: {
      isLoading: false,
      data: null,
      isError: false,
    },
    extraReducers: (builder) => {
      builder.addCase(updateProfileAction.pending, (state, action) => {
        state.isLoading = true;
      });
      builder.addCase(updateProfileAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      });
      builder.addCase(updateProfileAction.rejected, (state, action) => {
       
        state.isError = true;
        state.isLoading = false;
      });
    },
    reducers: {
      resetupdateProfileAction: (state, action) => {
        state.data = undefined
      }
    }
  });
  export const { partnerRestPnaKycAction } = partnerPnaKycReducer.actions;
export const { isLoggedInAction } = loginReducer.actions;