import { createSlice } from "@reduxjs/toolkit";

export const bodyDataReducer = createSlice({
  name: "bodyData",
  initialState: {},
  reducers: {
    allBodyDataAction: (state, action) => {
      state.data = action.payload;
    },
    resetBodyDataAction: (state, action) => {
      state.data = undefined
    }
  },
});

export const { allBodyDataAction, resetBodyDataAction } = bodyDataReducer.actions;

export const storePreviousData = createSlice({
  name: "storePreviousData",
  initialState: {},
  reducers: {
    previousfilenameAction: (state, action) => {
      state.data = action.payload;
    },
    resetpreviousfilenameAction: (state, action) => {
      state.data = undefined
    }
  },
});

export const LoginTabDataReducer = createSlice({
  name: "LoginTabData",
  initialState: {data:{}},
  reducers: {
    LogiTabDataAction: (state, action) => {
      state.data = { ...state.data, ...action.payload };
    },
    resetLogiTabData: (state, action) => {
      state.data = undefined
    }
  },
});
export const { LogiTabDataAction, resetLogiTabData } = LoginTabDataReducer.actions;
export const { previousfilenameAction, resetpreviousfilenameAction } = storePreviousData.actions;

