import React, { useState, useEffect, useRef } from "react";
import Modal from "react-modal";
import { useSelector, useDispatch } from "react-redux";
import "./Modal.css";
import Botton from "../Button/Button";
import { commonVehicleFetchApiAction, common2WVehicleFetchApiAction } from "../../redux/Actions/commonVehicleFetchApiAction";
import EditDetails from "../ViewPlans/EditDetails";
import EditDetailsBike from "../ViewPlans/EditDetailsBike";
import close from "../../Assets/Images/Close.svg";
import ModalInp from "../RegNumberForm/modalInp";
import { Routes, Route, useNavigate, Link } from "react-router-dom";
import { ROLLOVER, FINDMYCAR } from "../../utils/commonUtils";
import edit from "../../Assets/Images/document.svg"
import ButtonLoader from "../Pages/Loader/Button Loader/ButtonLoader";
import { error } from "pdf-lib";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

Modal.setAppElement("#root");


const PopUp = (props) => {
  const {
    text,
    className,
    name,
    event,
    leftSideTitle,
    leftSideData,
    leftClassOne,
    leftClassTwo,
    leftClassThree,
    bottomContentLeft,
    // rightSideHead,
    CalendarDeactiveFlag,
    icons,
    renderContent,
    handlePolicyTypeSelection,
    PolicyTypeValue,
    value,
    HandleExpDate,
    vehicleNumber,
    carDetails,
    isClaimPrevPolicy,
    handleInputCarDetails,
    clickEvent,
    MakeList,
    FuelTypeList,
    businessType,
    VarientList,
    ccList,
    ModelListArray,
    setCarDetails,
    carDetailsCopyState,
    handleCopiedDetails,
    setcarDetailsCopyState,
    minDateValue,
    maxDateValue,
    setRegNumberErr,
    errors,
    setLoading,
    handleMakeSelect,
    handleModelSelect,
    handleFuelSelect,
    handleVariantSelect,
    handleCCSelect,
    mobileCLickEvent,
    handleNcbValue,
    policyTypeList,
    setShowRegDateCalendar,
    ShowRegDateCalendar,
    ShowOdExpDateCalendar,
    setShowOdExpDateCalendar,
    ShowExpDateCalendar,
    setShowExpDateCalendar,
    vehicleDetails,
    setOpenCarDetailsPopUp,
    vehicleType = "4W",
    dealerDetails,
    type,
    handleFindMyCarRoute,
    handleFindMyBikeRoute,
    ncbpopupstate,
    loading,
    errorss,
    setErrorss,
    setErrors,
    errorsbike,
    setErrorsBike,
    errorsCar,
    setErrorsCar,
    // setstopApi,
  } = props;

  console.log(errorss, setErrors, "setErrorsssetErrorss");

  const navigate = useNavigate();
  const buttonRef = useRef(null);
  const dispatch = useDispatch();
  const [modalIsOpen, setIsOpen] = useState(false);
  console.log(carDetailsCopyState, "gdgdsk55gjsgjds");


  async function openModal() {
    if (renderContent === "policyDetails") {
      if (businessType === "rollover") {
        if (vehicleNumber?.length <= 0) {
          setRegNumberErr(true);
        }
        else {
          if (vehicleNumber) {    // Adding Validation for GetReg no For Main Page.
            const regex = /^[A-Z]{2}[0-9]{0,2}[A-HJ-NP-Z]{1,3}[0-9]{4,5}$/;
            let ValidateRegexRegNo = regex.test(vehicleNumber);
            if (ValidateRegexRegNo) {
              if (mobileCLickEvent) {
                mobileCLickEvent();
              }
              setLoading(true);
              try {
                let getVehicleDetails
                if (vehicleType == "4W") {
                  getVehicleDetails = await dispatch(
                    commonVehicleFetchApiAction(vehicleNumber)
                  );
                } else {
                  getVehicleDetails = await dispatch(
                    common2WVehicleFetchApiAction(vehicleNumber)
                  );
                }
                getVehicleDetails = getVehicleDetails?.payload;
                if (
                  getVehicleDetails?.data?.model_details?.[0]?.Model == null
                  || getVehicleDetails.msg == "Error"
                  || getVehicleDetails.message == "Internal server error"
                ) {
                  setLoading(false);
                  localStorage.setItem("isFindMyCar", false);
                  if (vehicleType == "4W") {
                    navigate(dealerDetails?.dealer_code ?
                      `/Find-My-Car?Franchise_ID=${dealerDetails?.dealer_code}`
                      : "/Find-My-Car", {
                      state: {
                        businessType: FINDMYCAR,
                        vehicleNumber: vehicleNumber,
                        isFindMyCar: false,
                      },
                    });
                  } else {
                    navigate(dealerDetails?.dealer_code ?
                      `/Find-My-Bike?Franchise_ID=${dealerDetails?.dealer_code}`
                      : "/Find-My-Bike", {
                      state: {
                        businessType: FINDMYCAR,
                        vehicleNumber: vehicleNumber,
                        isFindMyCar: false,
                      },
                    });
                  }
                } else {
                  setLoading(false);
                  // setIsOpen(true);
                  // alert("kelau");
                  if (vehicleType == "4W") {
                    if (handleFindMyCarRoute) {
                      handleFindMyCarRoute();
                    }
                  } else {
                    if (handleFindMyBikeRoute) {
                      handleFindMyBikeRoute();
                    }
                  }
                }
              } catch (error) {
                setLoading(false);
                localStorage.setItem("isFindMyCar", false);
                if (vehicleType == "4W") {
                  navigate(dealerDetails?.dealer_code ?
                    `/Find-My-Car?Franchise_ID=${dealerDetails?.dealer_code}`
                    : "/Find-My-Car", {
                    state: {
                      businessType: FINDMYCAR,
                      vehicleNumber: vehicleNumber,
                      isFindMyCar: false,
                    },
                  });
                } else {
                  navigate(dealerDetails?.dealer_code ?
                    `/Find-My-Bike?Franchise_ID=${dealerDetails?.dealer_code}`
                    : "/Find-My-Bike", {
                    state: {
                      businessType: FINDMYCAR,
                      vehicleNumber: vehicleNumber,
                      isFindMyCar: false,
                    },
                  });
                }
              }
            } else {
              setRegNumberErr(true);
            }
          }
          else {
            // setIsOpen(true);
            // alert("kelau");
            if (vehicleType == "4W") {
              if (handleFindMyCarRoute) {
                handleFindMyCarRoute();
              }
            } else {
              if (handleFindMyBikeRoute) {
                handleFindMyBikeRoute();
              }
            }
          }
        }
      }
      else {
        // setIsOpen(true);
        // alert("kelau");
        if (vehicleType == "4W") {
          if (handleFindMyCarRoute) {
            handleFindMyCarRoute();
          }
        } else {
          if (handleFindMyBikeRoute) {
            handleFindMyBikeRoute();
          }
        }
      }
    } else {
      setIsOpen(true);
    }
  }

  // const [errorss, setErrorss] = useState({});

  const validateValidateDetails = () => {

    const newErrors = {};
    if (!carDetailsCopyState?.vehicleNumberCopy) {
      newErrors.vehicleNumberCopy = "Vehicle Number is required";
    }
    if (!carDetailsCopyState?.makeCopy) {
      newErrors.makeCopy = "Make  is required";
    }
    if (!carDetailsCopyState?.modelCopy) {
      newErrors.modelCopy = "Model is required";
    }
    if (!carDetailsCopyState?.varientCopy) {
      newErrors.varientCopy = "Varient is required";
    }
    if (!carDetailsCopyState?.fuelTypeCopy) {
      newErrors.fuelTypeCopy = "FuleType is required";
    }
    if (!carDetailsCopyState?.ccCopy) {
      newErrors.ccCopy = "CC is required";
    }

    setErrorss(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSaveCarDetails = (e, isFindMyCar) => {
    e.preventDefault();
    let isValid = validateValidateDetails();

    console.log(carDetailsCopyState?.newNcbCopy, carDetailsCopyState?.prevNcbCopy, carDetailsCopyState?.isClaimPrevPolicyCopy, "asdknadsa");
    if (isValid) {
      handleInputCarDetails(carDetailsCopyState?.isClaimPrevPolicyCopy === true
        ? "Yes"
        : "No", "isClaimPrevPolicy", "")

      closeModal();
      setCarDetails((prevItems) => ({
        ...prevItems,
        vehicleNumber: carDetailsCopyState?.vehicleNumberCopy,
        prevPolicyType: carDetailsCopyState?.prevPolicyTypeCopy,
        policyExpDate: carDetailsCopyState?.policyExpCopy,
        prevPolicyODexpDate: carDetailsCopyState?.odPolicyExpCopy,
        // prevPolicyTPexpDate: carDetailsCopyState?.tpPolicyExpCopy,
        isClaimPrevPolicy: carDetailsCopyState?.isClaimPrevPolicyCopy,
        prevNcb: carDetailsCopyState?.isClaimPrevPolicyCopy === true ? "0" : carDetailsCopyState?.prevNcbCopy || prevItems.prevNcb,
        newNcb: carDetailsCopyState?.isClaimPrevPolicyCopy === true ? "0" : carDetailsCopyState?.newNcbCopy || prevItems.newNcb,
        make: carDetailsCopyState?.makeCopy,
        model: carDetailsCopyState?.modelCopy,
        varient: carDetailsCopyState?.varientCopy,
        fuelType: carDetailsCopyState?.fuelTypeCopy,
        cc: carDetailsCopyState?.ccCopy,
        policyRegDate: carDetailsCopyState?.regDateCopy,
        isFindMyCar: isFindMyCar ? isFindMyCar : carDetails.isFindMyCar,
      }));
    }
  };

  const handleCancelModal = () => {
    let isValid = validateValidateDetails();
    if (!isValid) {
        setErrorss({});
      }
    if (closeModal) {
      closeModal();
    }
    setcarDetailsCopyState((prevItems) => ({
      ...prevItems,
      vehicleNumberCopy: carDetails?.vehicleNumber,
      prevPolicyTypeCopy: carDetails?.prevPolicyType,
      policyExpCopy: carDetails?.policyExpDate,
      odPolicyExpCopy: carDetails?.prevPolicyODexpDate,
      tpPolicyExpCopy: carDetails?.prevPolicyTPexpDate,
      isClaimPrevPolicyCopy: carDetails?.isClaimPrevPolicy,
      prevNcbCopy: carDetails?.prevNcb,
      newNcbCopy: carDetails?.newNcb,
      makeCopy: carDetails?.make,
      modelCopy: carDetails?.model,
      varientCopy: carDetails?.varient,
      fuelTypeCopy: carDetails?.fuelType,
      ccCopy: carDetails?.cc,
      regDateCopy: carDetails?.policyRegDate,
    }));
    let zoomLevel;
    if (window.innerWidth <= 400) {
      zoomLevel = 0.9;
      document.body.style.zoom = zoomLevel?.toString();
    }

  };

  function closeModal() {
    setIsOpen(false);
  }

  function closeEditModal() {
    setIsOpen(false);
    handleCancelModal();
  }

  const handleRouteToFMC = () => {
    localStorage.setItem("isFindMyCar", false);
    if (vehicleType == "4W") {
      navigate(dealerDetails?.dealer_code ?
        `/Find-My-Car?Franchise_ID=${dealerDetails?.dealer_code}`
        : "/Find-My-Car", {
        state: {
          businessType: ROLLOVER,
          vehicleNumber: vehicleNumber,
          isFindMyCar: false,
        },
      });
    } else {
      navigate(dealerDetails?.dealer_code ?
        `/Find-My-Bike?Franchise_ID=${dealerDetails?.dealer_code}`
        : "/Find-My-Bike", {
        state: {
          businessType: ROLLOVER,
          vehicleNumber: vehicleNumber,
          isFindMyCar: false,
        },
      });
    }
  };

  useEffect(() => {
    if (setOpenCarDetailsPopUp) {
      setOpenCarDetailsPopUp(modalIsOpen);
    }
  }, [modalIsOpen])

  console.log(loading, "loadingloading");

  return (
    <div id="">
      {loading ? (<ButtonLoader />) : <Botton
        text={text}
        icons={icons}
        type={type}
        name={name}
        ref={buttonRef}
        event={openModal}
        className={className}
      />}
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={
          renderContent === "policyDetails" ? closeModal : closeEditModal
        }
        style={customStyles}
        contentLabel="Example Modal"
        id=""
        // shouldCloseOnOverlayClick={Object.keys(errorss).length === 0 ? true : false}
        shouldCloseOnOverlayClick={Object.keys(errorss || {}).length === 0 ? true : false}   // it's working on ViewPlane Edit popup outside clickEvent 
      >

        {renderContent === "policyDetails" ? (
          <div className="modal_container modal_container-height">
            <div className="leftSide_modal leftSide_modal-desktop part_modal_container ">
              <p className="modal_title model_title_margin">{leftSideTitle}</p>
              {leftSideData.map((index, key) => (
                <div key={key} className={leftClassOne}>
                  <p
                    className={
                      index.title == "Company Model: "
                        ? leftClassTwo + " " + "width-title"
                        : leftClassTwo
                    }
                  >
                    {index.title}
                  </p>
                  <p className={leftClassThree}>{index.desc}</p>
                </div>
              ))}
              {bottomContentLeft.map((index, key) => (
                <div
                  key={key}
                  className={index.classOne + " " + "bottomContentPadding"}
                >
                  <p className={index.classTwo}>{index.contentOne}</p>
                  <p className={index.classThree} onClick={handleRouteToFMC}>
                    {index.contentTwo}
                  </p>
                </div>
              ))}
            </div>
            <div className="rightSide_modal rightSide_modal-width part_modal_container ">
              <img
                src={close}
                className="closeImg_modal"
                alt=""
                srcSet=""
                onClick={closeModal}
              />
              <p className="title_rightSide_modal" > {vehicleType == "4W" ? "Enter your car details" : "Enter your bike details"} </p>
              <ModalInp type="submit"
                clickEvent={clickEvent}
                closeModal={closeModal}
                businessType={businessType}
                errors={errors}
                vehicleType={vehicleType}
                edit={edit}
                make={vehicleDetails?.make}
                model={vehicleDetails?.model}
                vehicleNumber={vehicleDetails?.vehicleNumber}
                varient={vehicleDetails?.varient}
                fuelType={vehicleDetails?.fuelType}
                fuelTypeCopy={carDetailsCopyState?.fuelTypeCopy}
                regDate={vehicleDetails?.regDate}
                pincode={vehicleDetails?.suggested_pincode}
                handleRouteToFMC={handleRouteToFMC}
                vehicleDetails={leftSideData}
              />
            </div>
          </div>
        ) : (
          <>
            {vehicleType == "4W" ?
              <EditDetails
                type="submit"
                renderContent={renderContent}
                closeModal={closeModal}
                handlePolicyTypeSelection={handlePolicyTypeSelection}
                PolicyTypeValue={PolicyTypeValue}
                handleRegDate={event}
                HandleExpDate={HandleExpDate}
                CalendarDeactiveFlag={CalendarDeactiveFlag}
                value={value}
                name={name}
                carDetails={carDetails}
                isClaimPrevPolicy={isClaimPrevPolicy}
                handleInputCarDetails={handleInputCarDetails}
                MakeList={MakeList}
                FuelTypeList={FuelTypeList}
                VarientList={VarientList}
                ModelListArray={ModelListArray}
                businessType={businessType}
                ccList={ccList}
                setCarDetails={setCarDetails}
                carDetailsCopyState={carDetailsCopyState}
                handleCopiedDetails={handleCopiedDetails}
                setcarDetailsCopyState={setcarDetailsCopyState}
                handleMakeSelect={handleMakeSelect}
                handleModelSelect={handleModelSelect}
                handleFuelSelect={handleFuelSelect}
                handleVariantSelect={handleVariantSelect}
                handleCCSelect={handleCCSelect}
                minDateValue={minDateValue}
                maxDateValue={maxDateValue}
                handleSaveCarDetails={handleSaveCarDetails}
                errorss={errorss}
                setErrorss={setErrorss}
                errorsCar={errorsCar}
                setErrorsCar={setErrorsCar}
                handleCancelModal={handleCancelModal}
                handleNcbValue={handleNcbValue}
                policyTypeList={policyTypeList}
                isDisabled={true}
                setShowRegDateCalendar={setShowRegDateCalendar}
                ShowRegDateCalendar={ShowRegDateCalendar}
                ShowOdExpDateCalendar={ShowOdExpDateCalendar}
                setShowOdExpDateCalendar={setShowOdExpDateCalendar}
                ShowExpDateCalendar={ShowExpDateCalendar}
                setShowExpDateCalendar={setShowExpDateCalendar}
                ncbpopupstate={ncbpopupstate}
              // setstopApi={setstopApi}
              /> :
              <EditDetailsBike
                type="submit"
                renderContent={renderContent}
                closeModal={closeModal}
                handlePolicyTypeSelection={handlePolicyTypeSelection}
                PolicyTypeValue={PolicyTypeValue}
                handleRegDate={event}
                HandleExpDate={HandleExpDate}
                value={value}
                name={name}
                carDetails={carDetails}
                isClaimPrevPolicy={isClaimPrevPolicy}
                handleInputCarDetails={handleInputCarDetails}
                MakeList={MakeList}
                FuelTypeList={FuelTypeList}
                VarientList={VarientList}
                ModelListArray={ModelListArray}
                businessType={businessType}
                ccList={ccList}
                setCarDetails={setCarDetails}
                carDetailsCopyState={carDetailsCopyState}
                handleCopiedDetails={handleCopiedDetails}
                setcarDetailsCopyState={setcarDetailsCopyState}
                handleMakeSelect={handleMakeSelect}
                handleModelSelect={handleModelSelect}
                handleFuelSelect={handleFuelSelect}
                handleVariantSelect={handleVariantSelect}
                handleCCSelect={handleCCSelect}
                minDateValue={minDateValue}
                maxDateValue={maxDateValue}
                handleSaveCarDetails={handleSaveCarDetails}
                handleCancelModal={handleCancelModal}
                handleNcbValue={handleNcbValue}
                policyTypeList={policyTypeList}
                setShowRegDateCalendar={setShowRegDateCalendar}
                ShowRegDateCalendar={ShowRegDateCalendar}
                ShowOdExpDateCalendar={ShowOdExpDateCalendar}
                setShowOdExpDateCalendar={setShowOdExpDateCalendar}
                ShowExpDateCalendar={ShowExpDateCalendar}
                setShowExpDateCalendar={setShowExpDateCalendar}
                CalendarDeactiveFlag={CalendarDeactiveFlag}
                errorss={errorss}
                setErrorss={setErrorss}
                errorsbike={errorsbike}
                setErrorsBike={setErrorsBike}
              />}
          </>
        )
        }


      </Modal>
    </div>
  );
};

export default PopUp;
