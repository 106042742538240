import React, { useState, useEffect } from "react";
import moment from 'moment';
import { useDispatch } from "react-redux";
import car from "../../../../Assets/Images/car.svg";
import bike from "../../../../Assets/Images/bike.svg";
import health from "../../../../Assets/Images/health.svg";
import taxi from "../../../../Assets/Images/taxi.svg";
import grayArrow from "../../../../Assets/Images/grayArrow.svg";
import tick from "../../../../Assets/Images/success.svg";
import Botton from "../../../Button/Button";
import ActivePolicy from "./ActivePolicy";
import YetToStart from "./YetToStart";
import ExpiredPolicy from "./ExpiredPolicy";
import RenewalPolicy from "./RenewalPolicy";
import { useSelector } from "react-redux";
import { json } from "react-router-dom";
import { getRenewpolicyDataAction } from "../../../../redux/Actions/GetpolicyDataAction";
import { LogiTabDataAction } from "../../../../redux/Reducers/bodyDataReducer";

const PolicyFound = (props) => {


  const dispatch = useDispatch()
  const { handleInputChange, FormValue } = props;
  const LoginTabData = useSelector((state) => {
    return state.LoginTabData
  })
  // Future Policies Policies, Expired Policies, Upcoming Renewal, Active Policies
  const [ActivePolicyTab, setActivePolicyTab] = useState(LoginTabData?.data?.data1 ? LoginTabData?.data?.data1 : "Active Policies");

  let active = []
  let yetStart = []
  let exprie = []
  let upcoming = []

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [ActivePolicyTab])
  const ClinetPolicyData = useSelector((state) => {
    return state.getPolicyData
  })


  function getStatus(startDate, endDate) {
    const currentDate = moment();
    const start = moment(startDate);
    const end = moment(endDate);
    const daysToEnd = end.diff(currentDate, 'days');
    return start.isAfter(currentDate) ? "Future Policies" : end.isBefore(currentDate) ? "Expired" : daysToEnd >= 0 && daysToEnd <= 45 ? "Upcoming" : 'Active'

  }

  const ClinetPolicyActiveData = ClinetPolicyData?.data?.data?.customerDetails
  ClinetPolicyActiveData?.map((e) => {
    let start = e.policy_start_date
    let end = e.policy_end_date
    let status = getStatus(start, end)
    status == "Future Policies" ? yetStart = [...yetStart, e] : status == "Expired" ? exprie = [...exprie, e] : status == "Upcoming" ? upcoming = [...upcoming, e] : active = [...active, e]
  })
  let tabList = [
    "Active Policies",
    "Future Policies",
    "Expired Policies",
    "Upcoming Renewal",
  ];

  let coveragesFromDB = [
    "Zero Depreciation",
    "Consumables",
    "Consumables",
    "Consumables",
    "Consumables",
  ];

  let policyDetails = [
    {
      productIcon: car,
      policyNumber: "DUMMYPOLICY0000001",
      insuredProductInfo: "Private Car Policy Insurance",
      insurerName: "HDFC Ergo",
      policyStartDate: "24 Jan 2023",
      policyEndDate: "24 Jan 2023",
      policyHolderName: "Vaibhav Dubey",
      vehicleNumber: "MH 02 CR 3380",
      coverages: coveragesFromDB,
    },
    {
      productIcon: car,
      policyNumber: "DUMMYPOLICY0000002",
      insuredProductInfo: "Private Car Policy Insurance",
      insurerName: "TATA AIG",
      policyStartDate: "2 Jan 2023",
      policyEndDate: "2 Jan 2023",
      policyHolderName: "Shubh Trivedi",
      vehicleNumber: "MH 04 CB 7897",
      coverages: coveragesFromDB,
    },
    {
      productIcon: car,
      policyNumber: "DUMMYPOLICY0000003",
      insuredProductInfo: "Private Car Policy Insurance",
      insurerName: "SBI General Insurance",
      policyStartDate: "24 Jan 2023",
      policyEndDate: "24 Jan 2023",
      policyHolderName: "Rohit Pandey",
      vehicleNumber: "MH 04 CA 9090",
      coverages: coveragesFromDB,
    },
  ];

  const handleDownloadPolicy = (policyNumber) => {
    console.log("file downloaded")

  };


  return (
    <>
      <div className="policyTypeTab">
        {tabList.map((tabItems, key) => (
          <Botton
            text={tabItems}
            className={
              ActivePolicyTab == tabItems
                ? "accordian_button accordian_button-policy-section active_accordian_btn"
                : "accordian_button accordian_button-policy-section"
            }
            value="Comprehensive"
            name="PolicyTypeValue"
            event={(e) => {
              setActivePolicyTab(tabItems)
              dispatch(LogiTabDataAction({ data1: tabItems }));
            }}
          />
        ))}
      </div>

      {ActivePolicyTab === "Active Policies" && (
        <ActivePolicy
          policyDetails={policyDetails}
          FormValue={FormValue}
          car={car}
          bike={bike}
          coverages={coveragesFromDB}
          ClinetPolicyActiveData={active}
          handleDownloadPolicy={handleDownloadPolicy}
        />
      )}

      {ActivePolicyTab === "Future Policies" && (
        <YetToStart
          FormValue={FormValue}
          ClinetPolicyActiveData={yetStart}
          car={car}
          bike={bike}
          handleInputChange={handleInputChange}
          handleDownloadPolicy={handleDownloadPolicy}
        />
      )}

      {ActivePolicyTab === "Expired Policies" && (
        <ExpiredPolicy
          handleDownloadPolicy={handleDownloadPolicy}
          ClinetPolicyActiveData={exprie}
          car={car}
          bike={bike}
          handleInputChange={handleInputChange}
        />
      )}

      {ActivePolicyTab === "Upcoming Renewal" && (
        <RenewalPolicy
          handleDownloadPolicy={handleDownloadPolicy}
          ClinetPolicyActiveData={upcoming}
          car={car}
          bike={bike}
          handleInputChange={handleInputChange}

        />
      )}
    </>
  );
};

export default PolicyFound;
