import React, { useState } from "react";
import InputFields from "../InputFields/InputFields";
import Botton from "../Button/Button";
import RadioButton from "../RadioButton/RadioButton";
import Calender from "../InputFields/Calender";
import DropDown from "../DropDown/DropDown";
import grayArrow from "../../Assets/Images/grayArrow.svg";
import { useLocation, Link, useNavigate, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { SBI, SHREERAM } from "../../utils/commonUtils";
import backIcon from "../../Assets/Images/backIcon.svg";
import CustomCalendar from "../InputFields/Calendar/Calendar";
import CustomDateField from "../InputFields/CustomDateField/CustomDateField";
import ButtonLoader from "../Pages/Loader/Button Loader/ButtonLoader";

const ProposalTwo = (props) => {
  const {
    Edit,
    errors,
    formData,
    DobClone,
    dateValue,
    nomineeAge,
    nomineeName,
    companyName,
    bodyData,
    handleBackBtn,
    sbiAutoKycData,
    handleDobClone,
    nomineeRelation,
    ShowDobCalendar,
    handleSubmitForm,
    saveCalendarValue,
    HandleInputChange,
    setShowDobCalendar,
    cancelCalendarValue,
    type,
  } = props;

  const navigate = useNavigate();
  

  const [ShowRelationDropdown, setShowRelationDropdown] = useState(false);
  const [ShowAppointeeRelation, setShowAppointeeRelation] = useState(false);
  const search = useLocation().search;
  const id = new URLSearchParams(search).get("id");
  let currenDate = new Date();
  let maxDobDate = currenDate.setFullYear(currenDate.getFullYear() - 18);

  const relationList = [
    { label: "Husband", value: "Husband" },
    { label: "Wife", value: "Wife" },
    { label: "Spouse", value: "Spouse" },
    { label: "Father", value: "Father" },
    { label: "Mother", value: "Mother" },
    { label: "Son", value: "Son" },
    { label: "Daughter", value: "Daughter" },
    { label: "Brother", value: "Brother" },
    { label: "Sister", value: "Sister" },
    { label: "Other", value: "Other" },
  ];

  const appointeeRelationList = [
    { label: "Father", value: "Father" },
    { label: "Mother", value: "Mother" },
    { label: "Brother", value: "Brother" },
    { label: "Sister", value: "Sister" },
    { label: "Other", value: "Other" },
  ];

  const handlePopUps = () => {
    if (ShowRelationDropdown) {
      setShowRelationDropdown(false);
    }
    if (ShowAppointeeRelation) {
      setShowAppointeeRelation(false);
    }
  };

  let vehicleType = bodyData?.data?.vehicleType;

  let transactionStatus = useSelector((state) => {
    return state.getTransactionStatus;
  });

  return (
    <div >
      <div className="titleContainer titleContainer_pform">
        <p>Personal Details</p>
        <p>All fields are mandatory</p>
      </div>
      <form className="formSection-pform" action="" onClick={handlePopUps} onSubmit={(e) => { e.preventDefault(); }}>
        <div className="input_group_pform">
          <div className="inputGropOne_form inputGrop inputGrop_caseTwo ">
            <p className="customLabel_pform">Gender</p>
            <div className="radioContainer-pform">
              <RadioButton
                label="Male"
                className=""
                id="Male"
                groupName="gender"
                value="Male"
                changeEvent={(e) => HandleInputChange(e)}
                isChecked={formData?.gender === "Male" ? true : false}
                isDisabled={(companyName === SBI && sbiAutoKycData?.kycStatus === "CKYCSuccess") ? true : false}
              />
              <RadioButton
                label="Female"
                className=""
                id="Female"
                groupName="gender"
                value="Female"
                changeEvent={(e) => HandleInputChange(e)}
                isChecked={formData?.gender === "Female" ? true : false}
                isDisabled={(companyName === SBI && sbiAutoKycData?.kycStatus === "CKYCSuccess") ? true : false}
              />
            </div>
            <p className="err-msg">{errors?.gender ? errors.gender : ""}</p>
          </div>

          <div className="inputGropOne_form inputGrop inputGrop_caseTwo ">
            <p className="customLabel_pform">Marital Status</p>
            <div className="radioContainer-pform">
              <RadioButton
                label="Married"
                className=""
                id="Married"
                groupName="maritalStatus"
                value="Married"
                changeEvent={(e) => HandleInputChange(e)}
                isChecked={formData?.maritalStatus === "Married" ? true : false}
              />
              <RadioButton
                label="Single"
                className=""
                id="Single"
                groupName="maritalStatus"
                value="Single"
                changeEvent={(e) => HandleInputChange(e)}
                isChecked={formData?.maritalStatus === "Single" ? true : false}
              />
            </div>
            <p className="err-msg">
              {errors?.maritalStatus ? errors.maritalStatus : ""}
            </p>
          </div>
        </div>

        <div className="input_group_pform">
          <div className="inputGropOne_form inputGrop ">
            <label htmlFor="dobPform">
              <CustomDateField
                className="pForm_datePicker pForm_datePicker-tab "
                name="dobCopy"
                btnId="dobCopyBtn"
                textId="dobCopyTxt"
                imgId="dobCopyImg"
                errors={errors}
                dateValue={DobClone}
                placeholder="Date Of Birth"
                placeholderId="dobPlaceholderId"
                // onChangeEvent={(e) => HandleInputChange(e)}
                isDisabled={(companyName === SBI && sbiAutoKycData?.kycStatus === "CKYCSuccess") ? true : false}
                calendarReveal={ShowDobCalendar}
                setCalendarReveal={setShowDobCalendar}
              />
            </label>
            <p className="err-msg">{errors?.dob ? errors?.dob : ""}</p>
          </div>
          <div className="inputGropOne_form inputGrop inputGrop-hide-mobile "></div>
        </div>

        <div className="input_group_pform">
          <div className="inputGropOne_form inputGrop inputGrop_caseTwo ">
            <p className="customLabel_pform">Nominee Details</p>
            <div className="">
              <InputFields
                value={nomineeName}
                placeholder="Nominee Name"
                id="nomineeName"
                type="text"
                name="nomineeName"
                onChangeEvent={(e) => HandleInputChange(e)}
                errState={errors?.nomineeName ? true : false}
                errMsg={errors?.nomineeName}
                errClassName="input-err"
              />
            </div>
          </div>
          <div className="inputGropOne_form inputGrop inputGrop_caseTwo ">
            <p className="customLabel_pform inputGrop-hide-mobile "> &nbsp; </p>
            <div className="nominee_division">
              <div className="nominee_division_age">
                <InputFields
                  value={nomineeAge}
                  placeholder="Age"
                  id="nomineeAge"
                  type="number"
                  name="nomineeAge"
                  onChangeEvent={(e) => HandleInputChange(e)}
                  errState={errors?.nomineeAge ? true : false}
                  errMsg={errors?.nomineeAge}
                  errClassName="input-err"
                />
              </div>
              <div className="nominee_division_Relation">
                <DropDown
                  type="submit"
                  dropDownMenu={relationList}
                  value={nomineeRelation}
                  name="nomineeRelation"
                  classNameForHead="headTxtClassPform"
                  Head="Relation"
                  label="Relation"
                  arrowInGray={grayArrow}
                  inputId="nomineeRelation"
                  clickEvent={(e) => console.log(e)}
                  headClick={(e) =>
                    setShowRelationDropdown(!ShowRelationDropdown)
                  }
                  revealOptions={ShowRelationDropdown}
                  afterTrueClass="dropDown_container NoBorderRadius"
                  className="dropDown_container"
                  valueSelectionEvent={HandleInputChange}
                  revealOptionsClasses=" dropDownOptions showdropDownOptions "
                  classNameTwo="dropDownOptions"
                  dropDownHeadClass="dropDownHead"
                  idForHead="relHead"
                  idForHeadTxt="relHeadTxt"
                  idForArrow="relHeadArrow"
                  dropdownId="relListDp"
                  idForParentObj="relParentDp"
                  errState={errors?.nomineeRelation ? true : false}
                  errMsg={errors?.nomineeRelation}
                  errClassName="input-err"
                />
              </div>
            </div>
          </div>
        </div>

        {
          (nomineeAge < 18 && companyName === SHREERAM && nomineeAge != "") && (
            <div className="input_group_pform">
              <div className="inputGropOne_form inputGrop ">
                <InputFields
                  value={formData?.appointeeName}
                  placeholder="Appointee Name"
                  id="appointeeName"
                  type="text"
                  name="appointeeName"
                  errState={errors?.appointeeName ? true : false}
                  errMsg={errors?.appointeeName}
                  errClassName="input-err"
                  onChangeEvent={(e) => HandleInputChange(e)}
                />
              </div>
              <div className="inputGropOne_form inputGrop ">
                <DropDown
                  type="submit"
                  dropDownMenu={appointeeRelationList}
                  value={formData?.appointeeRelation}
                  name="appointeeRelation"
                  classNameForHead="headTxtClassPform"
                  Head="Select Appointee Relation"
                  label="Appointee Relation"
                  arrowInGray={grayArrow}
                  inputId="nomineeRelation"
                  clickEvent={(e) => console.log(e)}
                  headClick={(e) =>
                    setShowAppointeeRelation(!ShowAppointeeRelation)
                  }
                  revealOptions={ShowAppointeeRelation}
                  afterTrueClass="dropDown_container NoBorderRadius"
                  className="dropDown_container"
                  valueSelectionEvent={HandleInputChange}
                  revealOptionsClasses=" dropDownOptions showdropDownOptions "
                  classNameTwo="dropDownOptions"
                  dropDownHeadClass="dropDownHead"
                  idForHead="relHead"
                  idForHeadTxt="relHeadTxt"
                  idForArrow="relHeadArrow"
                  dropdownId="relListDp"
                  idForParentObj="relParentDp"
                  errState={errors?.appointeeRelation ? true : false}
                  errMsg={errors?.appointeeRelation}
                  errClassName="input-err"
                />
              </div>
            </div>
          )
        }

        <section className="btnContainer_pform btnContainer_pform_collapsible">
          <Botton
            text="Back"
            className="button btn_pForm  secondry_btn"
            event={() => handleBackBtn("PersonalDetails")}
          />
          {transactionStatus?.isLoading ?
            <ButtonLoader className="btn_pForm" />
            : <Botton
              text="Save"
              type="submit"
              className="button btn_pForm  "
              event={(e) =>
                handleSubmitForm(Edit ? "kycDetails" : "PersonalDetails", e, "editPersonalDetails")
              }
            />}
        </section>
      </form>

      {
        ShowDobCalendar && (
          <CustomCalendar
            value={DobClone}
            headText="Enter your Date of Birth"
            name="dobCopy"
            btnId="dobCopyBtn"
            textId="dobCopyTxt"
            imgId="dobCopyImg"
            placeholderId="dobPlaceholderId"
            revealState={ShowDobCalendar}
            setRevealState={setShowDobCalendar}
            handleCopiedDetails={handleDobClone}
            cancelCalendarValue={cancelCalendarValue}
            saveCalendarValue={saveCalendarValue}
            onClose={(e) => setShowDobCalendar(false)}
          // maxDate={maxDobDate}
          />
        )
      }

    </div>
  );
};

export default ProposalTwo;
