import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import search from "../../Assets/Images/search.svg";
import Footer from "../Footer/Footer";
import InputFields from "../InputFields/InputFields";
import Botton from "../Button/Button";
import DropDown from "../DropDown/DropDown";
import grayArrow from "../../Assets/Images/grayArrow.svg";
import Select from "react-select";
import { updateVehicleInfoAction } from "../../redux/Reducers/commonVehicleFetchApiReducer";
import { digitQuickQuoteAction12 } from "../../redux/Actions/digitApiAction";
import { digitQuickQuoteReducer12, } from "../../redux/Reducers/digitApiReducer";
import {
  getModelAction,
  getCarBrandAction,
  getFuelTypeAction,
  getVariantAction,
} from "../../redux/Actions/mmvAction";
import { getRtoAction } from "../../redux/Actions/stateDetails";
import {
  Routes,
  Route,
  useNavigate,
  Link,
  useLocation,
} from "react-router-dom";
import ModalInp from "./modalInp";
import Calender from "../InputFields/Calender";
import SearchSelect from "../DropDown/Search Select/SearchSelect";
import { NEW } from "../../utils/commonUtils";
import Navbar from "../Navbar/Navbar";
import backIcon from "../../Assets/Images/backIcon.svg";
import CustomDateField from "../InputFields/CustomDateField/CustomDateField";
import CustomCalendar from "../InputFields/Calendar/Calendar";
import CheckBox from "../Checkbox/CheckBox";
import { isFmcContentAction } from "../../redux/Reducers/FindMyCarContentType";
import { allBodyDataAction } from "../../redux/Reducers/bodyDataReducer";
import SkeletonLoader from "../Pages/Loader/Skeleton loader/SkeletonLoader";
import FindMyVehicleMob from "./FindMyVehicleMob";
import close from "../../Assets/Images/Close.svg"

const FindMyCarDetails = (props) => {

  // const { sectionOne, sectionTwo } = props;
  const [sectionOne, setSectionOne] = useState(false);
  const dispatch = useDispatch();
  const [ShowPolicyStartDate, setShowPolicyStartDate] = useState(false);
  const [PolStartDateClone, setPolStartDateClone] = useState(null);
  const [falg, setFalg] = useState(false)
  const [isEnalbeBtn, setIsEnableBtn] = useState(true)

  const bodyData = useSelector((state) => {
    return state?.bodyData?.data;
  })


  let proPlan = useSelector((state) => {
    return state?.digitQuickQuote12;
  })

  const handlePhoneNumber = (e) => {
    const { id, value, name } = e?.target || {};

    if (value.length <= 10) {
      setManualInpValue((prevItems) => ({
        ...prevItems,
        mobileNo: value,
      }));
      localStorage.setItem("mobileNo", String(value))
    }
  };

 

  // getting details vehicle
  const getVehicleData = useSelector((state) => {
    return state?.getVehicleData?.data?.data?.data;
  });

  let getContentType = useSelector((state) => {
    return state?.getFmcContentType?.data;
  })

  let dealerDetails = useSelector((state) => {
    return state?.dealerData?.data
  });

  const FuelType = getVehicleData?.fuelType;

  const getModelValue = useSelector((state) => {
    return state?.getModel;
  });
  const ModelList = getModelValue?.data?.data?.vehicelinfo;
  const uuid = getModelValue?.data?.data?.uuid;

  const getFuelTypeValue = useSelector((state) => {
    return state?.getFuel;
  });

  const fuelList = getFuelTypeValue?.data?.data?.vehicelinfo;

  const getVariantValue = useSelector((state) => {
    return state?.getVariant;
  });

  const variantList = getVariantValue?.data?.data?.vehicelinfo;

  const getRtoData = useSelector((state) => {
    return state?.getRtoDetails;
  });

  const rtoArray = getRtoData?.data?.data?.rtocity?.map((element) => {
    return {
      label: element?.rto + "-" + element?.city_name,
      value: element?.rto + "-" + element?.city_name,
    };
  });

  const getInitialShowUserDataModal = () => {
    const storedValue = localStorage.getItem('ShowUserDataModal');
    return storedValue !== null ? JSON.parse(storedValue) : false;
  };

  const getInitialSwitchFunction = () => {
    const storedValue = localStorage.getItem('SwitchFunction');
    return storedValue !== null ? JSON.parse(storedValue) : false;
  };

  // navigation
  const navigate = useNavigate();
  const location = useLocation();
  let businessType = ''
  let vehicleNumber = location?.state?.vehicleNumber;
  businessType = location?.state?.businessType;

  // useState
  const [MakeId, setMakeId] = useState(bodyData?.make ? bodyData?.make : "");
  const [Mmv, setMmv] = useState("");
  const [SearchedTerm, setSearchedTerm] = useState("");
  const [ContentType, setContentType] = useState(getContentType);
  const [SearchedTermModel, setSearchedTermModel] = useState("");
  const [SearchedTermVarient, setSearchedTermVarient] = useState("");
  const [SearchedTermRto, setSearchedTermRto] = useState("");
  useEffect(() => {
    setContentType(getContentType);
  }, [])
  const [ShowMakeBottom, setShowMakeBottom] = useState(false);
  const [ShowModelBottom, setShowModelBottom] = useState(false);
  const [ShowRtoBottom, setShowRtoBottom] = useState(false)
  const [ShowRtoList, setShowRtoList] = useState(false);
  const [RegYearList, setRegYearList] = useState(false);
  const [SwitchFunction, setSwitchFunction] = useState(getInitialSwitchFunction);
  const [ShowUserDataModal, setShowUserDataModal] = useState(getInitialShowUserDataModal);
  const currentYear = new Date().getFullYear();
  const yearOfRegistration = [];

  useEffect(() => {
    localStorage.setItem('ShowUserDataModal', JSON.stringify(ShowUserDataModal));
    localStorage.setItem('SwitchFunction', JSON.stringify(SwitchFunction));
  }, [ShowUserDataModal, SwitchFunction]);

  for (let year = 1990; year <= currentYear; year++) {
    if (businessType === "New") {
      if (year == currentYear || year == currentYear - 1) {
        yearOfRegistration.push(year);
      }
    } else {
      yearOfRegistration.push(year);
    }
  }

  const yearOfReg = yearOfRegistration?.map((data, key) => ({
    label: data,
    value: data,
  }));



  // Reverse the array
  yearOfReg.reverse();

  const manualInputs = {
    make: null || bodyData?.make || localStorage.getItem("make"),
    model: null || bodyData?.model || localStorage.getItem("model"),
    varient: null || bodyData?.varient || localStorage.getItem("varient"),
    fuelType: null || bodyData?.fuelType || localStorage.getItem("fuelType"),
    rto: null || getVehicleData?.rto || localStorage.getItem("rto"),
    cc: null || bodyData?.cc || localStorage.getItem("cc"),
    regn_dt: null || getVehicleData?.regn_dt || localStorage.getItem("regn_dt"),
    policyStartDate: null || getVehicleData?.policyStartDate || localStorage.getItem("policyStartDate"),
    businessType: bodyData?.typeOfBusiness ? bodyData?.typeOfBusiness : businessType,
    mobileNo: null || bodyData?.mobileNo || localStorage.getItem("mobileNo"),
    pincode: null || bodyData?.pincode || localStorage.getItem("pincode"),
    isPospSale: false || bodyData?.isPospSale,
  };


  let newDate = new Date()
  const [MinDateValue, setMinDateValue] = useState(newDate);
  const [ManualInpValue, setManualInpValue] = useState(manualInputs);
  const [errors, setErrors] = useState({});
  const [isManualRTOUpdate, setIsManualRTOUpdate] = useState(false);

  console.log(ManualInpValue, "ManualInpValue");


  useEffect(() => {

    if (!isManualRTOUpdate && vehicleNumber && !getVehicleData?.rto) {

      let vehicleRTOCode = vehicleNumber?.substring(0, 4);
      let matchedRTO = rtoArray?.find(rto => rto?.label?.startsWith(vehicleRTOCode));

      if (matchedRTO?.value && ManualInpValue.rto !== matchedRTO?.value) {
        setManualInpValue((prevItems) => ({
          ...prevItems,
          rto: matchedRTO?.value || getVehicleData?.rto,
        }));
      }
    };

  }, [vehicleNumber, rtoArray, ManualInpValue?.rto, getVehicleData?.rto, isManualRTOUpdate]);

  const [FmcError, setFmcError] = useState({
    makeErr: null,
    modelErr: null,
    varientErr: null,
    fuelTypeErr: null,
    rtoErr: null,
    policyDetailsErr: null,
  });

  const handleSearchQuery = (searchValue, type) => {
    switch (type) {
      case "make":
        setSearchedTerm(searchValue);
        break;

      case "model":
        setSearchedTermModel(searchValue);
        break;

      case "variant":
        setSearchedTermVarient(searchValue);
        break;

      case "rto":
        setSearchedTermRto(searchValue);

        break;

      default:
        break;
    }
  };

  useEffect(() => {
    (async function () {
      let carBrand = await dispatch(getCarBrandAction());
      setSectionOne(carBrand?.payload?.data?.vehicelinfo);
    })();
    dispatch(getRtoAction("rto"));
  }, [])

  let reorderedCarBrands = [];

  if (SearchedTerm !== "") {
    reorderedCarBrands = SearchedTerm
      ? sectionOne?.filter((item) =>
        item?.make?.toLowerCase()?.includes(SearchedTerm?.toLowerCase())
      )
      : [];
  } else {
    reorderedCarBrands = sectionOne
  }

  let reorderedCarModels = [];

  if (SearchedTermModel !== "") {
    reorderedCarModels = SearchedTermModel
      ? ModelList?.filter((item) =>
        item?.Model?.toLowerCase()?.includes(SearchedTermModel?.toLowerCase())
      )
      : [];
  } else {
    reorderedCarModels = ModelList
  }

  let reorderedCarVarients = [];

  if (SearchedTermVarient !== "") {
    reorderedCarVarients = variantList.filter((item) =>
      // item.Variant.toLowerCase().includes(SearchedTermVarient.toLowerCase()) ||
      // item.Cubic_Capacity.toLowerCase().includes(SearchedTermVarient.toLowerCase())
      (item?.Variant?.toLowerCase() + (item.Cubic_Capacity)?.toString())?.includes(SearchedTermVarient?.toLowerCase())

    );
  } else {
    reorderedCarVarients = variantList;
  }

  let reorderedRtoList = [];

  if (SearchedTermRto !== "") {
    reorderedRtoList = SearchedTermRto
      ? rtoArray?.filter((item) =>
        item?.value?.toLowerCase()?.includes(SearchedTermRto?.toLowerCase())
      )
      : [];
  } else {
    reorderedRtoList = rtoArray;
  }

  const handlePolicyStartDate = (e) => {
    localStorage.setItem("regn_dt", e)
    const { name, value } = e?.target;

    setManualInpValue((prevItems) => ({
      ...prevItems,
      [name]: value,
    }));
  };

  const handleMakeData = (id, name) => {
    setMakeId(id);
    localStorage.setItem("make", id);


    // Check if the new make is different from the current one
    setManualInpValue((prevItems) => {
      const isDifferentMake = prevItems.make !== name;
      const isDifferentModel = prevItems.model !== name;
      const isDifferentfuelType = prevItems.fuelType !== name;
      // const isDifferentVarient = prevItems.varient !== name;

      return {
        ...prevItems,
        make: name,
        model: isDifferentMake ? null : prevItems.model,  // Reset model only if the make changes
        fuelType: (isDifferentMake && isDifferentModel) ? null : prevItems.fuelType,  // Reset fuelType only if the make changes
        varient: (isDifferentMake && isDifferentModel && isDifferentfuelType) ? null : prevItems.varient,  // Reset varient only if the make changes
        // rto: isDifferentVarient ? null : prevItems.rto,  // Reset rto only if the make changes
        // regn_dt: isDifferentVarient ? null : prevItems.regn_dt,  // Reset regdate only if the make changes

      };
    });

    setFmcError((prevItems) => ({
      ...prevItems,
      makeErr: false,
      modelErr: false,
      fuelTypeErr: false,
    }));

    dispatch(getModelAction(id));
    setContentType("Model");
    dispatch(isFmcContentAction("Model"));
  };

  const handleModelData = (name, key) => {
    let fuelReqObj = {
      id: MakeId,
      modelName: name,
    };
    localStorage.setItem("model", name);
    // Check if the new model is different from the current one
    setManualInpValue((prevItems) => {
      const isDifferentModel = prevItems.model !== name;
      const isDifferentfuelType = prevItems.fuelType !== name;
      // const isDifferentVarient = prevItems.varient !== name;

      return {
        ...prevItems,
        model: name,
        fuelType: isDifferentModel ? null : prevItems.fuelType,  // Reset fuelType only if the model changes
        varient: (isDifferentModel && isDifferentfuelType) ? null : prevItems.varient,  // Reset varient only if the model changes
        // rto: isDifferentVarient ? null : prevItems.rto,  // Reset rto only if the model changes
        // regn_dt: isDifferentVarient ? null : prevItems.regn_dt,  // Reset regdate only if the model changes

      };
    });
    setFmcError((prevItems) => ({
      ...prevItems,
      makeErr: false,
      modelErr: false,
      fuelTypeErr: false,
      varientErr: false,
    }));

    dispatch(getFuelTypeAction(fuelReqObj));
    setContentType("FuelType");
    dispatch(isFmcContentAction("FuelType"));
  };

  const handleFuelTypeValue = (value) => {
    let variantReqObj = {
      id: MakeId,
      modelName: ManualInpValue?.model,
      selectedFuel: value,
    };
    setMmv(value);
    localStorage.setItem("fuelType", value);

    setContentType("Varient");
    dispatch(isFmcContentAction("Varient"));
    dispatch(getVariantAction(variantReqObj));

    // Check if the new fuelType is different from the current one
    setManualInpValue((prevItems) => {
      const isDifferentfuelType = prevItems.fuelType !== value;
      // const isDifferentVarient = prevItems.varient !== value;

      return {
        ...prevItems,
        fuelType: value,
        varient: isDifferentfuelType ? null : prevItems.varient,  // Reset varient only if the fuelType changes
        // rto: isDifferentVarient ? null : prevItems.rto,  // Reset rto only if the fuelType changes
        // regn_dt: isDifferentVarient ? null : prevItems.regn_dt,  // Reset regdate only if the fuelType changes

      };
    });
    setFmcError((prevItems) => ({
      ...prevItems,
      makeErr: false,
      varientErr: false,
      rtoErr: false,
    }));
  };

  const handleVarientData = (variant, cc) => {
    setMmv(variant);
    setManualInpValue((prevItems) => {
      localStorage.setItem("varient", variant);
      // const isDifferentVarient = prevItems.varient !== variant;
      return {
        ...prevItems,
        varient: variant,
        cc: cc,
        // rto: isDifferentVarient ? null : prevItems.rto,  // Reset rto only if the varient changes
        // regn_dt: isDifferentVarient ? null : prevItems.regn_dt,  // Reset regdate only if the varient changes

      }

    });
    setMmv(cc);
    localStorage.setItem("cc", cc);

    setFmcError((prevItems) => ({
      ...prevItems,
      makeErr: false,
      varientErr: false,
      fuelTypeErr: false,
    }));
    setContentType("RTO");
    dispatch(isFmcContentAction("RTO"));
  };

  const showBottomContent = (contentType) => {
    switch (contentType) {
      case "Make":
        setShowMakeBottom(!ShowMakeBottom);
    }
  };

  const handleRtoValue = (value, resolution = false) => {
    setIsManualRTOUpdate(true);
    setMmv(value);
    localStorage.setItem("rto", (resolution === "mobileView" ? value : value?.value));
    delete errors.rto

    setManualInpValue((prevItems) => ({
      ...prevItems,
      rto: resolution === "mobileView" ? value : value?.value,
    }));
    if (resolution === "mobileView") {
      setContentType("regYear");
      dispatch(isFmcContentAction("regYear"));
    }
  };

  const handleRegYearValue = (e, device = false) => {
    delete errors?.regn_dt;
    setMmv(e);

    const regnValue = e || e?.value;
    if (device === "mobile") {
      setManualInpValue((prevItems) => ({
        ...prevItems,
        regn_dt: e === "Buying a Brand New Car" ? currentYear : e,
        businessType: e === currentYear ? "New" : businessType,
      }));
      localStorage.setItem("regn_dt", String(regnValue));

      if (e === currentYear) {
        businessType = "New"
      }
      setContentType("PolicyDetails");
      dispatch(isFmcContentAction("PolicyDetails"));
      setSwitchFunction(true);
    } else {
      localStorage.setItem("regn_dt", e?.value);
      setManualInpValue((prevItems) => ({
        ...prevItems,
        regn_dt: e?.value,
      }));
    }
  };


  const handlePopUps = () => {
    if (ShowRtoList) {
      setShowRtoList(false);
    }

    if (RegYearList) {
      setRegYearList(false);
    }
  };

  let breadCrumb = [
    {
      name: "Make",
      head: "Company Brand:",
      value:
        ManualInpValue?.make === "Maruti"
          ? "Maruti Suzuki"
          : ManualInpValue?.make || "Select Make",
    },
    {
      name: "Model",
      head: "Brand Model:",
      value: ManualInpValue?.model ? ManualInpValue?.model : "Select Model",
    },
    {
      name: "FuelType",
      head: "Fuel Type:",
      value:
        ManualInpValue?.fuelType === "INTERNAL_LPG_CNG"
          ? "Internal Fitted CNG"
          : ManualInpValue?.fuelType || "Select Fuel Type",
    },
    {
      name: "Varient",
      head: "Variant:",
      value: ManualInpValue?.varient
        ? ManualInpValue?.varient + " - " + ManualInpValue?.cc + "cc"
        : "Select Variant",
    },
    {
      name: "RTO",
      secondName: "PolicyDetails",
      head: "RTO and year of Registration: ",
      value: (ManualInpValue?.rto && ManualInpValue?.regn_dt)
        ? ManualInpValue?.rto + " and " + ManualInpValue?.regn_dt
        : "Select RTO",
    },
  ];

  const validateModalInp = (obj) => {
    const newErrors = {};
    if (ManualInpValue?.businessType != "New") {
      if (obj.isPrevPolicyExp == null) {
        newErrors.isPrevPolicyExp = "First Name is required";
      }
      if (obj.isPrevPolicyExp === true) {
        if (obj.prevPolicyExpLastMonth === null) {
          newErrors.prevPolicyExpLastMonth = "First Name is required";
        }
      }
      if (obj.prevPolicyExpLastMonth !== true) {
        if (obj.isClaimPrevPolicy == null) {
          newErrors.isClaimPrevPolicy = "First Name is required";
        }
      }
    }

    if (obj.isCngLpgVehicleType === true) {
      if (!obj.cngKitValue) {
        newErrors.cngKitValue = "CNG/LPG Kit Value is required";
      }
    }

    let regexMobile = /^[2-9]{1}[0-9]{9}$/;

    if (!obj.mobileNo) {
      newErrors.phoneNumber = "Phone Number is required";
    }

    if (!obj.rto) {
      newErrors.rto = "RTO is required";
    }

    if (!obj.regn_dt) {
      newErrors.regn_dt = "Registration year is required";
    }

    if (ManualInpValue?.businessType === "New") {
      if (!obj.policyStartDate) {
        newErrors.policyStartDate = "Policy Start Date is required";
      }
    }

    if (obj.mobileNo && !regexMobile.test(obj.mobileNo)) {
      newErrors.phoneNumber = "Mobile number is Invalid";
    }

    if (!obj.pincode) {
      newErrors.pincode = "Pin code is required";
    }

    let pinregEx = /^[1-9]{1}[0-9]{2}[0-9]{3}$/;

    if (obj.pincode && !pinregEx.test(obj.pincode)) {
      newErrors.pincode = "Invalid pin code";
    }

    console.log(newErrors, "newEdrrors");


    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleRoute = (vehicleInfo) => {
    if (vehicleInfo.e) {
      vehicleInfo?.e?.preventDefault();
    }

    const storeVehicleInfo = vehicleInfo;
    const isValid = validateModalInp(storeVehicleInfo);
    if (isValid) {
      const data = {
        data: {
          data: vehicleInfo,
        },
      };
      delete vehicleInfo.e
      dispatch(updateVehicleInfoAction(data));

      async function DigitApisPlan() {
        await dispatch(digitQuickQuoteAction12(data));
      }

      DigitApisPlan()

      localStorage.setItem("isFindMyCar", true);
      localStorage.setItem("mobileNo", vehicleInfo.mobileNo);
      localStorage.setItem("pincode", vehicleInfo.pincode);
      localStorage.setItem("vehicleNumber", ManualInpValue?.rto?.split("-")[0])

      navigate(dealerDetails?.dealer_code ?
        `/View-Plans?id=${uuid}&Franchise_ID=${dealerDetails?.dealer_code}`
        : "/View-Plans?id=" + uuid, {
        state: {
          vehicleNumber: vehicleNumber,
          businessType: ManualInpValue?.businessType,
          isFindMyCar: true,
        },
      });

    }
  };

  const validateRto = (obj) => {
    const newErrors = {};
    if (!obj.rto) {
      newErrors.rto = "RTO is required";
    }

    if (!obj.regn_dt) {
      newErrors.regn_dt = "Registration year is required";
    }

    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      setContentType("PolicyDetails");
      dispatch(isFmcContentAction("PolicyDetails"));
    }
  };

  const handleRouteNew = (manualValues) => {

    const isValid = validateModalInp(manualValues);

    if (isValid) {
      const data = {
        data: {
          data: manualValues,
        },
      };
      delete manualValues.e
      dispatch(updateVehicleInfoAction(data));
      localStorage.setItem("mobileNo", manualValues.mobileNo);
      localStorage.setItem("pincode", manualValues.pincode);
      localStorage.setItem("isFindMyCar", true);
      navigate(dealerDetails?.dealer_code ?
        `/View-Plans?id=${uuid}&Franchise_ID=${dealerDetails?.dealer_code}`
        : "/View-Plans?id=" + uuid, {
        state: {
          vehicleNumber: vehicleNumber,
          businessType: ManualInpValue?.businessType,
          isFindMyCar: true,
        },
      });
    }
  };

  const handleContentReveal = (content) => {
    switch (content) {
      case "Make":
        setContentType("Make");
        dispatch(isFmcContentAction("Make"));
        break;

      case "Model":
        if (ManualInpValue?.make != null) {
          if (ContentType === "Make") {
            if (ManualInpValue?.make != null) {
              setContentType("Model");
              dispatch(isFmcContentAction("Model"));
              setFmcError((prevItems) => ({
                ...prevItems,
                makeErr: false,
              }));
            } else {
              setContentType("Make");
              dispatch(isFmcContentAction("Make"))
            }
          } else {
            setContentType("Model");
            dispatch(isFmcContentAction("Model"))
            setFmcError((prevItems) => ({
              ...prevItems,
              makeErr: false,
            }));
          }
        } else {
          // alert("kakakaka")
          setFmcError((prevItems) => ({
            ...prevItems,
            makeErr: true,
          }));
        }
        break;

      case "FuelType":
        if (ManualInpValue?.make != null && ManualInpValue?.model != null) {
          setContentType("FuelType");
          dispatch(isFmcContentAction("FuelType"))
          setFmcError((prevItems) => ({
            ...prevItems,
            fuelTypeErr: false,
            makeErr: false,
          }));
        } else {
          setFmcError((prevItems) => ({
            ...prevItems,
            fuelTypeErr: true,
            makeErr: true,
          }));
        }
        break;

      case "Varient":
        if (
          ManualInpValue?.make != null &&
          ManualInpValue?.model != null &&
          ManualInpValue?.fuelType != null
        ) {
          setContentType("Varient");
          dispatch(isFmcContentAction("Varient"));
          setFmcError((prevItems) => ({
            ...prevItems,
            varientErr: false,
            fuelTypeErr: false,
            makeErr: false,
          }));
        } else {
          setFmcError((prevItems) => ({
            ...prevItems,
            varientErr: true,
            fuelTypeErr: true,
            makeErr: true,
          }));
        }
        break;

      case "RTO":
        if (
          ManualInpValue?.make != null &&
          ManualInpValue?.model != null &&
          ManualInpValue?.fuelType != null &&
          ManualInpValue?.varient != null

        ) {
          setContentType("RTO");
          dispatch(isFmcContentAction("RTO"));
          setFmcError((prevItems) => ({
            ...prevItems,
            varientErr: false,
            fuelTypeErr: false,
            makeErr: false,
            rtoErr: false,
          }));
        } else {
          setFmcError((prevItems) => ({
            ...prevItems,
            varientErr: true,
            fuelTypeErr: true,
            makeErr: true,
            rtoErr: true,
          }));
        }
        break;

      case "PolicyDetails":
        if (
          ManualInpValue?.make != null &&
          ManualInpValue?.model != null &&
          ManualInpValue?.fuelType != null &&
          ManualInpValue?.varient != null &&
          ManualInpValue?.rto != null &&
          ManualInpValue?.regn_dt != null
        ) {
          setContentType("PolicyDetails");
          dispatch(isFmcContentAction("PolicyDetails"));
          setFmcError((prevItems) => ({
            ...prevItems,
            varientErr: false,
            fuelTypeErr: false,
            makeErr: false,
            rtoErr: false,
            policyDetailsErr: false,
          }));
        } else {
          setFmcError((prevItems) => ({
            ...prevItems,
            varientErr: true,
            fuelTypeErr: true,
            makeErr: true,
            rtoErr: true,
            policyDetailsErr: true,
          }));
        }
        break;

      default:
        break;
    }
  };

  const handleCheckboxChange = (checkboxName) => {
    setManualInpValue({
      ...ManualInpValue,
      [checkboxName]: !ManualInpValue[checkboxName],
    });
  };

  const backToHome = () => {
    navigate("/");
  };

  const handleRouteToFMC = () => {
    setContentType("Make");
  }

  const closeModal = () => {
    if (window.innerWidth <= 610) {
      setContentType("regYear");

    } else {
      navigate("/");
    }
  }

  const validationMobDevice = (form) => {
    const newErrors = {};

    if (form.isPrevPolicyExp == null) {
      newErrors.isPrevPolicyExp = "First Name is required";
    }

    if (form.isPrevPolicyExp === true) {
      if (form.prevPolicyExpLastMonth === null) {
        newErrors.prevPolicyExpLastMonth = "First Name is required";
      }
    }

    if (form.prevPolicyExpLastMonth !== true) {
      if (form.isClaimPrevPolicy == null) {
        newErrors.isClaimPrevPolicy = "First Name is required";
      }
    }

    if (form.isCngLpgVehicleType === true) {
      if (!form.cngKitValue) {
        newErrors.cngKitValue = "CNG/LPG Kit Value is required";
      }
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  }

  const handleProceed = (obj) => {
    const isValid = validationMobDevice(obj);
    if (isValid) {
      setShowUserDataModal(true);
    }
  }

  const handleManualInpValue = (e) => {
    const { value } = e?.target || {};
    if (value.length <= 10) {
      setManualInpValue((prevItems) => ({
        ...prevItems,
        mobileNo: value,
      }));
      localStorage.setItem("mobileNo", String(value));
    }
  }
  
  const cancelCalendarValue = () => {
    setPolStartDateClone(ManualInpValue?.policyStartDate)
    setShowPolicyStartDate(false);
  };

  const saveCalendarValue = () => {

    if (PolStartDateClone) {
     
      setManualInpValue((previtems) => ({
        ...previtems,
        policyStartDate: PolStartDateClone,
      }))
      localStorage.setItem("policyStartDate", PolStartDateClone)
      setShowPolicyStartDate(false);
    }
  };

  const handlePolStartDateClone = (value) => {
    setPolStartDateClone(value)
    setIsEnableBtn(false);
  };

  useEffect(() => {
    const listOfInputfield = ['mobileNo', 'pincode', 'policyStartDate', 'rto', 'regn_dt'];
    listOfInputfield.forEach(item => {
      const storedValue = localStorage.getItem(item);
      if (storedValue) {
        setManualInpValue(prevItems => ({
          ...prevItems,
          [item]: storedValue,
        }));
      }
    });
  }, []);

  return (
    <>
      {
        window.innerWidth <= 610 && ContentType !== "PolicyDetails" ? <Navbar /> : <Navbar />
      }
      {/* {ContentType !== "PolicyDetails"  && <Navbar />} */}

      <div className={`FindMyCar_container ${ContentType !== "PolicyDetails" && "Hide_FindMyCar_container"} `} onClick={handlePopUps}>
        <div className="findMyCar_parent maxWidth">
          <div className="content_block-findMyCar">
            <div className="leftContent_findMyCar contentDivide_findMyCar ">
              <p className="leftContent_findMyCar_head">Your Car Details</p>
              {breadCrumb?.map((item, key) => (
                <div
                  key={key}
                  className={
                    ContentType === item?.name || ContentType === item?.secondName
                      ? "info_block_left-active info_block_left_findMyCar"
                      : "info_block_left_findMyCar"
                  }
                  onClick={(e) => handleContentReveal(item.name)}
                >
                  <p className="info_block_left_head">{item?.head} </p>
                  <p className="info_block_left_desc">{item?.value}</p>
                </div>
              ))}
            </div>
            <div
              className={
                ContentType === "PolicyDetails"
                  ? "rightContent_findMyCar height-brandContainer-findMyCar contentDivide_findMyCar"
                  : "rightContent_findMyCar  contentDivide_findMyCar"
              }
            >
              {
                ContentType !== "PolicyDetails" ? (
                  <p className="mobile-back-fmc" onClick={(e) => backToHome()}>
                    <img src={backIcon} alt="" srcSet="" /> Back to home
                  </p>
                ) : null
              }
              <div className="brandContainer-findMyCar">

                {ContentType === "Make" ? (
                  <>
                    <p className="title_brandCar">Select my Car Brand </p>
                    {FmcError?.makeErr === true && (
                      <p className="imp-msg ">
                        Please select your car Brand to proceed.
                      </p>
                    )}
                  </>
                ) : ContentType === "Model" ? (
                  <>
                    <p className="title_brandCar">Select my Car Model</p>
                    {FmcError?.fuelTypeErr === true && (
                      <p className="imp-msg ">
                        Please select your car Model to proceed.
                      </p>
                    )}
                  </>
                ) : ContentType === "FuelType" ? (
                  <>
                    <p className="title_brandCar">Select my Car Fuel Type</p>
                    {FmcError?.varientErr === true && (
                      <p className="imp-msg ">
                        Please select your car Fuel Type to proceed.
                      </p>
                    )}
                  </>
                ) : ContentType === "RTO" ? (
                  <>
                    <p className="title_brandCar">
                      Select RTO and year of car registration
                    </p>
                    {FmcError?.policyDetailsErr === true && (

                      <>
                        {
                          (ManualInpValue?.rto !== undefined && ManualInpValue?.regn_dt === undefined) && (
                            <p className="imp-msg ">
                              Please select your Registration Year to proceed.
                            </p>
                          )
                        }

                        {
                          (ManualInpValue?.rto === undefined && ManualInpValue?.regn_dt !== undefined) && (
                            <p className="imp-msg ">
                              Please select your RTO to proceed.
                            </p>
                          )
                        }

                        {
                          (ManualInpValue?.rto === undefined && ManualInpValue?.regn_dt === undefined) && (
                            <p className="imp-msg ">
                              Please select your RTO and Registration Year to proceed.
                            </p>
                          )
                        }
                      </>
                    )}
                  </>
                ) : ContentType === "Varient" ? (
                  <>
                    <p className="title_brandCar">Select my car Variant</p>
                    {FmcError?.rtoErr === true && (
                      <p className="imp-msg ">
                        Please select your car Varient to proceed.
                      </p>
                    )}
                  </>
                ) : (ContentType === "PolicyDetails" && ManualInpValue?.businessType === NEW) ? (
                  <>
                    <p className="title_brandCar">Policy Details </p>
                  </>
                ) : null}

                {ContentType === "Make" && (
                  <>
                    <div className="brandLogoContainer-input">
                      <img
                        src={search}
                        className="search-fmc"
                        alt=""
                        srcSet=""
                      />
                      <input
                        type="text"
                        className="fmcInput"
                        id="fmcInput"
                        name=""
                        value={SearchedTerm}
                        placeholder="Search your car brand"
                        onChange={(e) =>
                          handleSearchQuery(e.target.value, "make")
                        }
                      />
                    </div>
                    <div className="brandLogoContainer-findMyCar  logo-findmycar model-findmycar">
                      {
                        !sectionOne ? (
                          <>
                            <div className="skeleton-container-fmc">
                              <SkeletonLoader height={60} />
                              <SkeletonLoader height={60} />
                              <SkeletonLoader height={60} />
                              <SkeletonLoader height={60} />
                              <SkeletonLoader height={60} />
                              <SkeletonLoader height={60} />
                              <SkeletonLoader height={60} />
                              <SkeletonLoader height={60} />
                              <SkeletonLoader height={60} />
                              <SkeletonLoader height={60} />
                              <SkeletonLoader height={60} />
                              <SkeletonLoader height={60} />
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="fmc-btn-container fmc-btn-container-varient">
                              {!ShowMakeBottom ? (
                                <>
                                  {reorderedCarBrands?.map(
                                    (item, key) =>
                                      key < 9 && (
                                        <div
                                          className={
                                            ManualInpValue.make === item.make
                                              ? "model_tab model_tab-active"
                                              : "model_tab"
                                          }
                                          key={key}
                                          onClick={(e) =>
                                            handleMakeData(item.make, item.make)
                                          }
                                        >
                                          <p>{item.make}</p>
                                        </div>
                                      )
                                  )}
                                </>
                              ) : (
                                <>
                                  {reorderedCarBrands?.map((item, key) => (
                                    <div
                                      className={
                                        ManualInpValue.make === item.make
                                          ? "model_tab model_tab-active"
                                          : "model_tab"
                                      }
                                      key={key}
                                      onClick={(e) =>
                                        handleMakeData(item.make, item.make)
                                      }
                                    >
                                      <p>{item.make}</p>
                                    </div>
                                  ))}
                                </>
                              )}
                            </div>
                            <div className="btnContainer-fmc">
                              <Botton
                                className="secondry_btn secondry_btn-fmc"
                                text={ShowMakeBottom ? "Hide" : "View all 44 brands"}
                                event={(e) => showBottomContent("Make")}
                              />
                            </div>
                          </>
                        )
                      }
                    </div>
                    <div className="btnContainer-fmc btnContainer-fmc-noPadding btnContainer-fmc-mobile">
                      <Botton
                        className="secondry_btn secondry_btn-fmc"
                        text="Back"
                        event={(e) => backToHome()}
                      />
                      <Botton
                        className="button secondry_btn-fmc"
                        text="Next"
                        event={(e) => handleContentReveal("Model")}
                      />
                    </div>
                  </>
                )}

                {ContentType === "Model" && (
                  <>
                    <div className="brandLogoContainer-input">
                      <img
                        src={search}
                        className="search-fmc"
                      />
                      <input
                        type="text"
                        className="fmcInput"
                        id="fmcInput"
                        name=""
                        value={SearchedTermModel}
                        placeholder="Search your car model"
                        onChange={(e) =>
                          handleSearchQuery(e.target.value, "model")
                        }
                      />
                    </div>
                    {
                      getModelValue?.isLoading ? (
                        <>
                          <div className="skeleton-container-fmc skeleton-container-fmc-border">
                            <SkeletonLoader height={60} />
                            <SkeletonLoader height={60} />
                            <SkeletonLoader height={60} />
                            <SkeletonLoader height={60} />
                            <SkeletonLoader height={60} />
                            <SkeletonLoader height={60} />
                            <SkeletonLoader height={60} />
                            <SkeletonLoader height={60} />
                            <SkeletonLoader height={60} />
                            <SkeletonLoader height={60} />
                            <SkeletonLoader height={60} />
                            <SkeletonLoader height={60} />
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="brandLogoContainer-findMyCar logo-findmycar model-findmycar ">
                            <div
                              className={
                                getModelValue?.isLoading === true
                                  ? " fmc-btn-container fmc-btn-container-varient blurEffect"
                                  : "fmc-btn-container fmc-btn-container-varient"
                              }
                            >
                              {reorderedCarModels?.map((item, key) => (
                                <Botton
                                  key={key}
                                  className={
                                    ManualInpValue?.model === item.Model
                                      ? "model_tab model_tab-active"
                                      : "model_tab"
                                  }
                                  text={item.Model}
                                  event={(e) => handleModelData(item.Model, key)}
                                />
                              ))}
                            </div>
                          </div>
                          <div className="btnContainer-fmc btnContainer-fmc-noPadding btnContainer-fmc-mobile ">
                            <Botton
                              className="secondry_btn secondry_btn-fmc"
                              text="Back"
                              event={(e) => setContentType("Make")}
                            />
                            <Botton
                              className="button secondry_btn-fmc"
                              text="Next"
                              event={(e) => handleContentReveal("FuelType")}
                            />
                          </div>
                        </>
                      )
                    }
                  </>
                )}

                {ContentType === "FuelType" && (
                  <>
                    {
                      getFuelTypeValue?.isLoading ? (
                        <>
                          <div className="skeleton-container-fmc skeleton-container-fmc-border">
                            <SkeletonLoader height={60} />
                            <SkeletonLoader height={60} />
                            <SkeletonLoader height={60} />
                            <SkeletonLoader height={60} />
                            <SkeletonLoader height={60} />
                            <SkeletonLoader height={60} />
                            <SkeletonLoader height={60} />
                            <SkeletonLoader height={60} />
                            <SkeletonLoader height={60} />
                            <SkeletonLoader height={60} />
                            <SkeletonLoader height={60} />
                            <SkeletonLoader height={60} />
                          </div>
                        </>
                      ) : (
                        <>
                          <div
                            className={
                              getFuelTypeValue?.isLoading === true
                                ? " brandLogoContainer-findMyCar fuelTypeContainer-findMyCar blurEffect"
                                : "brandLogoContainer-findMyCar fuelTypeContainer-findMyCar"
                            }
                          >
                            {fuelList?.map((fuel, key) => (
                              <Botton
                                key={key}
                                text={
                                  fuel.Fuel_Type === "INTERNAL_LPG_CNG"
                                    ? "Internal Fitted CNG"
                                    : fuel.Fuel_Type
                                }
                                className={
                                  ManualInpValue?.fuelType === fuel.Fuel_Type
                                    ? "fuelType-btn fuelType-btn-active "
                                    : "fuelType-btn"
                                }
                                event={(e) => handleFuelTypeValue(fuel.Fuel_Type)}
                              />
                            ))}
                          </div>
                          <div className="btnContainer-fmc btnContainer-fmc-noPadding btnContainer-fmc-mobile">
                            <Botton
                              className="secondry_btn secondry_btn-fmc"
                              text="Back"
                              event={(e) => setContentType("Model")}
                            />
                            <Botton
                              className="button secondry_btn-fmc"
                              text="Next"
                              event={(e) => handleContentReveal("Varient")}
                            />
                          </div>
                        </>
                      )
                    }
                  </>
                )}

                {ContentType === "Varient" && (
                  <>
                    <div className="brandLogoContainer-input">
                      <img
                        src={search}
                        className="search-fmc"
                        alt=""
                        srcSet=""
                      />
                      <input
                        type="text"
                        className="fmcInput"
                        id="fmcInput"
                        name=""
                        value={SearchedTermVarient}
                        placeholder="Search your car Variant"
                        onChange={(e) =>
                          handleSearchQuery(e.target.value, "variant")
                        }
                      />
                    </div>

                    {
                      getVariantValue?.isLoading ? (
                        <>
                          <div className="skeleton-container-fmc skeleton-container-fmc-border">
                            <SkeletonLoader height={60} />
                            <SkeletonLoader height={60} />
                            <SkeletonLoader height={60} />
                            <SkeletonLoader height={60} />
                            <SkeletonLoader height={60} />
                            <SkeletonLoader height={60} />
                            <SkeletonLoader height={60} />
                            <SkeletonLoader height={60} />
                            <SkeletonLoader height={60} />
                            <SkeletonLoader height={60} />
                            <SkeletonLoader height={60} />
                            <SkeletonLoader height={60} />
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="brandLogoContainer-findMyCar logo-findmycar model-findmycar  ">
                            <div
                              className={
                                getVariantValue?.isLoading === true
                                  ? " fmc-btn-container fmc-btn-container-varient blurEffect"
                                  : "fmc-btn-container fmc-btn-container-varient"
                              }
                            >
                              {reorderedCarVarients?.map((item, key) => (
                                <button
                                  key={key}
                                  className={
                                    ManualInpValue?.varient === item.Variant
                                      ? "model_tab variant_tab_fmc model_tab-active "
                                      : "model_tab variant_tab_fmc "
                                  }
                                  onClick={(e) => handleVarientData(item.Variant, item.Cubic_Capacity)}
                                >
                                  <p> {item.Variant} <span>{item.Cubic_Capacity} {" "} CC</span> </p>
                                </button>
                              ))}
                            </div>
                          </div>
                          <div className="btnContainer-fmc btnContainer-fmc-noPadding btnContainer-fmc-mobile">
                            <Botton
                              className="secondry_btn secondry_btn-fmc"
                              text="Back"
                              event={(e) => setContentType("FuelType")}
                            />
                            <Botton
                              className="button secondry_btn-fmc"
                              text="Next"
                              event={(e) => handleContentReveal("RTO")}
                            />
                          </div>
                        </>
                      )
                    }
                  </>
                )}

                {ContentType === "RTO" && (
                  <form action="" onSubmit={(e) => { e.preventDefault(); }}
                    className={
                      businessType === "New"
                        ? "brandLogoContainer-findMyCar fuelTypeContainer-findMyCar  flexDiv-findMyCar fuelTypeContainer-findMyCar-  "
                        : "brandLogoContainer-findMyCar fuelTypeContainer-findMyCar  flexDiv-findMyCar"
                    }
                  >
                    <div className="input_group_pform input_group-FMC ">
                      <div className="inputGropOne_form inputGrop ">
                        <SearchSelect
                          type="submit"
                          optionList={rtoArray}
                          handleSelect={handleRtoValue}
                          value={ManualInpValue?.rto}
                          ManualInpValue={ManualInpValue}
                          placeholder="Select RTO"
                          label="Select RTO"
                          errState={errors?.rto ? true : false}
                          errMsg={errors?.rto ? errors?.rto : ""}
                        />
                      </div>
                      <div className="inputGropOne_form inputGrop ">
                        <SearchSelect
                          type="submit"
                          optionList={yearOfReg}
                          businessType={businessType}
                          handleSelect={handleRegYearValue}
                          value={Number(ManualInpValue?.regn_dt)}
                          ManualInpValue={ManualInpValue}
                          placeholder="Year of Registration"
                          label="Registration Year"
                          errState={errors?.regn_dt ? true : false}
                          errMsg={errors?.regn_dt ? errors?.regn_dt : ""}
                        />
                      </div>
                    </div>
                    <div className="btnContainer-fmc btnContainer-fmc-rto btnContainer-fmc-noPadding ">
                      <Botton
                        className="secondry_btn secondry_btn-fmc secondry_btn-fmc-rto"
                        text="Back"
                        event={(e) => setContentType("Varient")}
                      />
                      {businessType === NEW ? (
                        <Botton
                          className="button secondry_btn-fmc secondry_btn-fmc-rto"
                          text="Next"
                          type="submit"
                          event={(e) => validateRto({ ...ManualInpValue })}
                        />
                      ) : (
                        <Botton
                          className="button secondry_btn-fmc secondry_btn-fmc-rto"
                          text="Next"
                          type="submit"
                          event={(e) => handleContentReveal("PolicyDetails")}
                        />
                      )}
                    </div>
                  </form >
                )}

                {
                  ContentType === "PolicyDetails" && (
                    <>
                      {
                        ManualInpValue?.businessType === "New" ? (
                          <>
                            {
                              window.innerWidth <= 610 ? (
                                <div
                                  className="edit-tabs-container-mob-height"
                                  data-aos="fade-up"
                                  data-aos-anchor-placement="top-bottom"
                                >
                                  <div className="active-bottom-edit-policy-tabs edit-modalInp-container">
                                    <div className="inputGropOne_form inputGrop inputs-parent-mob-modal">
                                      <p className="head-p" >Policy Start Date <span> <img src={close} onClick={(e) => setContentType("RTO")} /> </span> </p>
                                      <div className=" input_group_editForm ">
                                        <div className="inputGropOne_form inputGrop ">
                                          <CustomDateField
                                            className="pForm_datePicker pForm_datePicker-tab "
                                            btnId="policyStartDateBtn"
                                            textId="policyStartDateTxt"
                                            imgId="policyStartDateImg"
                                            dateValue={ManualInpValue?.policyStartDate}
                                            placeholder="Select Policy Start Date"
                                            placeholderId="polStartDateId"
                                            calendarReveal={ShowPolicyStartDate}
                                            setCalendarReveal={setShowPolicyStartDate}
                                            changeEvent={(e) => { handlePolicyStartDate(e); delete errors?.policyStartDate }}
                                            errClassName={errors?.policyStartDate ? "input-err" : ""}
                                            errMsg={errors?.policyStartDate}
                                          />
                                        </div>
                                        <div className="inputGropOne_form inputGrop inputGrop-hide-mobile "></div>
                                      </div>

                                    </div>

                                    <div className="inputs-parent-mob-modal">
                                      <p className="head-p" >Enter Phone Number</p>
                                      <InputFields
                                        value={ManualInpValue?.mobileNo}
                                        placeholder="Phone Number"
                                        id="mobileNo"
                                        type="number"
                                        name="mobileNo"
                                        errState={errors?.phoneNumber ? true : false}
                                        errMsg={errors?.phoneNumber}
                                        errClassName="input-err"
                                        onChangeEvent={(e) => {
                                          handleManualInpValue(e)
                                          delete errors?.phoneNumber
                                        }
                                        }
                                        hideLabel={true}
                                        onKeyPress={(e) => {
                                          if (ManualInpValue?.mobileNo?.length >= 10) {
                                            e.preventDefault();
                                          }
                                        }}
                                      />
                                    </div>

                                    <div className="inputs-parent-mob-modal">
                                      <p>Enter Pincode </p>
                                      <InputFields
                                        value={ManualInpValue?.pincode}
                                        placeholder="Pin code"
                                        id="pincode"
                                        type="number"
                                        name="pincode"
                                        errState={errors?.pincode ? true : false}
                                        errMsg={errors?.pincode}
                                        errClassName="input-err"
                                        hideLabel={true}
                                        onChangeEvent={(e) => {
                                          let newValue = e.target.value;
                                          if (newValue.length <= 6) {
                                            setManualInpValue((prevItems) => ({
                                              ...prevItems,
                                              pincode: newValue,
                                            }));
                                            localStorage.setItem("pincode", String(newValue));
                                          }
                                          delete errors?.pincode;
                                        }}
                                        onKeyPress={(e) => {
                                          if (ManualInpValue?.pincode?.length >= 6) {
                                            e.preventDefault();
                                          }
                                        }}
                                      />
                                    </div>

                                    <div className="policy-type-list-container-mob" style={{ padding: "0rem" }}>
                                      <section className="btnContainer_pform btnContainer_pform-edit">
                                        <Botton
                                          text="Check Premium"
                                          className="button btn_pForm"
                                          event={() => handleRouteNew({ ...ManualInpValue })}
                                        />
                                      </section>
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                <form action="" onSubmit={(e) => { e.preventDefault(); }}
                                  className={
                                    ManualInpValue?.businessType === "New"
                                      ? "brandLogoContainer-findMyCar fuelTypeContainer-findMyCar  flexDiv-findMyCar fuelTypeContainer-findMyCar-"
                                      : "brandLogoContainer-findMyCar fuelTypeContainer-findMyCar  flexDiv-findMyCar"
                                  }
                                >
                                  <div className="input_group_pform input_group-FMC input_group_new ">
                                    <div className="inputGropOne_form inputGrop ">
                                      {/* <div className=" input_group_editForm "> */}
                                      {/* <div className="inputGropOne_form inputGrop "> */}
                                      <CustomDateField
                                        className="pForm_datePicker pForm_datePicker-tab "
                                        btnId="policyStartDateBtn"
                                        textId="policyStartDateTxt"
                                        imgId="policyStartDateImg"
                                        dateValue={ManualInpValue?.policyStartDate}
                                        placeholder="Select Policy Start Date"
                                        placeholderId="polStartDateId"
                                        calendarReveal={ShowPolicyStartDate}
                                        setCalendarReveal={setShowPolicyStartDate}
                                        changeEvent={(e) => { handlePolicyStartDate(e); delete errors?.policyStartDate }}
                                        errClassName={errors?.policyStartDate ? "input-err" : ""}
                                        errMsg={errors?.policyStartDate}
                                      />
                                      {/* </div> */}
                                      <div className="inputGropOne_form inputGrop inputGrop-hide-mobile "></div>
                                      {/* </div> */}
                                    </div>

                                    <div className="inputGropOne_form inputGrop ">

                                      <InputFields
                                        value={ManualInpValue?.mobileNo}
                                        placeholder="Phone Number"
                                        id="mobileNo"
                                        type="number"
                                        name="mobileNo"
                                        errState={errors?.phoneNumber ? true : false}
                                        errMsg={errors?.phoneNumber}
                                        errClassName="input-err"
                                        onChangeEvent={(e) => { handlePhoneNumber(e); delete errors?.phoneNumber }}
                                        onKeyPress={(e) => {
                                          if (ManualInpValue?.mobileNo?.length >= 10) {
                                            e.preventDefault();
                                          }
                                        }}
                                      />
                                    </div>
                                  </div>
                                  <div className="input_group_pform input_group-FMC">
                                    <div className="inputGropOne_form inputGrop ">
                                      <InputFields
                                        value={ManualInpValue?.pincode}
                                        placeholder="Pin code"
                                        id="pincode"
                                        type="number"
                                        name="pincode"
                                        errState={errors?.pincode ? true : false}
                                        errMsg={errors?.pincode}
                                        errClassName="input-err"
                                        onChangeEvent={(e) => {
                                          let newValue = e.target.value;
                                          if (newValue.length <= 6) {
                                            setManualInpValue((prevItems) => ({
                                              ...prevItems,
                                              pincode: newValue,
                                            }));
                                            localStorage.setItem("pincode", String(newValue));
                                          }
                                          delete errors?.pincode;
                                        }}
                                        onKeyPress={(e) => {
                                          if (ManualInpValue?.pincode?.length >= 6) {
                                            e.preventDefault();
                                          }
                                        }}
                                      />
                                    </div>
                                    <div className="inputGropOne_form inputGrop inputGrop-fmc-new ">
                                    </div>
                                  </div>
                                  <div className="btnContainer-fmc btnContainer-fmc-rto btnContainer-fmc-noPadding ">
                                    <Botton
                                      className="secondry_btn secondry_btn-fmc secondry_btn-fmc-rto"
                                      text="Back"
                                      event={(e) => setContentType("RTO")}
                                    />
                                    <Botton
                                      className="button secondry_btn-fmc secondry_btn-fmc-rto"
                                      text="Check Premium"
                                      type="submit"
                                      event={(e) => handleRouteNew({ ...ManualInpValue })}
                                    />
                                  </div>
                                </form>
                              )
                            }
                          </>

                        ) : (
                          <ModalInp
                            type="submit"
                            ManualInpValue={ManualInpValue}
                            businessType={ManualInpValue?.businessType}
                            clickEvent={handleRoute}
                            validateModalInp={validateModalInp}
                            errors={errors}
                            uniqueClass="positionUnique"
                            vehicleType="4W"
                            make={ManualInpValue?.make}
                            model={ManualInpValue?.model}
                            varient={ManualInpValue?.varient}
                            fuelType={ManualInpValue?.fuelType}
                            regDate={ManualInpValue?.regn_dt}
                            vehicleNumber={ManualInpValue?.rto}
                            handleRouteToFMC={handleRouteToFMC}
                            closeModal={closeModal}
                            SwitchFunction={SwitchFunction}
                            handleProceed={handleProceed}
                            setShowUserDataModal={setShowUserDataModal}
                            ShowUserDataModal={ShowUserDataModal}
                            setManualInpValue={setManualInpValue}
                            makeList={sectionOne}
                            ModelList={ModelList}
                            fuelList={fuelList}
                            variantList={variantList}
                            yearOfRegistration={yearOfRegistration}
                            currentYear={currentYear}
                            rtoArray={rtoArray}
                            handleRtoValue={handleRtoValue}
                          />
                        )
                      }
                    </>
                  )
                }
              </div >
            </div >
          </div >
        </div >
      </div >

      {/* FMC CONTAINER - MOBILE */}

      <FindMyVehicleMob
        reorderedCarBrands={reorderedCarBrands}
        ShowMakeBottom={ShowMakeBottom}
        showBottomContent={showBottomContent}
        handleSearchQuery={handleSearchQuery}
        SearchedTerm={SearchedTerm}
        backToHome={backToHome}
        ContentType={ContentType}
        handleMakeData={handleMakeData}
        SearchedTermModel={SearchedTermModel}
        ManualInpValue={ManualInpValue}
        setContentType={setContentType}
        reorderedCarModels={reorderedCarModels}
        handleModelData={handleModelData}
        setShowModelBottom={setShowModelBottom}
        ShowModelBottom={ShowModelBottom}
        fuelList={fuelList}
        handleFuelTypeValue={handleFuelTypeValue}
        SearchedTermVarient={SearchedTermVarient}
        reorderedCarVarients={reorderedCarVarients}
        handleVarientData={handleVarientData}
        SearchedTermRto={SearchedTermRto}
        rtoArray={reorderedRtoList}
        handleRtoValue={handleRtoValue}
        setShowRtoBottom={setShowRtoBottom}
        ShowRtoBottom={ShowRtoBottom}
        yearOfReg={yearOfReg}
        handleRegYearValue={handleRegYearValue}
        currentYear={currentYear}
        handleRoute={handleRoute}
        validateModalInp={validateModalInp}
        errors={errors}
        uniqueClass="positionUnique"
        vehicleType="4W"
        businessType={ManualInpValue?.businessType}
        handleRouteToFMC={handleRouteToFMC}
        SwitchFunction={SwitchFunction}
      />

      {/* policy Start Date */}

      {
        ShowPolicyStartDate && (
          <CustomCalendar
            value={PolStartDateClone}
            headText="Select your Policy Start Date"
            name="policyStartDate"
            btnId="policyStartDateBtn"
            textId="polStartDateId"
            imgId="policyStartDateImg"
            placeholderId="polStartDatePlaceholderId"
            revealState={ShowPolicyStartDate}
            setRevealState={setShowPolicyStartDate}
            PolStartDateClone={PolStartDateClone}
            handleCopiedDetails={handlePolStartDateClone}
            cancelCalendarValue={cancelCalendarValue}
            saveCalendarValue={saveCalendarValue}
            onClose={(e) => setShowPolicyStartDate(false)}
            isEnalbeBtn={isEnalbeBtn}
          // maxDate={maxDobDate}
          />
        )
      }

      <Footer />
    </>
  );
};

export default FindMyCarDetails;
