import { getpolicyDataAction, getRenewpolicyDataAction } from "../Actions/GetpolicyDataAction";
import { createSlice } from "@reduxjs/toolkit";

export const getpolicydataReducer = createSlice({
  name: "getpolicydataReducer",
  initialState: {
    isLoading: false,
    data: null,
    isError: false,
  },
  extraReducers: (builder) => {
    builder.addCase(getpolicyDataAction.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getpolicyDataAction.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    });
    builder.addCase(getpolicyDataAction.rejected, (state, action) => {

      state.isError = true;
      state.isLoading = false;
    });
  },
  reducers: {
    resetPolicyDataAction: (state, action) => {
      state.data = undefined
    }
  }
});
export const getRenewpolicydataReducer = createSlice({
  name: "getpolicydataReducer",
  initialState: {
    isLoading: false,
    data: null,
    isError: false,
  },
  extraReducers: (builder) => {
    builder.addCase(getRenewpolicyDataAction.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getRenewpolicyDataAction.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    });
    builder.addCase(getRenewpolicyDataAction.rejected, (state, action) => {

      state.isError = true;
      state.isLoading = false;
    });
  },
  reducers: {
    resetRenewPolicyDataAction: (state, action) => {
      state.data = undefined
    }
  }
});