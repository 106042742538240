import React from "react";
import edit from "../../Assets/Images/document.svg";
import PopUp from "../Modal/Modal";

const Paper = (props) => {
  const {
    mainTitle,
    heading,
    desc,
    headingTwo,
    descTwo,
    HeadThree,
    descThree,
    HeadFour,
    descFour,
    component,
    componentTwo,
    name,
    classNameDate,
    renderContent,
    handlePolicyTypeSelection,
    PolicyTypeValue,
    value,
    HandleRegDate,
    HandleExpDate,
    carDetails,
    handleInputCarDetails,
    isClaimPrevPolicy,
    businessType,
    MakeList,
    FuelTypeList,
    VarientList,
    ModelListArray,
    ccList,
    setCarDetails,
    carDetailsCopyState,
    handleCopiedDetails,
    setcarDetailsCopyState,
    minDateValue,
    maxDateValue,
    handleMakeSelect,
    handleModelSelect,
    handleFuelSelect,
    handleVariantSelect,
    handleCCSelect,
    handleNcbValue,
    policyTypeList,
    ShowRegDateCalendar,
    setShowRegDateCalendar,
    ShowOdExpDateCalendar,
    setShowOdExpDateCalendar,
    ShowExpDateCalendar,
    setShowExpDateCalendar,
    CalendarDeactiveFlag,
    vehicleType = "4W",
    type,
    setErrorss,
    errorss,
    errorsbike,
    setErrorsBike,
    errorsCar,
    setErrorsCar,
    // setstopApi,
  } = props;

  return (
    <>
      <div className="viewPlan_paper_container">
        <div className="viewPlan_paper_head">
          <p className="viewPlan_paper_head_txt"> {mainTitle} </p>
          <PopUp
            type="submit"
            renderContent={renderContent}
            icons={edit}
            text="Edit"
            className="edit_btn"
            handlePolicyTypeSelection={handlePolicyTypeSelection}
            PolicyTypeValue={PolicyTypeValue}
            value={value}
            event={HandleRegDate}
            HandleExpDate={HandleExpDate}
            name={name}
            carDetails={carDetails}
            isClaimPrevPolicy={isClaimPrevPolicy}
            handleInputCarDetails={handleInputCarDetails}
            MakeList={MakeList}
            FuelTypeList={FuelTypeList}
            ModelListArray={ModelListArray}
            ccList={ccList}
            businessType={businessType}
            CalendarDeactiveFlag={CalendarDeactiveFlag}
            VarientList={VarientList}
            setCarDetails={setCarDetails}
            carDetailsCopyState={carDetailsCopyState}
            handleCopiedDetails={handleCopiedDetails}
            handleMakeSelect={handleMakeSelect}
            handleModelSelect={handleModelSelect}
            handleFuelSelect={handleFuelSelect}
            handleVariantSelect={handleVariantSelect}
            setcarDetailsCopyState={setcarDetailsCopyState}
            handleCCSelect={handleCCSelect}
            minDateValue={minDateValue}
            maxDateValue={maxDateValue}
            handleNcbValue={handleNcbValue}
            policyTypeList={policyTypeList}
            ShowRegDateCalendar={ShowRegDateCalendar}
            setShowRegDateCalendar={setShowRegDateCalendar}
            ShowOdExpDateCalendar={ShowOdExpDateCalendar}
            setShowOdExpDateCalendar={setShowOdExpDateCalendar}
            ShowExpDateCalendar={ShowExpDateCalendar}
            setShowExpDateCalendar={setShowExpDateCalendar}
            vehicleType={vehicleType}
            errorss={errorss}
            setErrorss={setErrorss}
            errorsCar={errorsCar}
            setErrorsCar={setErrorsCar}
            errorsbike={errorsbike}
            setErrorsBike={setErrorsBike}
          // setstopApi={setstopApi}
          />
        </div>
        <div
          className={
            businessType === "New"
              ? "viewPlan_paper_child_container-new"
              : "viewPlan_paper_child_container"
          }
        >
          <div
            className={
              businessType === "New"
                ? "viewPlan_paper_child viewPlan_paper_child-new"
                : "viewPlan_paper_child"
            }
          >
            <div className="viewPlan_paper_child_head_title">
              <p className="viewPlan_paper_child_head"> {heading} </p>
              <p className="viewPlan_paper_child_title"> {desc} </p>
            </div>
            <div className="viewPlan_paper_child_head_title">
              <p className="viewPlan_paper_child_head"> {headingTwo} </p>
              <p className="viewPlan_paper_child_title">{descTwo}</p>
            </div>
          </div>
          <div
            className={
              businessType === "New"
                ? "viewPlan_paper_child viewPlan_paper_child-new"
                : "viewPlan_paper_child"
            }
          >
            <div
              className={
                businessType === "New"
                  ? "viewPlan_paper_child viewPlan_paper_child-new viewPlan_paper_child_padding"
                  : "viewPlan_paper_child viewPlan_paper_child_padding"
              }
            >
              <div className="viewPlan_paper_child_head_title">
                <p className="viewPlan_paper_child_head"> {HeadThree} </p>
                <p
                  className={
                    componentTwo ? classNameDate : "viewPlan_paper_child_title"
                  }
                >
                  {descThree} <label htmlFor="expDate">{componentTwo} </label>{" "}
                </p>
              </div>
              <div className="viewPlan_paper_child_head_title">
                <p className="viewPlan_paper_child_head"> {HeadFour} </p>
                <div className={classNameDate}>
                  <label htmlFor="regDate">{component}</label>
                  {descFour}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Paper;
