import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import digit from "../../Assets/Partners_logo/digit.svg";
import zuno from "../../Assets/Partners_logo/zuno.png";
import shreeRam from "../../Assets/Partners_logo/shriram.png";
import icici from "../../Assets/Partners_logo/icici lombard.svg";
import hdfc from "../../Assets/Partners_logo/BOOKMYINSURANCE/hdfc.jpg";
import sbi from "../../Assets/Partners_logo/SBI.png";
import acko from "../../Assets/Partners_logo/acko.png";
import tata from "../../Assets/Partners_logo/TATA AIG.png";
import Botton from "../Button/Button";
import success from "../../Assets/Images/success.svg";
import fail from "../../Assets/Images/failure.svg";
import PlancardError from "./PlancardError";
import {
  zunoFullQuoteAction,
  zunoQuickQuoteAction,
} from "../../redux/Actions/zunoApiAction";
import { shreeRamQuickQuoteAction } from "../../redux/Actions/shreeRamApiAction";
import { digitQuickQuoteAction } from "../../redux/Actions/digitApiAction";
import { sbiQuickQuoteAction } from "../../redux/Actions/sbiApiAction";
import { Routes, Route, useNavigate, Link } from "react-router-dom";
import PlanDetails from "./PlanDetailsBike";
import {
  SBI,
  RAHEJA,
  SHREERAM,
  ZUNO,
  DIGIT,
  ICICI,
  HDFC,
  ACKO,
  TATA,
  findKeyValue
} from "../../utils/commonUtils";
import { getCompanyName } from "../../redux/Reducers/companyNameReducer";
import ButtonLoader from "../Pages/Loader/Button Loader/ButtonLoader";
import SkeletonLoader from "../Pages/Loader/Skeleton loader/SkeletonLoader";

const Plancard = (props) => {
  const navigate = useNavigate();
  let maxIdv = [];
  let minIdv = [];



  const { planType, openModal, carDetails, triggerVerifyTab, allIDVValues, setShowBottomTab, vehicleAge, CalendarDeactiveFlag } = props;
  console.log(allIDVValues, "allIDVValues");

  let checkboxTrueFalseObject = {
    paCover: false,
    zeroDep: false,
    consumables: false,
    roadAssistance: false,
    engineProtection: false,
    invoiceCover: false,
    keyLockReplacement: false,
    ncbProtection: false,
    driverCover: false,
    passengerCover: false,
    accessories: false,
    tyreProtection: false,
    emiProtection: false,
    lossofPersonalBelongings: false,
    dailyAllowance: false,
    shreeramProtect: false,
  };

  const inspectDetails = useSelector((state) => {
    return state?.inspectDetails?.data?.isInspectionReq
  })


  for (let addon of carDetails?.coverage[0]) {
    if (addon.name in checkboxTrueFalseObject) {
      checkboxTrueFalseObject[addon.name] = addon.checked ? true : false;
    }
  }

  const handleBuyNow = (logo, policyData, companyName) => {
    dispatch(
      getCompanyName({
        companyName: companyName,
      })
    );
    openModal(logo, policyData, companyName);
  };

  let loading = !carDetails?.isPrevPolicyExp ? "Loading..." : "";

  const dispatch = useDispatch();

  const sbiQuickQuote = useSelector((state) => {
    return state?.sbiQuickQuote;
  });
  const sbiQuickQuoteLoading = sbiQuickQuote?.isLoading;

  const getBodyData = useSelector((state) => {
    return state?.bodyData;
  });
  console.log(getBodyData, "getBodyDatagetBodyData");


  let sbiPrice = {
    paCover: false,
    zeroDep: false,
    consumables: false,
    roadAssistance: false,
    engineProtection: false,
    invoiceCover: false,
    keyLockReplacement: false,
    ncbProtection: false,
    driverCover: false,
    passengerCover: false,
    accessories: false,
    totalPremium: false,
    basicPremium: false,
    idv: false,
    duePremium: false,
    tyreProtection: false,
    emiProtection: false,
    lossofPersonalBelongings: false,
    dailyAllowance: false,
    odExternalCngLpg: false,
    odInternalCngLpg: false,
    tpCngLpg: false,
  };

  if (
    sbiQuickQuote?.data?.body?.data?.quote?.PolicyLobList?.length &&
    sbiQuickQuote?.data?.body?.data?.quote?.PolicyLobList[0]?.PolicyRiskList[0]
      ?.PolicyCoverageList?.length
  ) {
    sbiPrice.idv =
      sbiQuickQuote?.data?.body?.data?.quote?.PolicyLobList[0]?.PolicyRiskList[0]?.IDV_User;
    sbiPrice.duePremium =
      sbiQuickQuote?.data?.body?.data?.quote?.BeforeVatPremium;

    if (planType === "Third Party") {
      sbiPrice.basicPremium =
        sbiQuickQuote?.data?.body?.data?.quote?.PolicyLobList[0]?.PolicyRiskList[0]?.TP_BasePremium;
    } else if (planType === "Own Damage") {
      sbiPrice.basicPremium =
        sbiQuickQuote?.data?.body?.data?.quote?.PolicyLobList[0]?.PolicyRiskList[0]?.OD_After_Disc; // od after ncb discount
    } // PolicyLobList[0]?.PolicyRiskList[0]?.OD_After_Disc;
    else {
      sbiPrice.basicPremium =
        sbiQuickQuote?.data?.body?.data?.quote?.PolicyLobList[0]
          ?.PolicyRiskList[0]?.OD_After_Disc +
        sbiQuickQuote?.data?.body?.data?.quote?.PolicyLobList[0]
          ?.PolicyRiskList[0]?.TP_BasePremium; // od after ncb discount + basic tp
    }

    for (
      let index = 0;
      index <
      sbiQuickQuote?.data?.body?.data?.quote?.PolicyLobList[0]
        ?.PolicyRiskList[0]?.PolicyCoverageList?.length;
      index++
    ) {
      const element =
        sbiQuickQuote?.data?.body?.data?.quote?.PolicyLobList[0]
          ?.PolicyRiskList[0]?.PolicyCoverageList?.[index];
      switch (element?.ProductElementCode) {
        // case "C101066":
        //   sbiPrice.paCover = element?.AnnualPremium;
        //   break;
        case "C101072":
          sbiPrice.zeroDep = element?.AnnualPremium;
          break;
        case "C101111":
          sbiPrice.consumables = element?.AnnualPremium;
          break;
        case "C101069":
          sbiPrice.roadAssistance =
            element?.PolicyBenefitList[0]?.AnnualPremium;
          break;
        case "C101108":
          sbiPrice.engineProtection = element?.AnnualPremium;
          break;
        case "C101073":
          sbiPrice.keyLockReplacement = element?.AnnualPremium;
          break;
        case "C101067":
          sbiPrice.invoiceCover = element?.AnnualPremium;
          break;
        case "C101069":
          sbiPrice.roadAssistance = element?.AnnualPremium;
          break;
        case "C101110":
          sbiPrice.tyreProtection = element?.AnnualPremium;
          break;
        case "C101109":
          sbiPrice.emiProtection = element?.AnnualPremium;
          break;
        case "C101075":
          sbiPrice.lossofPersonalBelongings = element?.AnnualPremium;
          break;
        case "C101074":
          sbiPrice.dailyAllowance = element?.AnnualPremium;
          break;
        case "C101068":
          sbiPrice.ncbProtection =
            element?.AnnualPremium === 0 ? false : element?.AnnualPremium;
          break;
        default:
          break;
      }

      for (let index = 0; index < element?.PolicyBenefitList?.length; index++) {
        const coveragePolicy = element?.PolicyBenefitList[index];
        switch (coveragePolicy?.ProductElementCode) {
          case "B00016": // PA - Passenger cover
            sbiPrice.passengerCover = coveragePolicy?.AnnualPremium;
            break;
          case "B00027": // PA - Driver cover
            sbiPrice.driverCover = coveragePolicy?.AnnualPremium;
            break;
          case "B00015": //PA - Owner Driver
            sbiPrice.paCover = coveragePolicy?.GrossPremium;
            break;
          case "B00004": //electrical
            sbiPrice.accessories += coveragePolicy?.AnnualPremium;
            break;
          case "B00003": // accessories
            sbiPrice.accessories += coveragePolicy?.AnnualPremium;
            break;
          case "B00005": // external fitted CNG
            sbiPrice.odExternalCngLpg = coveragePolicy?.AnnualPremium;
            break;
          case "B00006": // internal fitted CNG
            sbiPrice.odInternalCngLpg = coveragePolicy?.AnnualPremium;
            break;
          case "B00010":
            sbiPrice.tpCngLpg = coveragePolicy?.AnnualPremium;
            break;
          default:
            break;
        }
      }
    }
  }

  //shree ram set value
  const shreeRamQuote = useSelector((state) => {
    return state?.shreeRamQuickQuote;
  });

  const shreeRamQuotePrice = shreeRamQuote?.data?.data?.quote?.GetQuotResult;
  const shreeRamQuoteLoading = shreeRamQuote?.isLoading;

  let shreeRamPrice = {
    paCover: false,
    zeroDep: false,
    zeroDepLoad: false,
    consumables: false,
    consumablesLoad: false,
    roadAssistance: false,
    engineProtection: false,
    engineProtectionLaod: false,
    invoiceCover: false,
    keyLockReplacement: false,
    ncbProtection: false,
    driverCover: false,
    passengerCover: false,
    accessories: false,
    electricAccessories: false,
    nonElectricAccessories: false,
    totalPremium: false,
    basicPremium: 0,
    deTarrifAmount: 0,
    ncbDiscount: 0,
    idv: false,
    duePremium: false,
    tyreProtection: false,
    emiProtection: false,
    lossofPersonalBelongings: false,
    dailyAllowance: false,
    odExternalCngLpg: false,
    tpExternalCngLpg: false,
    shreeramProtect: false
  };

  for (
    let index = 0;
    index < shreeRamQuotePrice?.CoverDtlList.length;
    index++
  ) {
    let premium = shreeRamQuotePrice.CoverDtlList[index].Premium;
    let switchCaseMatchForAllYear = shreeRamQuotePrice.CoverDtlList[
      index
    ].CoverDesc.replace(/ - (1|2|3|4) Year/g, "");
    switch (switchCaseMatchForAllYear) {
      case "Total Premium":
        shreeRamPrice.totalPremium += Number(premium);
        break;
      case "Depreciation Deduction Waiver (Nil Depreciation) - OD":
        shreeRamPrice.zeroDep += Number(premium);
        break;
      case "Nil Depreciation Loading - OD":
        shreeRamPrice.zeroDepLoad += Number(premium);
        break;
      case "Engine Protector - OD":
        shreeRamPrice.engineProtection += Number(premium);
        break;
      case "Engine Protector Loading - OD":
        shreeRamPrice.engineProtectionLaod += Number(premium);
        break;
      case "Consumable - OD":
        shreeRamPrice.consumables += Number(premium);
        break;
      case "Consumable Loading - OD":
        shreeRamPrice.consumablesLoad += Number(premium);
        break;
      case "Key Replacement - OD":
        shreeRamPrice.keyLockReplacement += Number(premium);
        break;
      case "Road Side Assistance - OD":
        shreeRamPrice.roadAssistance += Number(premium);
        break;
      case "GR42-Outbuilt CNG/LPG-Kit-Cover - OD":
        shreeRamPrice.odExternalCngLpg += Number(premium);
        break;
      case "GR36B2-PA Cover For Passengers (Un-Named Persons) - TP":
        shreeRamPrice.passengerCover += Number(premium);
        break;
      case "GR36A-PA FOR OWNER DRIVER - TP":
        shreeRamPrice.paCover += Number(premium);
        break;
      case "Legal Liability Coverages For Paid Driver - TP":
        shreeRamPrice.driverCover += Number(premium);
        break;
      case "GR42-Outbuilt CNG/LPG-Kit-Cover - TP":
        shreeRamPrice.tpExternalCngLpg += Number(premium);
        break;
      case "GR41-Cover For Electrical and Electronic Accessories - OD":
        shreeRamPrice.accessories += Number(premium);
        shreeRamPrice.electricAccessories += Number(premium);
        break;
      case "Cover For Non Electrical Accessories - OD":
        shreeRamPrice.accessories += Number(premium);
        shreeRamPrice.nonElectricAccessories += Number(premium);
        break;
      case "Return to Invoice - OD":
        shreeRamPrice.invoiceCover += Number(premium);
        break;
      case "Personal Belonging - OD":
        shreeRamPrice.lossofPersonalBelongings += Number(premium);
        break;
      case "Personal Belonging - OD":
        shreeRamPrice.lossofPersonalBelongings += Number(premium);
        break;
      case "Basic Premium - OD":
        shreeRamPrice.basicPremium += Number(premium);
        break;
      case "Basic Premium - TP":
        shreeRamPrice.basicPremium += Number(premium);
        break;
      case "De-Tariff Discount - OD":
        shreeRamPrice.deTarrifAmount += Number(premium);
        break;
      case "NCB Discount  - OD":
        shreeRamPrice.ncbDiscount += Number(premium);
        break;
      case "Motor Protection - OD":
        shreeRamPrice.shreeramProtect += Number(premium);
        break;
      default:
        // Handle other cases or leave empty if not needed
        break;
    }
  }

  shreeRamPrice.basicPremium =
    (shreeRamPrice.basicPremium + shreeRamPrice.accessories) -
    shreeRamPrice.deTarrifAmount -
    shreeRamPrice.ncbDiscount;

  // digit
  const digitQuote = useSelector((state) => {
    return state?.digitQuickQuote;
  });

  const digitQuotePrice = digitQuote?.data?.data?.quote?.["Motor-Quick Quote"];
  const digitQuoteLoading = digitQuote?.isLoading;
  const digitContractCovereage = digitQuotePrice?.contract?.coverages;
  const digitIDV = digitQuotePrice?.vehicle?.vehicleIDV;

  const digitPrice = {
    paCover:
      digitContractCovereage?.["personalAccident"]?.["selection"] &&
        digitContractCovereage?.["personalAccident"]?.["coverAvailability"] !==
        "NOT_AVAILABLE"
        ? digitContractCovereage?.["personalAccident"]?.["netPremium"]
        : false,
    zeroDep:
      digitContractCovereage?.["addons"]?.["partsDepreciation"]?.[
        "selection"
      ] &&
        digitContractCovereage?.["addons"]?.["partsDepreciation"]?.[
        "coverAvailability"
        ] !== "NOT_AVAILABLE"
        ? digitContractCovereage?.["addons"]?.["partsDepreciation"]?.[
        "netPremium"
        ]
        : false,
    consumables:
      digitContractCovereage?.["addons"]?.["consumables"]?.["selection"] &&
        digitContractCovereage?.["addons"]?.["consumables"]?.[
        "coverAvailability"
        ] !== "NOT_AVAILABLE"
        ? digitContractCovereage?.["addons"]?.["consumables"]?.["netPremium"]
        : false,
    roadAssistance:
      digitContractCovereage?.["addons"]?.["roadSideAssistance"]?.[
        "selection"
      ] &&
        digitContractCovereage?.["addons"]?.["roadSideAssistance"]?.[
        "coverAvailability"
        ] !== "NOT_AVAILABLE"
        ? digitContractCovereage?.["addons"]?.["roadSideAssistance"]?.[
        "netPremium"
        ]
        : false,
    engineProtection:
      digitContractCovereage?.["addons"]?.["engineProtection"]?.["selection"] &&
        digitContractCovereage?.["addons"]?.["engineProtection"]?.[
        "coverAvailability"
        ] !== "NOT_AVAILABLE"
        ? digitContractCovereage?.["addons"]?.["engineProtection"]?.[
        "netPremium"
        ]
        : false,
    invoiceCover:
      digitContractCovereage?.["addons"]?.["returnToInvoice"]?.["selection"] &&
        digitContractCovereage?.["addons"]?.["returnToInvoice"]?.[
        "coverAvailability"
        ] !== "NOT_AVAILABLE"
        ? digitContractCovereage?.["addons"]?.["returnToInvoice"]?.[
        "netPremium"
        ]
        : false,
    keyLockReplacement:
      digitContractCovereage?.["addons"]?.["keyAndLockProtect"]?.[
        "selection"
      ] &&
        digitContractCovereage?.["addons"]?.["keyAndLockProtect"]?.[
        "coverAvailability"
        ] !== "NOT_AVAILABLE"
        ? digitContractCovereage?.["addons"]?.["keyAndLockProtect"]?.[
        "netPremium"
        ]
        : false,
    driverCover: digitContractCovereage?.["unnamedPA"]?.["unnamedPaidDriver"]?.[
      "selection"
    ]
      ? digitContractCovereage?.["unnamedPA"]?.["unnamedPaidDriver"]?.[
      "netPremium"
      ]
      : false,
    passengerCover: digitContractCovereage?.["unnamedPA"]?.["unnamedPax"]?.[
      "selection"
    ]
      ? digitContractCovereage?.["unnamedPA"]?.["unnamedPax"]?.["netPremium"]
      : false,
    accessories:
      digitContractCovereage?.["accessories"]?.["cng"]?.["selection"] ||
        digitContractCovereage?.["accessories"]?.["electrical"]?.["selection"] ||
        digitContractCovereage?.["accessories"]?.["nonElectrical"]?.["selection"]
        ? true
        : false,
    tyreProtection:
      digitContractCovereage?.["addons"]?.["tyreProtection"]?.["selection"] &&
        digitContractCovereage?.["addons"]?.["tyreProtection"]?.[
        "coverAvailability"
        ] !== "NOT_AVAILABLE"
        ? digitContractCovereage?.["addons"]?.["tyreProtection"]?.["netPremium"]
        : false,
    rimProtection:
      digitContractCovereage?.["addons"]?.["rimProtection"]?.["selection"] &&
        digitContractCovereage?.["addons"]?.["rimProtection"]?.[
        "coverAvailability"
        ] !== "NOT_AVAILABLE"
        ? digitContractCovereage?.["addons"]?.["rimProtection"]?.["netPremium"]
        : false,
    personalBelonging:
      digitContractCovereage?.["addons"]?.["personalBelonging"]?.[
        "selection"
      ] &&
        digitContractCovereage?.["addons"]?.["personalBelonging"]?.[
        "coverAvailability"
        ] !== "NOT_AVAILABLE"
        ? digitContractCovereage?.["addons"]?.["personalBelonging"]?.[
        "netPremium"
        ]
        : false,
    emiProtection: false,
    dailyAllowance: false,
    ncbProtection: false,
    totalPremium: digitQuotePrice?.grossPremium?.slice(4),
    duePremium: digitQuotePrice?.netPremium?.slice(4),
    //basicPremium: digitQuotePrice?.netPremium
  };

  let OD_BasicPremium =
    digitContractCovereage?.thirdPartyLiability?.netPremium?.slice(4);
  let TP_BasicPremium = digitContractCovereage?.ownDamage?.netPremium?.slice(4);
  if (planType === "Third Party") {
    digitPrice.basicPremium = Math.round(OD_BasicPremium ? Number(OD_BasicPremium) : 0);
  } else if (planType === "Own Damage") {
    digitPrice.basicPremium = Math.round(TP_BasicPremium ? (Number(TP_BasicPremium) - ((digitContractCovereage?.ownDamage?.ncbDiscountWithZeroDepWithPreInspection ? (Number(digitContractCovereage?.ownDamage?.ncbDiscountWithZeroDepWithPreInspection.slice(4))) : 0))) : 0); // od after ncb discount
  } else {
    digitPrice.basicPremium =
      Math.round((OD_BasicPremium ? Number(OD_BasicPremium) : 0) +
        (TP_BasicPremium ? Number(TP_BasicPremium) : 0) -
        (digitContractCovereage?.ownDamage?.ncbDiscountWithZeroDep ? (Number(digitContractCovereage?.ownDamage?.ncbDiscountWithZeroDep.slice(4))) : 0));
  }

  const iciciQuote = useSelector((state) => {
    return state?.iciciQuickQuote;
  });
  const iciciQuotePrice = iciciQuote?.data?.data?.quote;
  const iciciQuoteLoading = iciciQuote?.isLoading;
  //const digitContractCovereage = digitQuotePrice?.contract?.coverages;
  const iciciIDV = iciciQuotePrice?.generalInformation?.depriciatedIDV;

  const iciciPrice = {
    paCover: iciciQuotePrice?.riskDetails?.paCoverForOwnerDriver
      ? iciciQuotePrice?.riskDetails?.paCoverForOwnerDriver
      : false,
    zeroDep: iciciQuotePrice?.riskDetails?.zeroDepreciation
      ? iciciQuotePrice?.riskDetails?.zeroDepreciation
      : false,
    consumables: iciciQuotePrice?.riskDetails?.consumables
      ? iciciQuotePrice?.riskDetails?.consumables
      : false,
    roadAssistance: iciciQuotePrice?.riskDetails?.roadSideAssistance
      ? iciciQuotePrice?.riskDetails?.roadSideAssistance
      : false,
    engineProtection: iciciQuotePrice?.riskDetails?.engineProtect
      ? iciciQuotePrice?.riskDetails?.engineProtect
      : false,
    invoiceCover: iciciQuotePrice?.riskDetails?.returnToInvoice
      ? iciciQuotePrice?.riskDetails?.returnToInvoice
      : false,
    keyLockReplacement: iciciQuotePrice?.riskDetails?.keyProtect
      ? iciciQuotePrice?.riskDetails?.keyProtect
      : false,
    driverCover: iciciQuotePrice?.riskDetails?.paidDriver
      ? iciciQuotePrice?.riskDetails?.paidDriver
      : false,
    passengerCover: iciciQuotePrice?.riskDetails?.paCoverForUnNamedPassenger
      ? iciciQuotePrice?.riskDetails?.paCoverForUnNamedPassenger
      : false,
    accessories:
      iciciQuotePrice?.riskDetails?.electricalAccessories ||
        iciciQuotePrice?.riskDetails?.nonElectricalAccessories
        ? true
        : false,
    tyreProtection: iciciQuotePrice?.riskDetails?.tyreProtect
      ? iciciQuotePrice?.riskDetails?.tyreProtect
      : false,
    rimProtection: false,
    personalBelonging: iciciQuotePrice?.riskDetails?.lossOfPersonalBelongings
      ? iciciQuotePrice?.riskDetails?.lossOfPersonalBelongings
      : false,
    emiProtection: false,
    dailyAllowance: false,
    ncbProtection: false,
    totalPremium: iciciQuotePrice?.finalPremium,
    duePremium: iciciQuotePrice?.packagePremium
      ? iciciQuotePrice?.packagePremium
      : iciciQuotePrice?.totalLiabilityPremium,
  };

  //adding od and tp basic price
  iciciPrice.basicPremium =
    (iciciQuotePrice?.riskDetails?.basicOD
      ? iciciQuotePrice?.riskDetails?.basicOD
      : 0) +
    (iciciQuotePrice?.riskDetails?.electricalAccessories
      ? iciciQuotePrice?.riskDetails?.electricalAccessories
      : 0) +
    (iciciQuotePrice?.riskDetails?.nonElectricalAccessories
      ? iciciQuotePrice?.riskDetails?.nonElectricalAccessories
      : 0) +
    iciciQuotePrice?.riskDetails?.basicTP;

  let loadingAmt = iciciQuotePrice?.riskDetails?.breakinLoadingAmount ? iciciQuotePrice?.riskDetails?.breakinLoadingAmount : 0;
  let ncbDiscount = iciciQuotePrice?.riskDetails?.bonusDiscount ? iciciQuotePrice?.riskDetails?.bonusDiscount : 0;
  //minus ncb
  iciciPrice.basicPremium = (iciciPrice.basicPremium + loadingAmt) - ncbDiscount;
  iciciPrice.basicPremium = iciciPrice.basicPremium ? iciciPrice.basicPremium : "NA";
  iciciPrice.duePremium = iciciPrice.duePremium ? iciciPrice.duePremium : 0;

  // hdfcprice

  let totalAddonsPrice = 0
  const hdfcQuote = useSelector((state) => {
    return state?.hdfcQuickQuote;
  });
  console.log("HDFC Quote", hdfcQuote);

  const hdfcQuotePrice = hdfcQuote?.data?.data?.quote?.Resp_TW;
  const hdfcQuoteLoading = hdfcQuote?.isLoading;
  const hdfcIDV = hdfcQuote?.data?.data?.quote?.Resp_TW?.IDV;
  const hdfcPrice = {

    paCover: hdfcQuotePrice?.PAOwnerDriver_Premium
      ? hdfcQuotePrice?.PAOwnerDriver_Premium
      : false,
    zeroDep: hdfcQuotePrice?.Vehicle_Base_ZD_Premium
      ? hdfcQuotePrice?.Vehicle_Base_ZD_Premium
      : false,
    consumables: hdfcQuotePrice?.Vehicle_Base_COC_Premium
      ? hdfcQuotePrice?.Vehicle_Base_COC_Premium
      : false,
    roadAssistance: hdfcQuotePrice?.EA_premium
      ? hdfcQuotePrice?.EA_premium
      : false,
    engineProtection: hdfcQuotePrice?.Vehicle_Base_ENG_Premium
      ? hdfcQuotePrice?.Vehicle_Base_ENG_Premium
      : false,
    invoiceCover: hdfcQuotePrice?.Vehicle_Base_RTI_Premium
      ? hdfcQuotePrice?.Vehicle_Base_RTI_Premium
      : false,
    keyLockReplacement: false,
    driverCover: hdfcQuotePrice?.PAPaidDriver_Premium
      ? hdfcQuotePrice?.PAPaidDriver_Premium
      : false,
    passengerCover: hdfcQuotePrice?.UnnamedPerson_premium
      ? hdfcQuotePrice?.UnnamedPerson_premium
      : false,
    accessories: Number(hdfcQuotePrice?.Electical_Acc_Premium) + Number(hdfcQuotePrice?.NonElectical_Acc_Premium) > 0
      ? Number(hdfcQuotePrice?.Electical_Acc_Premium) + Number(hdfcQuotePrice?.NonElectical_Acc_Premium)
      : false,
    tyreProtection: hdfcQuotePrice?.Vehicle_Base_TySec_Premium
      ? hdfcQuotePrice?.Vehicle_Base_TySec_Premium
      : false,
    rimProtection: false,
    personalBelonging: hdfcQuotePrice?.LossOfPersonalBelongings_Premium
      ? hdfcQuotePrice?.LossOfPersonalBelongings_Premium
      : false,
    emiProtection: false,
    dailyAllowance: false,
    ncbProtection: hdfcQuotePrice?.Vehicle_Base_NCB_Premium
      ? hdfcQuotePrice?.Vehicle_Base_NCB_Premium
      : false,
    totalPremium: hdfcQuotePrice?.Total_Premium,
    duePremium: hdfcQuotePrice?.Net_Premium

  };
  totalAddonsPrice += Number(hdfcPrice?.paCover) + Number(hdfcPrice?.paCover) + Number(hdfcPrice?.consumables) + Number(hdfcPrice?.roadAssistance) + Number(hdfcPrice?.emiProtection) + Number(hdfcPrice?.invoiceCover) + Number(hdfcPrice?.ncbProtection);
  //adding od and tp basic price




  let ncbDiscountHdfc = hdfcQuotePrice?.NCBBonusDisc_Premium ? hdfcQuotePrice?.NCBBonusDisc_Premium : 0;
  hdfcPrice.basicPremium =
    hdfcQuotePrice?.Basic_OD_Premium +
    hdfcQuotePrice?.Basic_TP_Premium - Number(ncbDiscountHdfc)
  //minus ncb
  // hdfcPrice.basicPremium = hdfcPrice.basicPremium - ncbDiscountHdfc;
  hdfcPrice.duePremium = hdfcPrice.duePremium ? hdfcPrice.duePremium : 0;
  hdfcPrice.odExternalCngLpg = hdfcQuotePrice?.BiFuel_Kit_OD_Premium;
  hdfcPrice.tpCngLpg = hdfcQuotePrice?.BiFuel_Kit_TP_Premium;

  //Handle Acko Quote 
  const ackoQuote = useSelector((state) => {
    return state?.ackoQuickQuote;
  });

  const ackoQuotePrice = ackoQuote?.data?.data?.quote?.insured[0];
  const ackoQuoteLoading = ackoQuote?.isLoading;
  const ackoIDV = ackoQuotePrice?.idv?.values[0].idv_amount;
  const ackoPrice = {}
  let ackoPlanId = ''
  if (planType === "Comprehensive") {
    ackoPlanId = 'bike_comprehensive'
  } else if (planType === "Bundled") {
    ackoPlanId = 'bike_bundled'
  } else if (planType === "Third Party") {
    ackoPlanId = 'bike_tp'
  } else if (planType === "Own Damage") {
    ackoPlanId = 'bike_od'
  }
  let ackoPlan = {};

  if (ackoQuotePrice?.plans) {
    ackoPlan = ackoQuotePrice?.plans.find(item => {
      return item.id == ackoPlanId
    })
  }

  function getAddonPrice(addonData, plan_id) {
    if (addonData) {
      let addOnprice = addonData.find(item => {
        return item.id == plan_id
      })
      return addOnprice?.price?.net_premium ? addOnprice?.price?.net_premium : 0
    } else {
      return 0
    }
  }

  ackoPrice.basicPremium = ackoPlan?.total_price?.net_premium
  ackoPrice.paCover = checkboxTrueFalseObject.paCover ? getAddonPrice(ackoPlan?.addons, "pa_owner_two_wheeler") : false;
  ackoPrice.zeroDep = checkboxTrueFalseObject.zeroDep ? getAddonPrice(ackoPlan?.addons, "zero_depreciation_two_wheeler") : false;
  ackoPrice.personalBelonging = checkboxTrueFalseObject.lossofPersonalBelongings ? getAddonPrice(ackoPlan?.addons, "personal_belongings_damage_two_wheeler") : false;
  ackoPrice.duePremium = ackoPlan?.total_price?.net_premium + ackoPrice.paCover +
    ackoPrice.zeroDep + ackoPrice.personalBelonging;

  // **************Zuno Quick quote *************//

  // get response from store
  const zunoQuickQuote = useSelector((state) => {
    return state?.zunoQuickQuote;
  });

  // set zuno response to variable
  const {
    premiumDetails,
    policyLevelDetails,
    contractDetails,
    commisionDetails,
  } = zunoQuickQuote?.data?.data?.quote || {};

  // zuno quick quote loading
  const zunoQuickQuoteLoading = zunoQuickQuote?.isLoading;
  const zunoIDV = findKeyValue(contractDetails, "systemIdv")

  //  Calculate their value
  let zunoElectricAccessories = 0,
    zunononElectricAccessories = 0,
    zunoPassengerCover = 0,
    zunoDriverCover = 0,
    zunoExtrnalCng = 0;
  // for electricalZuno, nonElectricalZuno, ncbDiscountZuno, odExternalCngLpg
  if (premiumDetails?.totalODPremium > 0 && premiumDetails?.totalTPPremium > 0) {
    if ((contractDetails[0]?.coverage?.subCoverage)?.length > 0) {
      for (let item of contractDetails[0]?.coverage?.subCoverage) {
        if (item.salesProductTemplateId == "MOSCMF02") {
          zunoElectricAccessories = item.grossPremium;
        } else if (item.salesProductTemplateId == "MOSCMF01") {
          zunononElectricAccessories = item.grossPremium;
        } else if (item.salesProductTemplateId == "MOSCMF03") {
          zunoExtrnalCng = item.grossPremium
        }
      }
    }
  } else if (premiumDetails?.totalODPremium > 0 && premiumDetails?.totalTPPremium == 0) {
    if ((contractDetails[0]?.coverage?.subCoverage)?.length > 0) {
      for (let item of contractDetails[0]?.coverage?.subCoverage) {
        if (item.salesProductTemplateId == "MOSCMF02") {
          zunoElectricAccessories = item.grossPremium;
        } else if (item.salesProductTemplateId == "MOSCMF01") {
          zunononElectricAccessories = item.grossPremium;
        } else if (item.salesProductTemplateId == "MOSCMF03") {
          zunoExtrnalCng = item.grossPremium
        }
      }
    }
  } else if (premiumDetails?.totalTPPremium == 0) {
    if (Array.isArray(contractDetails?.coverage?.subCoverage)) {
      for (let item of contractDetails?.coverage?.subCoverage) {
        if (item.salesProductTemplateId == "MOSCMF02") {
          zunoElectricAccessories = item.grossPremium;
        } else if (item.salesProductTemplateId == "MOSCMF01") {
          zunononElectricAccessories = item.grossPremium;
        } else if (item.salesProductTemplateId == "MOSCMF03") {
          zunoExtrnalCng = item.grossPremium
        }
      }
    }
  }

  // for passengerZuno, driverZuno , tpCngLpgZuno
  let contractDetailsLength = Array.isArray(contractDetails) ? contractDetails?.length - 1 : 0;
  if (premiumDetails?.totalODPremium > 0 && contractDetailsLength > 0) {
    if ((contractDetails[contractDetailsLength]?.coverage?.subCoverage)?.length > 0) {
      for (let item of contractDetails[contractDetailsLength]?.coverage
        ?.subCoverage) {
        if (item.salesProductTemplateId == "MOSCMF24") {
          zunoPassengerCover = item.totalPremium;
        } else if (item.salesProductTemplateId == "MOSCMF20") {
          zunoDriverCover = item.totalPremium;
        } else if (item.salesProductTemplateId == "MOSCMF17") {
          zunoExtrnalCng = item.totalPremium;
        }
      }
    }
  } else if (premiumDetails?.totalODPremium == 0 && contractDetailsLength == 0) {
    if ((contractDetails?.coverage?.subCoverage)?.length > 0) {
      for (let item of contractDetails?.coverage?.subCoverage) {
        if (item.salesProductTemplateId == "MOSCMF24") {
          zunoPassengerCover = item.totalPremium;
        } else if (item.salesProductTemplateId == "MOSCMF20") {
          zunoDriverCover = item.totalPremium;
        } else if (item.salesProductTemplateId == "MOSCMF17") {
          zunoExtrnalCng = item.totalPremium;
        }
      }
    }
  } else if (premiumDetails?.totalODPremium == 0 && contractDetailsLength > 0) {
    if ((contractDetails[contractDetailsLength]?.coverage?.subCoverage)?.length > 0) {
      for (let item of contractDetails[contractDetailsLength]?.coverage
        ?.subCoverage) {
        if (item.salesProductTemplateId == "MOSCMF24") {
          zunoPassengerCover = item.totalPremium;
        } else if (item.salesProductTemplateId == "MOSCMF20") {
          zunoDriverCover = item.totalPremium;
        } else if (item.salesProductTemplateId == "MOSCMF17") {
          zunoExtrnalCng = item.totalPremium;
        }
      }
    }
  }

  // Zuno price
  const zunoPrice = {
    paCover: (premiumDetails?.totalPApremium > 0)
      ? premiumDetails?.totalPApremium
      : false,
    zeroDep: (premiumDetails?.individualAddOnPremium?.zeroDepreciation > 0)
      ? premiumDetails?.individualAddOnPremium?.zeroDepreciation
      : false,
    consumables: (premiumDetails?.individualAddOnPremium?.consumableCover > 0)
      ? premiumDetails?.individualAddOnPremium?.consumableCover
      : false,
    roadAssistance: (premiumDetails?.individualAddOnPremium?.basicRoadAssistance > 0)
      ? premiumDetails?.individualAddOnPremium?.basicRoadAssistance
      : false,
    engineProtection: (premiumDetails?.individualAddOnPremium?.engineProtect > 0)
      ? premiumDetails?.individualAddOnPremium?.engineProtect
      : false,
    invoiceCover: (premiumDetails?.individualAddOnPremium?.invoiceValueProtect > 0)
      ? premiumDetails?.individualAddOnPremium?.invoiceValueProtect
      : false,
    keyLockReplacement: (premiumDetails?.individualAddOnPremium?.keyReplacement > 0)
      ? premiumDetails?.individualAddOnPremium?.keyReplacement
      : false,
    odExternalCngLpg: Number(zunoExtrnalCng > 0) ? zunoExtrnalCng : false,
    driverCover: Number(zunoDriverCover) > 0 ? zunoDriverCover : false,
    passengerCover: Number(zunoPassengerCover) > 0 ? zunoPassengerCover : false,
    accessories:
      Number(zunoElectricAccessories) + Number(zunononElectricAccessories) > 0
        ? Number(zunoElectricAccessories) + Number(zunononElectricAccessories)
        : false,
    tyreProtection: (premiumDetails?.individualAddOnPremium?.tyreProtect > 0)
      ? premiumDetails?.individualAddOnPremium?.tyreProtect
      : false,
    rimProtection: false,
    lossofPersonalBelongings: (premiumDetails?.individualAddOnPremium?.lossOfPersonalBelongings > 0)
      ? premiumDetails?.individualAddOnPremium?.lossOfPersonalBelongings
      : false,
    emiProtection: false,
    dailyAllowance: false,
    ncbProtection: (premiumDetails?.individualAddOnPremium?.protectionOfNCB > 0)
      ? premiumDetails?.individualAddOnPremium?.protectionOfNCB
      : false,
    totalPremium: premiumDetails?.grossTotalPremium,
    duePremium: premiumDetails?.netTotalPremium,
    basicPremium:
      premiumDetails?.totalODPremium +
      premiumDetails?.totalTPPremium -
      Number(zunoElectricAccessories) -
      Number(zunononElectricAccessories) -
      Number(zunoDriverCover) -
      Number(zunoPassengerCover),
  };

  // **************tata Quick quote ************* //

  const tataQuote = useSelector((state) => {
    return state?.tataQuickQuote;
  });

  // set zuno response to variable
  const tataPremiumedetails = tataQuote?.data?.data?.quote?.data?.premium_break_up ? tataQuote?.data?.data?.quote?.data?.premium_break_up : ""
  const tataQuoteLoading = tataQuote?.isLoading;
  const tataIDV = tataQuote?.data?.data?.quote?.data?.data?.vehicle_idv ? tataQuote?.data?.data?.quote?.data?.data?.vehicle_idv : "";
  console.log(tataPremiumedetails, 'tataPremiumedetails');

  // tata price
  const tataPrice = {}
  let addonsPrice = 0;

  tataPrice.basicPremium = Number(tataPremiumedetails?.total_od_premium?.total_od) + Number(tataPremiumedetails?.total_tp_premium?.basic_tp_prem)
  tataPrice.paCover = tataPremiumedetails?.total_tp_premium?.pa_cover_prem > 0 ? tataPremiumedetails?.total_tp_premium?.pa_cover_prem : false
  tataPrice.zeroDep = tataPremiumedetails?.total_addOns?.dep_reimburse_prem > 0 ? tataPremiumedetails?.total_addOns?.dep_reimburse_prem : false
  if (checkboxTrueFalseObject.zeroDep) {
    addonsPrice += Number(tataPrice.zeroDep)
  }
  if (checkboxTrueFalseObject.paCover) {
    addonsPrice += tataPrice.paCover
  }
  tataPrice.duePremium = parseInt(tataPrice.basicPremium) + addonsPrice

  // set company quote response in this array of object:

  let quotesContent = [
    // Acko
    {
      id: 1,
      companyName: ACKO,
      companyImg: acko,
      // isInspection: inspectDetails && ackoisPiRequired,
      // showInspectionTab: planType !== "Bundled" && planType !== "Third Party",
      loadingSate: ackoQuoteLoading || ackoPrice.duePremium === undefined || ackoPrice.duePremium === 0 || isNaN(ackoPrice.duePremium),
      idv: ackoIDV?.toLocaleString('en-IN'),
      premiumValue: isNaN(ackoPrice.duePremium) ? 0 : ackoPrice.duePremium?.toLocaleString('en-IN'),
      basicPremiumValue: Math.round(ackoPrice?.basicPremium)?.toLocaleString('en-IN'),
      paCoverValue: Math.round(ackoPrice.paCover)?.toLocaleString('en-IN'),
      zeroDepValue: Math.round(ackoPrice?.zeroDep)?.toLocaleString('en-IN'),
      shreeramProtctValue: 0,
      engProtectValue: ackoPrice?.engineProtection,
      consumableValue: ackoPrice?.consumables,
      isKeyLockValue: ackoPrice?.keyLockReplacement || ackoPrice?.roadAssistance,
      invoiceCoverValue: ackoPrice?.invoiceCover,
      roadAssitanceValue: ackoPrice?.keyLockReplacement || ackoPrice?.roadAssistance,
      tyreProtectionValue: ackoPrice?.tyreProtection,
      personalBelongingValue: ackoPrice?.personalBelonging,
      dailyAllowanceValue: ackoPrice.dailyAllowance,
      passengerCoverValue: ackoPrice.passengerCover,
      ncbProtectionValue: ackoPrice?.ncbProtection,
      accessoriesValue: ackoPrice?.accessories,
      driverCoverValue: ackoPrice.driverCover,
      showPlanDetailsTab: !ackoQuoteLoading && ackoPrice.duePremium && !isNaN(ackoPrice.duePremium),
      quickQuote: ackoQuote,
      handleBuyNow: (e) => handleBuyNow(acko, "", ACKO),
      vehicleBreakupPrice: ackoPrice,
    },
    // TATA
    {
      id: 2,
      companyName: TATA,
      companyImg: tata,
      // isInspection: inspectDetails && ackoisPiRequired,
      // showInspectionTab: planType !== "Bundled" && planType !== "Third Party",
      loadingSate: tataQuoteLoading || tataPrice.duePremium === undefined || tataPrice.duePremium === 0 || isNaN(tataPrice.duePremium),
      idv: tataIDV?.toLocaleString('en-IN'),
      premiumValue: isNaN(tataPrice.duePremium) ? 0 : parseInt(tataPrice.duePremium)?.toLocaleString('en-IN'),
      basicPremiumValue: Math.round(tataPrice?.basicPremium)?.toLocaleString('en-IN'),
      paCoverValue: Math.round(tataPrice.paCover)?.toLocaleString('en-IN') || 0,
      zeroDepValue: Math.round(tataPrice?.zeroDep)?.toLocaleString('en-IN') || 0,
      shreeramProtctValue: 0,
      engProtectValue: tataPrice?.engineProtection,
      consumableValue: tataPrice?.consumables,
      isKeyLockValue: tataPrice?.keyLockReplacement,
      invoiceCoverValue: tataPrice?.invoiceCover,
      roadAssitanceValue: tataPrice?.roadAssistance,
      tyreProtectionValue: tataPrice?.tyreProtection,
      personalBelongingValue: tataPrice?.personalBelonging,
      dailyAllowanceValue: tataPrice.dailyAllowance,
      passengerCoverValue: tataPrice.passengerCover,
      ncbProtectionValue: tataPrice?.ncbProtection,
      accessoriesValue: tataPrice?.accessories,
      driverCoverValue: tataPrice.driverCover,
      showPlanDetailsTab: !tataQuoteLoading && tataPrice.duePremium && !isNaN(tataPrice.duePremium),
      quickQuote: tataQuote,
      handleBuyNow: (e) => handleBuyNow(tata, "", TATA),
      vehicleBreakupPrice: tataPrice,
    },
    // // Sbi
    // {
    //   id: 2,
    //   companyName: SBI,
    //   companyImg: sbi,
    //   isInspection: inspectDetails,
    //   showInspectionTab: planType !== "Bundled" && planType !== "Third Party",
    //   loadingSate: sbiQuickQuoteLoading || sbiPrice.duePremium === undefined || sbiPrice.duePremium == 0 || ShowSkeleton,
    //   idv: Math.round(sbiPrice.idv)?.toLocaleString('en-IN'),
    //   premiumValue: Math.round(sbiPrice.duePremium)?.toLocaleString('en-IN'),
    //   basicPremiumValue: Math.round(sbiPrice.basicPremium)?.toLocaleString('en-IN'),
    //   paCoverValue: Math.round(sbiPrice?.paCover)?.toLocaleString('en-IN'),
    //   isExternalFittedCng: sbiPrice?.odExternalCngLpg,
    //   zeroDepValue: Math.round(sbiPrice?.zeroDep)?.toLocaleString('en-IN'),
    //   shreeramProtctValue: 0,
    //   engProtectValue: sbiPrice?.engineProtection,
    //   consumableValue: sbiPrice?.consumables,
    //   isKeyLockValue: sbiPrice?.keyLockReplacement,
    //   invoiceCoverValue: sbiPrice?.invoiceCover,
    //   roadAssitanceValue: sbiPrice?.roadAssistance,
    //   tyreProtectionValue: sbiPrice?.tyreProtection,
    //   personalBelongingValue: sbiPrice.lossofPersonalBelongings,
    //   dailyAllowanceValue: sbiPrice.dailyAllowance,
    //   passengerCoverValue: sbiPrice.passengerCover,
    //   ncbProtectionValue: sbiPrice.ncbProtection,
    //   accessoriesValue: sbiPrice.accessories,
    //   driverCoverValue: sbiPrice.driverCover,
    //   showPlanDetailsTab: !sbiQuickQuoteLoading && sbiPrice.duePremium && !ShowSkeleton,
    //   quickQuote: sbiQuickQuote,
    //   handleBuyNow: (e) => handleBuyNow(sbi, sbiQuickQuote, SBI),
    //   vehicleBreakupPrice: sbiPrice,
    // },
    // Icici
    {
      id: 3,
      companyName: ICICI,
      companyImg: icici,
      // isInspection: inspectDetails,
      // showInspectionTab: planType !== "Bundled" && planType !== "Third Party",
      loadingSate: iciciQuoteLoading || iciciPrice.duePremium === undefined || iciciPrice.duePremium === 0,
      idv: Math.round(iciciIDV)?.toLocaleString('en-IN'),
      premiumValue: Math.round(iciciPrice.duePremium)?.toLocaleString('en-IN'),
      basicPremiumValue: Math.round(iciciPrice?.basicPremium)?.toLocaleString('en-IN') || 0,
      paCoverValue: Math.round(iciciPrice?.paCover)?.toLocaleString('en-IN') || 0,
      isExternalFittedCng: iciciPrice?.odExternalCngLpg,
      zeroDepValue: Math.round(iciciPrice?.zeroDep)?.toLocaleString('en-IN') || 0,
      shreeramProtctValue: 0,
      engProtectValue: iciciPrice?.engineProtection,
      consumableValue: iciciPrice?.consumables,
      isKeyLockValue: iciciPrice?.keyLockReplacement,
      invoiceCoverValue: iciciPrice?.invoiceCover,
      roadAssitanceValue: iciciPrice?.roadAssistance,
      tyreProtectionValue: iciciPrice?.tyreProtection,
      personalBelongingValue: iciciPrice?.personalBelonging,
      dailyAllowanceValue: iciciPrice.dailyAllowance,
      passengerCoverValue: iciciPrice.passengerCover,
      ncbProtectionValue: iciciPrice?.ncbProtection,
      accessoriesValue: iciciPrice?.accessories,
      driverCoverValue: iciciPrice?.driverCover,
      showPlanDetailsTab: !iciciQuoteLoading && iciciPrice.duePremium,
      quickQuote: iciciQuote,
      handleBuyNow: (e) => handleBuyNow(icici, "", ICICI),
      vehicleBreakupPrice: iciciPrice,
    },
    // // Zuno
    // {
    //   id: 4,
    //   companyName: ZUNO,
    //   companyImg: zuno,
    //   isInspection: inspectDetails,
    //   showInspectionTab: planType !== "Bundled" && planType !== "Third Party",
    //   loadingSate: zunoQuickQuoteLoading || zunoPrice.duePremium === undefined || zunoPrice.duePremium === 0 || ShowSkeleton,
    //   idv: Math.round(zunoIDV)?.toLocaleString('en-IN'),
    //   premiumValue: Math.round(Number(zunoPrice?.duePremium))?.toLocaleString('en-IN'),
    //   basicPremiumValue: Math.round(zunoPrice?.basicPremium)?.toLocaleString('en-IN') || 0,
    //   paCoverValue: Math.round(zunoPrice?.paCover)?.toLocaleString('en-IN') || 0,
    //   isExternalFittedCng: zunoPrice?.odExternalCngLpg,
    //   zeroDepValue: Math.round(zunoPrice?.zeroDep)?.toLocaleString('en-IN') || 0,
    //   shreeramProtctValue: 0,
    //   engProtectValue: zunoPrice?.engineProtection,
    //   consumableValue: zunoPrice?.consumables,
    //   isKeyLockValue: zunoPrice?.keyLockReplacement,
    //   invoiceCoverValue: zunoPrice?.invoiceCover,
    //   roadAssitanceValue: zunoPrice?.roadAssistance,
    //   tyreProtectionValue: zunoPrice?.tyreProtection,
    //   personalBelongingValue: zunoPrice?.lossofPersonalBelongings,
    //   dailyAllowanceValue: zunoPrice?.dailyAllowance,
    //   passengerCoverValue: zunoPrice?.passengerCover,
    //   ncbProtectionValue: zunoPrice?.ncbProtection,
    //   accessoriesValue: zunoPrice?.accessories,
    //   driverCoverValue: zunoPrice?.driverCover,
    //   showPlanDetailsTab: !zunoQuickQuoteLoading && zunoPrice.duePremium && !ShowSkeleton,
    //   quickQuote: zunoQuickQuote,
    //   handleBuyNow: (e) => handleBuyNow(zuno, "", ZUNO),
    //   vehicleBreakupPrice: zunoPrice,
    // },
    // // Shreeram
    // {
    //   id: 5,
    //   companyName: SHREERAM,
    //   companyImg: shreeRam,
    //   isInspection: inspectDetails,
    //   showInspectionTab: planType !== "Bundled" && planType !== "Third Party",
    //   loadingSate: shreeRamQuoteLoading || shreeRamPrice?.totalPremium === undefined || shreeRamPrice?.totalPremium === 0 || ShowSkeleton || shreeRamPrice?.totalPremium === false,
    //   idv: Math.round(shreeRamQuotePrice?.VehicleIDV)?.toLocaleString('en-IN'),
    //   premiumValue: Math.round(shreeRamPrice?.totalPremium)?.toLocaleString('en-IN'),
    //   basicPremiumValue: Math.round(shreeRamPrice?.basicPremium)?.toLocaleString('en-IN'),
    //   paCoverValue: Math.round(shreeRamPrice?.paCover)?.toLocaleString('en-IN'),
    //   isExternalFittedCng: shreeRamPrice.odExternalCngLpg,
    //   zeroDepValue: Math.round(shreeRamPrice?.zeroDep)?.toLocaleString('en-IN'),
    //   shreeramProtctValue: shreeRamPrice?.shreeramProtect,
    //   engProtectValue: shreeRamPrice?.engineProtection,
    //   consumableValue: shreeRamPrice?.consumables,
    //   isKeyLockValue: shreeRamPrice?.keyLockReplacement,
    //   invoiceCoverValue: shreeRamPrice?.invoiceCover,
    //   roadAssitanceValue: shreeRamPrice?.roadAssistance,
    //   tyreProtectionValue: shreeRamPrice?.tyreProtection,
    //   personalBelongingValue: shreeRamPrice?.lossofPersonalBelongings,
    //   dailyAllowanceValue: shreeRamPrice?.dailyAllowance,
    //   passengerCoverValue: shreeRamPrice?.passengerCover,
    //   ncbProtectionValue: shreeRamPrice?.ncbProtection,
    //   accessoriesValue: shreeRamPrice?.accessories,
    //   driverCoverValue: shreeRamPrice?.driverCover,
    //   showPlanDetailsTab: !shreeRamQuoteLoading && shreeRamPrice?.totalPremium && !ShowSkeleton && shreeRamPrice?.totalPremium !== false,
    //   quickQuote: shreeRamQuote,
    //   handleBuyNow: (e) => handleBuyNow(shreeRam, " ", SHREERAM),
    //   vehicleBreakupPrice: shreeRamPrice,
    // },
    // Digit
    {
      id: 6,
      companyName: DIGIT,
      companyImg: digit,
      // isInspection: inspectDetails,
      showInspectionTab: planType !== "Bundled" && planType !== "Third Party",
      loadingSate: digitQuoteLoading || digitPrice.duePremium === undefined || digitPrice.duePremium === 0 || !digitIDV?.idv,
      idv: Math.round(digitIDV?.idv)?.toLocaleString('en-IN'),
      premiumValue: Math.round(Number(digitPrice.duePremium))?.toLocaleString('en-IN'),
      basicPremiumValue: digitPrice?.basicPremium || 0,
      paCoverValue: digitPrice?.paCover || 0,
      isExternalFittedCng: "", // shreeRamPrice.odExternalCngLpg
      zeroDepValue: digitPrice?.zeroDep || 0,
      shreeramProtctValue: 0,
      engProtectValue: digitPrice?.engineProtection,
      consumableValue: digitPrice?.consumables,
      isKeyLockValue: digitPrice?.keyLockReplacement,
      invoiceCoverValue: digitPrice?.invoiceCover,
      roadAssitanceValue: digitPrice?.roadAssistance,
      tyreProtectionValue: digitPrice?.tyreProtection,
      personalBelongingValue: digitPrice?.personalBelonging,
      dailyAllowanceValue: digitPrice.dailyAllowance,
      passengerCoverValue: digitPrice.passengerCover,
      ncbProtectionValue: digitPrice?.ncbProtection,
      accessoriesValue: digitPrice?.accessories,
      driverCoverValue: digitPrice.driverCover,
      showPlanDetailsTab: !digitQuoteLoading && digitPrice.duePremium,
      quickQuote: digitQuote,
      handleBuyNow: (e) => handleBuyNow(digit, "", DIGIT),
      vehicleBreakupPrice: digitPrice,
    },
    {
      id: 7,
      companyName: HDFC,
      companyImg: hdfc,
      // isInspection: inspectDetails,
      // showInspectionTab: planType !== "Bundled" && planType !== "Third Party",
      loadingSate: hdfcQuoteLoading || hdfcPrice.duePremium === undefined || hdfcPrice.duePremium === 0,
      idv: Math.round(hdfcIDV)?.toLocaleString('en-IN'),
      premiumValue: (Math.round(hdfcPrice.basicPremium)),
      basicPremiumValue: Math.round(hdfcPrice?.basicPremium)?.toLocaleString('en-IN') || 0,
      paCoverValue: Math.round(hdfcPrice?.paCover)?.toLocaleString('en-IN') || 0,
      isExternalFittedCng: hdfcPrice?.odExternalCngLpg,
      zeroDepValue: Math.round(hdfcPrice?.zeroDep)?.toLocaleString('en-IN') || 0,
      shreeramProtctValue: 0,
      engProtectValue: hdfcPrice?.engineProtection,
      consumableValue: hdfcPrice?.consumables,
      isKeyLockChecked: checkboxTrueFalseObject.keyLockReplacement,
      isKeyLockValue: hdfcPrice?.keyLockReplacement,
      invoiceCoverValue: hdfcPrice?.invoiceCover,
      isRoadAssitanceChecked: checkboxTrueFalseObject.roadAssistance,
      roadAssitanceValue: hdfcPrice?.roadAssistance,
      tyreProtectionValue: hdfcPrice?.tyreProtection,
      personalBelongingValue: hdfcPrice?.personalBelonging,
      dailyAllowanceValue: hdfcPrice.dailyAllowance,
      passengerCoverValue: hdfcPrice.passengerCover,
      ncbProtectionValue: hdfcPrice?.ncbProtection,
      accessoriesValue: hdfcPrice?.accessories,
      driverCoverValue: hdfcPrice?.driverCover,
      showPlanDetailsTab: !hdfcQuoteLoading && hdfcPrice.duePremium,
      quickQuote: hdfcQuote,

      handleBuyNow: (e) => handleBuyNow(hdfc, "", HDFC),
      vehicleBreakupPrice: hdfcPrice,
    },
  ]
  const [loadingState, setLoadingState] = useState(false)
  const [ErrorPlan, setErrorPlan] = useState(false)
  if (checkboxTrueFalseObject.zeroDep) {
    quotesContent[4].premiumValue += Number(hdfcPrice?.zeroDep)

  }
  if (checkboxTrueFalseObject.paCover) {

    quotesContent[4].premiumValue += Number(hdfcPrice?.paCover)
  }
  if (checkboxTrueFalseObject.consumables) {

    quotesContent[4].premiumValue += Number(hdfcPrice?.consumables)
  }
  if (checkboxTrueFalseObject.roadAssistance) {

    quotesContent[4].premiumValue += Number(hdfcPrice?.roadAssistance)
  }
  if (checkboxTrueFalseObject.engineProtection) {

    quotesContent[4].premiumValue += Number(hdfcPrice?.engineProtection)
  }
  if (checkboxTrueFalseObject.invoiceCover) {

    quotesContent[4].premiumValue += Number(hdfcPrice?.invoiceCover)
  }
  if (checkboxTrueFalseObject.ncbProtection) {

    quotesContent[4].premiumValue += Number(hdfcPrice?.ncbProtection)
  }

  let filteredQuotes = quotesContent.filter(quote => {
    const premiumValue = typeof quote.premiumValue === 'string' ? parseFloat(quote.premiumValue.replace(/,/g, '')) : quote.premiumValue;
    return premiumValue > 0 && !isNaN(premiumValue) && quote.premiumValue !== undefined;
  });

  filteredQuotes.sort((a, b) => {
    const premiumValueA = typeof a.premiumValue === 'string' ? parseFloat(a.premiumValue.replace(/,/g, '')) : a.premiumValue;
    const premiumValueB = typeof b.premiumValue === 'string' ? parseFloat(b.premiumValue.replace(/,/g, '')) : b.premiumValue;
    return premiumValueA - premiumValueB;
  });

  let sortedQuotes = filteredQuotes.concat(quotesContent.filter(quote => {
    const premiumValue = typeof quote.premiumValue === 'string' ? parseFloat(quote.premiumValue.replace(/,/g, '')) : quote.premiumValue;
    return premiumValue === 0 || isNaN(premiumValue) || quote.premiumValue === undefined;
  }));

  quotesContent = sortedQuotes;

  let showtataCard;

  if (planType === "Comprehensive") {
    if (vehicleAge >= 5 && vehicleAge <= 15 && !inspectDetails) {
      showtataCard = true;
    } else {
      showtataCard = false;
    }
  } else {
    showtataCard = true;
  }
  let filterContets = quotesContent.filter((e) => {
    if (loadingState) {
      return e.id == 1
    } else {
      return e.loadingSate;
    }
  })

  useEffect(() => {
    setErrorPlan(false)
    let id = setTimeout(() => {
      setErrorPlan(true)
    }, 8000)
    return (() => {
      clearTimeout(id)
    })
  }, [carDetails.idv, carDetails.PolicyTypeValue, carDetails.idvValue, carDetails.policyExpDate, carDetails.fuelType, carDetails.make, carDetails.varient, carDetails.prevNcb, carDetails.make, carDetails.fuel, carDetails.varient, carDetails.cc, carDetails.regDate, carDetails.prevPolicyType, carDetails.policyRegDate])


  console.log(allIDVValues, "kjjkhlkjjuu")


  return (
    <>
      <section className="plancard_main_container">

        {/* *************** DYNAMIC - PLAN CARD'S *************** */}

        {
          filteredQuotes?.map((data, key) => (
            !data.loadingSate &&
            <div key={key} className="plancard_card">
              <div className="padding_breakup_container padding_breakup_container_bg">
                <div className="plancard_card_img_holder hide-in-mobile">
                  <img src={data.companyImg} alt="" srcSet="" />
                </div>

                <div className="premiumContainer_pc hide-in-mobile">
                  {
                    planType !== "Third Party" ? (
                      <p className="idv_title_pc">
                        Cover Value(IDV) :{" "}
                        {data.loadingSate ? (
                          <SkeletonLoader variant="rectangular" width={120} height={15} />
                        ) : (
                          <span>₹ {data.idv}</span>
                        )}
                      </p>
                    ) : null
                  }

                  {data.loadingSate ? (
                    <ButtonLoader />
                  ) : (
                    <Botton
                      text={`₹ ${data.companyName === HDFC ? (data.premiumValue)?.toLocaleString('en-IN') : data.premiumValue}`}
                      className="buyNowBtn_pc"
                      name="Plan Card"
                      spanTxt="Buy Now"
                      event={data.handleBuyNow}
                    />
                  )}
                </div>
                <div className="premiumContainer_mobile-container">
                  <img src={data.companyImg} alt="" srcSet="" />
                  {
                    planType !== "Third Party" ? (
                      <p className="idv_title_pc idv_title_pc-mobile">
                        Cover Value(IDV) :{" "}
                        {data.loadingSate ? (
                          <SkeletonLoader variant="rectangular" width={120} height={15} />
                        ) : (
                          <span>₹ {data.idv.toLocaleString('en-IN')}</span>
                        )}
                      </p>
                    ) : null
                  }
                  {data.loadingSate ? (
                    <ButtonLoader />
                  ) : (
                    <>
                      <Botton
                        text={`₹ ${data.premiumValue.toLocaleString('en-IN')}`}
                        // text="₹ 1,00,000"
                        className="buyNowBtn_pc buyNowBtn_pc-mobile"
                        name="Plan Card"
                        spanTxt="Buy Now"
                        event={(e) => triggerVerifyTab(data.companyImg, carDetails, data.companyName)}
                      />
                    </>
                  )}
                </div>

                <div className="breakup_container">

                  {data.loadingSate ? (
                    <SkeletonLoader variant="rectangular" height={15} />
                  ) : (
                    <div className="planHeadValueContainer hide-in-mobile">
                      <div className="planHead">
                        <p>
                          Basic Price{" "} {
                            data.basicPremiumValue ? (
                              <span>
                                ₹{" "}
                                {data.basicPremiumValue}
                              </span>
                            ) : (<span>0</span>)
                          }
                        </p>
                      </div>
                    </div>
                  )}

                  {/* paCover */}

                  {planType !== "Own Damage" ? (
                    <>
                      {checkboxTrueFalseObject.paCover ? (
                        <>
                          {data.loadingSate ? (
                            <SkeletonLoader variant="rectangular" height={15} />
                          ) : (
                            <div className="planHeadValueContainer">
                              <div className="planHead">
                                <p>
                                  Personal Accident Cover
                                  <span>
                                    ₹{" "}
                                    {data.paCoverValue}
                                  </span>
                                </p>
                              </div>
                              <div className="result_pc">
                                <img
                                  src={data.paCoverValue ? success : fail}
                                  alt=""
                                  srcSet=""
                                />
                              </div>
                            </div>
                          )}
                        </>
                      ) : null}
                    </>
                  ) : null
                  }

                  {/* externalFittedCNG */}

                  {getBodyData?.data?.isCngLpgVehicleType ? (
                    <>
                      {data.loadingSate ? (
                        <SkeletonLoader variant="rectangular" height={15} />
                      ) : (
                        <div className="planHeadValueContainer">
                          <div className="planHead">
                            <p>External Fitted CNG</p>
                          </div>
                          <div className="result_pc">
                            <img
                              src={data?.isExternalFittedCng ? success : fail}
                              alt=""
                              srcSet=""
                            />
                          </div>
                        </div>
                      )}
                    </>
                  ) : null}

                  {/* zeroDepreciation */}

                  {
                    planType !== "Third Party" && (
                      <>
                        {checkboxTrueFalseObject.zeroDep ? (
                          <>
                            {data.loadingSate ? (
                              <SkeletonLoader variant="rectangular" height={15} />
                            ) : (
                              <div className="planHeadValueContainer">
                                <div className="planHead">
                                  <p>
                                    Zero Depreciation
                                    <span className="bold_premium">
                                      ₹{" "}
                                      {data.zeroDepValue}
                                    </span>
                                  </p>
                                </div>
                                <div className="result_pc">
                                  <img
                                    src={data.zeroDepValue != 0 ? success : fail}
                                    alt=""
                                    srcSet=""
                                  />
                                </div>
                              </div>
                            )}
                          </>
                        ) : null}
                      </>
                    )
                  }

                  {/* motorProtection */}

                  {
                    planType !== "Third Party" && (
                      <>
                        {checkboxTrueFalseObject.shreeramProtect ? (
                          <>
                            {data.loadingSate ? (
                              <SkeletonLoader variant="rectangular" height={15} />
                            ) : (
                              <div className="planHeadValueContainer">
                                <div className="planHead">
                                  <p>Motor Protection</p>
                                </div>
                                <div className="result_pc">
                                  <img
                                    src={data.companyName !== SHREERAM ? fail : success}
                                    alt=""
                                    srcSet=""
                                  />
                                </div>
                              </div>
                            )}
                          </>
                        ) : null}
                      </>
                    )
                  }

                  {/* EngProtect */}

                  {
                    planType !== "Third Party" && (
                      <>
                        {checkboxTrueFalseObject.engineProtection ? (
                          <>
                            {data.loadingSate ? (
                              <SkeletonLoader variant="rectangular" height={15} />
                            ) : (
                              <div className="planHeadValueContainer">
                                <div className="planHead">
                                  <p>Engine Protector</p>
                                </div>
                                <div className="result_pc">
                                  <img
                                    src={data.engProtectValue ? success : fail}
                                    alt=""
                                    srcSet=""
                                  />
                                </div>
                              </div>
                            )}
                          </>
                        ) : null}
                      </>
                    )
                  }

                  {/* Consumbales */}

                  {
                    planType !== "Third Party" && (
                      <>
                        {checkboxTrueFalseObject.consumables ? (
                          <>
                            {data.loadingSate ? (
                              <SkeletonLoader variant="rectangular" height={15} />
                            ) : (
                              <div className="planHeadValueContainer">
                                <div className="planHead">
                                  <p>Consumable</p>
                                </div>
                                <div className="result_pc">
                                  <img
                                    src={data.consumableValue ? success : fail}
                                    alt=""
                                    srcSet=""
                                  />
                                </div>
                              </div>
                            )}
                          </>
                        ) : null}
                      </>
                    )
                  }

                  {
                    data.companyName === ACKO ? (
                      <>
                        {/* keyAndLock */}

                        {
                          planType !== "Third Party" && (
                            <>
                              {checkboxTrueFalseObject.keyLockReplacement || checkboxTrueFalseObject.roadAssistance ? (
                                <>
                                  {data.loadingSate ? (
                                    <SkeletonLoader variant="rectangular" height={15} />
                                  ) : (
                                    <div className="planHeadValueContainer">
                                      <div className="planHead">
                                        <p>Key and Lock Replacement</p>
                                      </div>
                                      <div className="result_pc">
                                        <img
                                          src={data.isKeyLockValue ? success : fail}
                                          alt=""
                                          srcSet=""
                                        />
                                      </div>
                                    </div>
                                  )}
                                </>
                              ) : null}
                            </>
                          )
                        }

                        {/* RSA */}

                        {
                          planType !== "Third Party" && (
                            <>
                              {checkboxTrueFalseObject.keyLockReplacement || checkboxTrueFalseObject.roadAssistance ? (
                                <>
                                  {data.loadingSate ? (
                                    <SkeletonLoader variant="rectangular" height={15} />
                                  ) : (
                                    <div className="planHeadValueContainer">
                                      <div className="planHead">
                                        <p>24x7 Roadside Assistance</p>
                                      </div>
                                      <div className="result_pc">
                                        <img
                                          src={data.roadAssitanceValue ? success : fail}
                                          alt=""
                                          srcSet=""
                                        />
                                      </div>
                                    </div>
                                  )}
                                </>
                              ) : null}
                            </>
                          )
                        }
                      </>
                    ) : (
                      <>
                        {/* keyAndLock */}

                        {
                          planType !== "Third Party" && (
                            <>
                              {checkboxTrueFalseObject.keyLockReplacement ? (
                                <>
                                  {data.loadingSate ? (
                                    <SkeletonLoader variant="rectangular" height={15} />
                                  ) : (
                                    <div className="planHeadValueContainer">
                                      <div className="planHead">
                                        <p>Key and Lock Replacement</p>
                                      </div>
                                      <div className="result_pc">
                                        <img
                                          src={data.isKeyLockValue ? success : fail}
                                          alt=""
                                          srcSet=""
                                        />
                                      </div>
                                    </div>
                                  )}
                                </>
                              ) : null}
                            </>
                          )
                        }

                        {/* RSA */}

                        {
                          planType !== "Third Party" && (
                            <>
                              {checkboxTrueFalseObject.roadAssistance ? (
                                <>
                                  {data.loadingSate ? (
                                    <SkeletonLoader variant="rectangular" height={15} />
                                  ) : (
                                    <div className="planHeadValueContainer">
                                      <div className="planHead">
                                        <p>24x7 Roadside Assistance</p>
                                      </div>
                                      <div className="result_pc">
                                        <img
                                          src={data.roadAssitanceValue ? success : fail}
                                          alt=""
                                          srcSet=""
                                        />
                                      </div>
                                    </div>
                                  )}
                                </>
                              ) : null}
                            </>
                          )
                        }
                      </>
                    )
                  }

                  {/* InvoiceCover */}

                  {
                    planType !== "Third Party" && (
                      <>
                        {checkboxTrueFalseObject.invoiceCover ? (
                          <>
                            {data.loadingSate ? (
                              <SkeletonLoader variant="rectangular" height={15} />
                            ) : (
                              <div className="planHeadValueContainer">
                                <div className="planHead">
                                  <p>Invoice Cover</p>
                                </div>
                                <div className="result_pc">
                                  <img
                                    src={data.invoiceCoverValue ? success : fail}
                                    alt=""
                                    srcSet=""
                                  />
                                </div>
                              </div>
                            )}
                          </>
                        ) : null}
                      </>
                    )
                  }

                  {/* tyreProtection */}

                  {
                    planType !== "Third Party" && (
                      <>
                        {checkboxTrueFalseObject.tyreProtection ? (
                          <>
                            {data.loadingSate ? (
                              <SkeletonLoader variant="rectangular" height={15} />
                            ) : (
                              <div className="planHeadValueContainer">
                                <div className="planHead">
                                  <p>Tyre Protection</p>
                                </div>
                                <div className="result_pc">
                                  <img
                                    src={data.tyreProtectionValue ? success : fail}
                                    alt=""
                                    srcSet=""
                                  />
                                </div>
                              </div>
                            )}
                          </>
                        ) : null}
                      </>
                    )
                  }

                  {/* lossOfPersonalBelongings */}

                  {
                    planType !== "Third Party" && (
                      <>
                        {checkboxTrueFalseObject.lossofPersonalBelongings ? (
                          <>
                            {data.loadingSate ? (
                              <SkeletonLoader variant="rectangular" height={15} />
                            ) : (
                              <div className="planHeadValueContainer">
                                <div className="planHead">
                                  <p>Loss of Personal Belongings</p>
                                </div>
                                <div className="result_pc">
                                  <img
                                    src={data.personalBelongingValue ? success : fail}
                                    alt=""
                                    srcSet=""
                                  />
                                </div>
                              </div>
                            )}
                          </>
                        ) : null}
                      </>
                    )
                  }

                  {/* dailyAllownce */}

                  {
                    planType !== "Third Party" && (
                      <>
                        {checkboxTrueFalseObject.dailyAllowance ? (
                          <>
                            {data.loadingSate ? (
                              <SkeletonLoader variant="rectangular" height={15} />
                            ) : (
                              <div className="planHeadValueContainer">
                                <div className="planHead">
                                  <p>Daily Allowance</p>
                                </div>
                                <div className="result_pc">
                                  <img src={data.dailyAllowanceValue ? success : fail} />
                                </div>
                              </div>
                            )}
                          </>
                        ) : null}
                      </>
                    )
                  }

                  {/* passengerCover */}

                  {
                    planType !== "Own Damage" && (
                      <>
                        {checkboxTrueFalseObject.passengerCover ? (
                          <>
                            {data.loadingSate ? (
                              <SkeletonLoader variant="rectangular" height={15} />
                            ) : (
                              <div className="planHeadValueContainer">
                                <div className="planHead">
                                  <p>Passenger Cover</p>
                                </div>
                                <div className="result_pc">
                                  <img src={data.passengerCoverValue ? success : fail} />
                                </div>
                              </div>
                            )}
                          </>
                        ) : null}
                      </>
                    )
                  }

                  {/* NCB-protection */}

                  {
                    planType !== "Third Party" && (
                      <>
                        {checkboxTrueFalseObject.ncbProtection ? (
                          <>
                            {data.loadingSate ? (
                              <SkeletonLoader variant="rectangular" height={15} />
                            ) : (
                              <div className="planHeadValueContainer">
                                <div className="planHead">
                                  <p>NCB Protection Cover</p>
                                </div>
                                <div className="result_pc">
                                  <img
                                    src={data.ncbProtectionValue ? success : fail}
                                    alt=""
                                    srcSet=""
                                  />
                                </div>
                              </div>
                            )}
                          </>
                        ) : null}
                      </>
                    )
                  }

                  {/* accessories */}

                  {
                    planType !== "Third Party" && (
                      <>
                        {checkboxTrueFalseObject.accessories ? (
                          <>
                            {data.loadingSate ? (
                              <SkeletonLoader variant="rectangular" height={15} />
                            ) : (
                              <div className="planHeadValueContainer">
                                <div className="planHead">
                                  <p>Accessories</p>
                                </div>
                                <div className="result_pc">
                                  <img
                                    src={data.accessoriesValue ? success : fail}
                                    alt=""
                                    srcSet=""
                                  />
                                </div>
                              </div>
                            )}
                          </>
                        ) : null}
                      </>
                    )
                  }

                  {/* driverCover */}

                  {
                    planType !== "Own Damage" && (
                      <>
                        {checkboxTrueFalseObject.driverCover ? (
                          <>
                            {data.loadingSate ? (
                              <SkeletonLoader variant="rectangular" height={15} />
                            ) : (
                              <div className="planHeadValueContainer">
                                <div className="planHead">
                                  <p>Driver Cover</p>
                                </div>
                                <div className="result_pc">
                                  <img src={data.driverCoverValue ? success : fail} />
                                </div>
                              </div>
                            )}
                          </>
                        ) : null}
                      </>
                    )
                  }

                </div>
              </div>
              <div className="bottomOptions_planCard bottomOptions_planCard_bg">
                <div className="option_div_pc option_div_pc_border">
                  {data.showPlanDetailsTab ? (
                    <PlanDetails
                      QuickQuote={data.quickQuote}
                      triggerTab="Plan Details"
                      handleBuyNow={data.handleBuyNow}
                      companyImg={data.companyImg}
                      companyName={data.companyName}
                      vehicleBreakupPrice={data.vehicleBreakupPrice}
                      bodyData={getBodyData}
                      setShowBottomTab={setShowBottomTab}
                      carDetails={carDetails}
                      allIDVValues={allIDVValues}
                    />
                  ) : null}
                </div>
                <div className="option_div_pc">
                  <p>Cashless Garages</p>
                </div>
              </div>
            </div>

          ))
        }
        {filterContets.map((e) => {
          if (CalendarDeactiveFlag || !ErrorPlan) {
            return (
              <div className="plancard_card">
                <div className="padding_breakup_container">
                  <div className="plancard_card_img_holder hideInMobile">
                    <img src={e.companyImg} alt="" srcSet="" />

                  </div>

                  <div className="premiumContainer_pc hideInMobile">
                    <p className="idv_title_pc">
                      Cover Value(IDV) :
                      <SkeletonLoader variant="rectangular" width={120} height={15} />
                    </p>
                    <div className="premiumContainer_pc">
                      <ButtonLoader />
                    </div>
                    <div className="breakup_container">
                      <SkeletonLoader variant="rectangular" height={15} />
                    </div>
                    <div className="breakup_container">
                      <SkeletonLoader variant="rectangular" height={15} />
                    </div>
                    <div className="bottomOptions_planCard">
                      <div className="option_div_pc option_div_pc_border">
                      </div>
                    </div>
                  </div>
                  <div className="premiumContainer_mobile-container">
                    <img src={e.companyImg} alt="" srcSet="" />
                    {
                      planType !== "Third Party" ? (
                        <p className="idv_title_pc idv_title_pc-mobile">
                          Cover Value(IDV) :{" "}
                          <SkeletonLoader variant="rectangular" width={120} height={15} />
                        </p>
                      ) : null
                    }
                    <ButtonLoader className="btn-loader-size" />
                  </div>
                </div>
              </div>
            )
          }

        }

        )}

      </section>
      {loadingState && filterContets[0].isLoading == true && <PlancardError quotesContent={filterContets} />}
      {ErrorPlan && !CalendarDeactiveFlag && <PlancardError quotesContent={filterContets} />}
    </>
  );
};

export default Plancard;