const Botton = (props) => {
  const {
    text,
    className,
    style,
    name,
    event,
    icons,
    spanTxt,
    isDisabledButton,
    value,
    type,
    useCase,
  } = props;

  return (
    <>
      <button
        className={className}
        name={name}
        style={style}
        type={type || "button"}
        onClick={(e) => event(e)}
        disabled={isDisabledButton}
        value={value}
        onSubmit={onsubmit}
      >
        <img src={icons} alt="" srcSet="" />
        <span> {spanTxt} </span> {text}
      </button>
    </>
  );
};

export default Botton;
