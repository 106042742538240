import { createAsyncThunk } from "@reduxjs/toolkit";

export const hdfcQuickQuoteAction = createAsyncThunk(
  "hdfcQuikQuotes",
  async (bodyData) => {
    const response = await fetch(
      `${process.env.REACT_APP_HDFC_QUICK_QUOTE_URL}`,
      {
        method: "POST",
        body: JSON.stringify(bodyData),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }
    );
    return response.json();
  }
);
export const hdfcProposalAction = createAsyncThunk(
  "hdfcProposal",
  async (bodyData) => {
    const response = await fetch(
      `${process.env.REACT_APP_HDFC_PROPOSAL_URL}`,
      {
        method: "POST",
        body: JSON.stringify(bodyData),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }
    );
    return response.json();
  }
);

export const hdfcKycAuto = createAsyncThunk(
  "hdfcKycAuto",
  async (bodyData) => {
    const response = await fetch(
      `${process.env.REACT_APP_HDFC_KYC_AUTO_URL}`,
      {
        method: "POST",
        body: JSON.stringify(bodyData),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }
    );
    return response.json();
  }
);

export const hdfcChecksumAction = createAsyncThunk(
  "hdfcChecksum",
  async (bodyData) => {
    const response = await fetch(
      `${process.env.REACT_APP_HDFC_CHECKSUM_URL}`,
      {
        method: "POST",
        body: JSON.stringify(bodyData),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }
    );
    return response.json();
  }
);

export const hdfcPdfDownloadAction = createAsyncThunk(
  "hdfcpdfDownload",
  async (bodyData) => {
    const response = await fetch(
      `${process.env.REACT_APP_HDFC_PDF_DOWNLOAD_URL}`,
      {
        method: "POST",
        body: JSON.stringify(bodyData),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }
    );
    return response.json();
  }
);