import React from "react";
import loader from "../../../Assets/Loader/Loader.mp4";
import loader2 from "../../../Assets/Loader/Loader.gif";
import "./Loader.css";

const Loader = () => {
  return (
    <>
      {/* DESKTOP */}

      <div id="cover-spin" className="desktopLoader_container">
        <img src={loader2} className="gifLoader" />
      </div>

      {/* MOBILE VIEW */}

      <div className="mobile_loader_container">
        <div class="loader"></div>
      </div>
    </>
  );
};

export default Loader;
