import React, { useRef, useEffect, useState, memo } from "react";
import Calendar from "react-multi-date-picker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import dayjs from "dayjs";
import "dayjs/locale/en";
import "./Calendar.css";
import close from "../../../Assets/Images/Close.svg";
import Botton from "../../Button/Button";
import arrow from "../../../Assets/Images/arrRight.svg"
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';

const CustomCalendar = (props) => {
    const {
        name,
        btnId,
        imgId,
        value,
        textId,
        minDate,
        maxDate,
        headText,
        calendarType,
        placeholderId,
        saveCalendarValue,
        cancelCalendarValue,
        handleCopiedDetails,
        getAlternateExpOption,
        ShowAlternateExpOptions,
        preventhandleClickOutside,
        setShowAlternateExpOptions,
        isEnalbeBtn,
    } = props;

    const calendarContainerRef = useRef(null);

    const handleClickOutside = (event) => {

        console.log(event.target.id, "mddmewkjewjkew");


        if (
            event.target.id === btnId ||
            event.target.id === textId ||
            event.target.id === imgId ||
            event.target.id === placeholderId
        ) {
            return;
        }

        if (
            calendarContainerRef.current &&
            !calendarContainerRef.current.contains(event.target)
        ) {
            cancelCalendarValue(name);
        }
    };

    useEffect(() => {
        if (!preventhandleClickOutside) {
            document.addEventListener("click", handleClickOutside);
        }
        return () => {
            if (!preventhandleClickOutside) {
                document.removeEventListener("click", handleClickOutside);
            }
        };
    }, []);

    let alterateOptionsForExp = [
        {
            id: 1,
            option: "Policy has not expired yet." // assume,  it will expire after 1 week.
        },
        {
            id: 2,
            option: "Policy expired in last 90 days." // assume, it has been expired 60 days before. 
        },
        {
            id: 3,
            option: "Policy expired more than 90 days." // assume, it has been expired 90 days before. 
        },
        {
            id: 4,
            option: "I purchased a used car." // assume, it has been expired 90 days before. 
        },
    ];
    console.log(headText, "PolStartDateClonePolStartDateClone");


    return (
        <>
            <div
                className="edit-tabs-container-mob-height-copy edit-tabs-container-mob-height-calendar "
                data-aos="fade-up"
                data-aos-anchor-placement="top-bottom"
            >
                {
                    !ShowAlternateExpOptions ? (
                        <div className="calendar-container" ref={calendarContainerRef} >
                            <div className="calendar-container-head">
                                <p>{headText}</p>
                                {/* <img
                                    src={close}
                                    alt=""
                                    srcSet=""
                                    onClick={(e) => cancelCalendarValue(name)}
                                /> */}
                            </div>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DateCalendar
                                    value={value ? dayjs(value) : name == "policyExpCopy" || name == "odPolicyExpCopy" ? "" : dayjs(new Date())}
                                    onChange={(newValue) => {
                                        handleCopiedDetails(new Date(newValue ? newValue : value), name, "")
                                    }

                                    }
                                    views={['year', 'month', 'day']}
                                    maxDate={maxDate ? dayjs(maxDate) : ""}
                                    minDate={minDate ? dayjs(minDate) : ""}
                                    autoFocus={false}
                                    disableHighlightToday={true}
                                />
                            </LocalizationProvider>
                            <div className="calendar-btn-container">
                                <div className="btn1">
                                    <Botton
                                        text="Cancel"
                                        className="secondry_btn calendar-btn"
                                        event={(e) => cancelCalendarValue(name)}
                                    />
                                </div>
                                <div className="btn2">
                                    <Botton
                                        text={isEnalbeBtn ? "Select" : "Save"}
                                        className={isEnalbeBtn ? "button noSelectedPlan calendar-btn" : "button calendar-btn"}
                                        type="submit"
                                        event={(e) => saveCalendarValue(name)}
                                    />
                                </div>
                            </div>
                            {(calendarType === "expDate" || calendarType === "expDateOd") && (
                                <p
                                    className="footer_hyper_link underline_text calendarTxt-notSure"
                                    onClick={(e) =>
                                        setShowAlternateExpOptions(!ShowAlternateExpOptions)
                                    }
                                >
                                    Don't Know policy expiry date?
                                </p>
                            )}
                        </div>
                    ) : (
                        <div className="calendar-container" ref={calendarContainerRef}>
                            <div className="calendar-container-head calendar-container-head-noBorder">
                                <p>Not sure about the expiry date</p>
                                <img
                                    src={close}
                                    alt=""
                                    srcSet=""
                                    onClick={(e) => cancelCalendarValue(name)}
                                />
                            </div>
                            <div className="alternate-options-expiry">
                                <p>Tell us when did your policy expire?</p>
                                <div className="seprator-calendar"></div>
                                {
                                    alterateOptionsForExp.map((data, key) => (
                                        <div key={key} className="tab-alternate-options-exp" onClick={(e) => getAlternateExpOption(data.option, calendarType)} >
                                            <p>{data.option}</p>
                                            <img src={arrow} alt="" srcset="" />
                                        </div>
                                    ))
                                }
                            </div>
                            {(calendarType === "expDate" || calendarType === "expDateOd") && (
                                <p className="footer_hyper_link underline_text calendarTxt-notSure"
                                    onClick={(e) => setShowAlternateExpOptions(!ShowAlternateExpOptions)} >
                                    I have my policy's expiration date.
                                </p>
                            )}
                        </div>
                    )
                }
            </div>
        </>
    );
};

const areEqual = (prevProps, nextProps) => {
    return (
        prevProps.value === nextProps.value &&
        prevProps.minDate === nextProps.minDate &&
        prevProps.maxDate === nextProps.maxDate &&
        prevProps.ShowAlternateExpOptions === nextProps.ShowAlternateExpOptions &&
        prevProps.preventhandleClickOutside === nextProps.preventhandleClickOutside
    );
};

export default memo(CustomCalendar, areEqual);