import { createAsyncThunk } from "@reduxjs/toolkit";
export const getpolicyDataAction = createAsyncThunk(
  "getpolicydata",
  async (token) => {
    const response = await fetch(
      `${process.env.REACT_APP_CLIENT_POLICYDATA_URL}`,
      {
        method: "GET",
        headers: {
          'Authorization': `Bearer ${token}`,
          "Content-type": "application/json; charset=UTF-8",
        },
      }
    );
    return response.json();
  }
);
export const getRenewpolicyDataAction = createAsyncThunk(
  "getRenewpolicydata",
  async (data2) => {
    let data = {
      data: {
        proposal_id: data2
      }
    };

    const response = await fetch(
      `${process.env.REACT_APP_RENEW_POLICIES_URL}`,
      {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }
    );
    return response.json();
  }
);