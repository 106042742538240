import React from "react";
import "./PolicyCard.css";
import NoPoliciesFound from "./NoPolicyFound";
import PolicyFound from "./PolicyFound";
import loader2 from "../../../../Assets/Loader/Loader.gif";

const PolicyCard = (props) => {
  const {
    FormValue,
    handleInputChange,
    NoPolicyFound,
    IsUploadPolicyAttempted,
    handleUploadPolicy,
    ShowPolicyTypeList,
    setShowPolicyTypeList,
    handleUploadedPolicyInsurer,
    handleCancelPolicyUpload,
    loading
  } = props;

  return (
    <>
     <div id="cover-spin" style={{ display: loading ? "" : "none" }}>
        <img src={loader2} className="gifLoader"></img>
      </div>
      {NoPolicyFound ? (
        <NoPoliciesFound
          IsUploadPolicyAttempted={IsUploadPolicyAttempted}
          handleUploadPolicy={handleUploadPolicy}
          FormValue={FormValue}
          handleInputChange={handleInputChange}
          ShowPolicyTypeList={ShowPolicyTypeList}
          setShowPolicyTypeList={setShowPolicyTypeList}
          handleUploadedPolicyInsurer={handleUploadedPolicyInsurer}
          handleCancelPolicyUpload={handleCancelPolicyUpload}
        />
      ) : (
        <PolicyFound
          handleInputChange={handleInputChange}
          FormValue={FormValue}
        />
      )}
    </>
  );
};

export default PolicyCard;
