import React, { useState, useEffect } from "react";
import InputFields from "../InputFields/InputFields";
import Botton from "../Button/Button";
import PopUp from "../Modal/Modal";
import ModalInp from "./modalInp";
import { Routes, Route, useNavigate, Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { FINDMYCAR, NEW, ROLLOVER } from "../../utils/commonUtils";
import { updateVehicleInfoAction, resetVehicleData } from "../../redux/Reducers/commonVehicleFetchApiReducer";
import { resetBodyDataAction } from "../../redux/Reducers/bodyDataReducer";
import { resetaddOnDataDataAction } from "../../redux/Reducers/addOnsDataReducer";
import { resetModelAction, resetFuelAction, resetVariantAction } from "../../redux/Reducers/mmvReducer"
import {
  resetQuoteAction,
  resetKycAutoAction,
  resetKycAction,
  resetSbiFullQuote,
  resetSbiPolicyGenerate,
  resetSbiPdfDownload,
  resetQuoteActionwithAddOn
} from "../../redux/Reducers/sbiApiReducer";
import {
  resetQuoteActionDig
} from "../../redux/Reducers/digitApiReducer";
import {
  reseticiciQuoteAction,
  reseticiciKycAutoAction,
  reseticiciKycAction,
  iciciProposalReducer,
  resetIciciCreateTransaction,
  resetIcicipdfDownload,
} from "../../redux/Reducers/iciciApiReducer";
import {
  resetackoQuoteAction,
  resetackoProposal,
  resetackopdfDownload
} from "../../redux/Reducers/ackoApiReducer";
import {
  isInitialAction
} from "../../redux/Reducers/inspectionReducer";
import { isViewPlanFlowAction } from "../../redux/Reducers/isViewPlanFlowCompleted";
import loader2 from "../../Assets/Loader/Loader.gif";
import "../RegNumberForm/loaderMain.css";
import ButtonLoader from "../Pages/Loader/Button Loader/ButtonLoader";
import { shreeRamProposalReducer, shreeRamQuickQuoteReducer } from "../../redux/Reducers/shreeRamApiReducer";
import { resethdfcQuoteAction } from "../../redux/Reducers/hdfcApiReducer";
import { digitCreateQuoteReducer, digitQuickQuoteReducer, resetDigitKycStatus, resetDigitPdfDownloadReducer } from "../../redux/Reducers/digitApiReducer";
import {
  resetZunoQuoteAction,
  resetZunoAutoKycAction,
  resetManualKycQuoteAction,
  zunoFullQuoteReducer,
  resetOnlinePaymentQuoteAction,
  resetPdfDownloadAction,
  zunoInsertBodyDownloadAction,
} from "../../redux/Reducers/zunoApiReducer";
import {
  resetashareLink
} from "../../redux/Reducers/shareLinkReducer"
import {
  resettataAutoKycAction,
  resettataManualKycAction,
  resettataProposalAction,
  resettataQuickQuoteAction,
  tataQuickQuoteReducer,
  tataAutoKycReducer,
  tataProposalReducer,
  tataManualKycReducer
} from "../../redux/Reducers/tataApiReducer";
import { resetPaymentWebhook, resetPaymentSendURL } from "../../redux/Reducers/paymentWebhookReducer"
import AOS from "aos";
import "aos/dist/aos.css";
import {
  resetRtoAction,
  resetCityAction,
  resetLoanAction,
  resetStateAction,
  resetInsurerAction,
} from "../../redux/Reducers/stateReducer";
import AckoCreative from "../Widgets/AckoCreative";
import { isFmcContentAction } from "../../redux/Reducers/FindMyCarContentType";
import rightArrow from "../../Assets/Images/arrRight.svg"
import Loader from "../Pages/Loader/Loader";
import { partnerRestPnaKycAction } from "../../redux/Reducers/loginAuthentication";

const FormContainer = (props) => {
  const dispatch = useDispatch();

  useEffect(() => {
    AOS.init({
      duration: 200,
    });
    AOS.refresh();
  }, []);

  const [loading, setLoading] = useState(false);

  const {
    irdai,
    productValue,
    vehicleNumber,
    irdaiContentOne,
    irdaiContentTwo,
    mobileCLickEvent,
    setShowCarContent,
    ProductTabClicked,
    handleVehicleNumber,
    stateForMobileContent,
    ShowProductMobile,
    dealerDetails,
    ShowProduct,
  } = props;

  const navigate = useNavigate();
  const [errors, setErrors] = useState({});
  const [OpenCarDetailsPopUp, setOpenCarDetailsPopUp] = useState(false);


  localStorage.setItem("vehicleNumber", vehicleNumber);
  dispatch(resetBodyDataAction());
  dispatch(resetaddOnDataDataAction());

  // SBI
  dispatch(resetKycAction());
  dispatch(resetQuoteAction());
  dispatch(resetSbiFullQuote());
  dispatch(resetKycAutoAction());
  dispatch(resetSbiPdfDownload());
  dispatch(resetSbiPolicyGenerate());
  // dispatch(resetQuoteActionwithAddOn())
  // ICICI
  dispatch(reseticiciKycAction());
  dispatch(resetIcicipdfDownload());
  dispatch(reseticiciQuoteAction());
  dispatch(reseticiciKycAutoAction());
  dispatch(resetIciciCreateTransaction());
  dispatch(iciciProposalReducer.actions.reseticiciProposal());
  // ACKO
  dispatch(resetackoProposal());
  dispatch(resetackoQuoteAction());
  dispatch(resetackopdfDownload());
  dispatch(partnerRestPnaKycAction())
  // Rest  Auto Kyc Flag from localStorage
  localStorage.removeItem('icicAutoKycData')
  // ZUNO
  dispatch(resetZunoQuoteAction());
  dispatch(resetZunoAutoKycAction());
  dispatch(resetPdfDownloadAction());
  dispatch(resetManualKycQuoteAction());
  dispatch(zunoInsertBodyDownloadAction());
  dispatch(resetOnlinePaymentQuoteAction());
  dispatch(zunoFullQuoteReducer.actions.resetzunofullQuote());
  // TATA
  dispatch(resettataQuickQuoteAction());
  dispatch(resettataAutoKycAction());
  dispatch(resettataManualKycAction());
  dispatch(tataProposalReducer.actions.resettataProposalAction());
  // SHREERAM
  dispatch(shreeRamProposalReducer.actions.resetShreeRamProposal());
  dispatch(shreeRamQuickQuoteReducer.actions.resetShreeRamQuickQuote());

  // DIGIT
  dispatch(resetDigitKycStatus());
  dispatch(resetDigitPdfDownloadReducer());
  dispatch(digitQuickQuoteReducer.actions.resetDigitQuote());
  dispatch(digitCreateQuoteReducer.actions.resetDigitProposal());
  //  shreLink 
  dispatch(resetashareLink())
  //hdfc
  dispatch(resethdfcQuoteAction())

  // TATA
  // INSPECTION
  dispatch(
    isInitialAction({
      isInspectionReq: false,
    })
  );
  dispatch(
    isViewPlanFlowAction({
      isViewPlanFlowCompleted: false,
    })
  );
  // PAYMENT WEBHOOK  
  dispatch(resetPaymentSendURL());
  dispatch(resetPaymentWebhook());
  // DROPDOWN RESPONSE
  dispatch(resetRtoAction());
  dispatch(resetCityAction());
  dispatch(resetLoanAction());
  dispatch(resetStateAction());
  dispatch(resetInsurerAction());
  // fmcContent  getContentType
  dispatch(isFmcContentAction("Make"));

  let [vehicleData, setVehicleData] = useState([]);
  let storageBusinessType = localStorage.getItem("businessType")
    ? localStorage.getItem("businessType")
    : ROLLOVER;

  useEffect(() => {
    dispatch(resetFuelAction());
    dispatch(resetVehicleData());
    dispatch(resetModelAction());
    dispatch(resetVariantAction());
  }, [])

  useEffect(() => {
    if (OpenCarDetailsPopUp) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }
    return () => {
      document.body.style.overflow = '';
    };
  }, [OpenCarDetailsPopUp])

  const getVehicleData = useSelector((state) => {
    return storageBusinessType === ROLLOVER
      ? state?.getVehicleData?.data?.data
      : state?.getVehicleData;
  });
  const uuid = getVehicleData?.uuid;

  function formatDate(date) {
    date = new Date(date)
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const day = date.getDate();
    const month = date.getMonth();
    const year = date.getFullYear();
    return `${months[month]} ${year}`;
    // return `${day} ${months[month]} ${year}`;
  }

  let regDate = formatDate(getVehicleData?.regn_dt);

  useEffect(() => {
    setVehicleData([
      {
        title: `RTO Registration Number :`,
        desc: vehicleNumber,
      },
      {
        title: "Company Model: ",
        desc: `${getVehicleData?.Brand || ""} ${getVehicleData?.Model || ""} ${getVehicleData?.Variant_Name || ""
          } ${getVehicleData?.fuelType || ""} (${getVehicleData?.displacement || ""
          } CC)`,
      },
    ]);
  }, [getVehicleData]);

  const handleRoute = (vehicleInfo, e) => {
    const storeVehicleInfo = vehicleInfo;
    const isValid = validateModalInp(storeVehicleInfo);
    if (isValid) {
      delete vehicleInfo.e
      dispatch(
        updateVehicleInfoAction({ ...{ vehicleNumber }, ...vehicleInfo })
      );
      localStorage.setItem("isFindMyCar", false);
      localStorage.setItem("mobileNo", vehicleInfo.mobileNo);
      localStorage.setItem("pincode", vehicleInfo.pincode);
      localStorage.setItem("vehicleType", "4W");
      navigate(dealerDetails?.dealer_code ?
        `/View-Plans?id=${uuid}&Franchise_ID=${dealerDetails?.dealer_code}`
        : "/View-Plans?id=" + uuid, {
        state: {
          businessType: ROLLOVER,
          vehicleNumber: vehicleNumber,
          isFindMyCar: false,
        },
      });
    }
  };

  const handleRouteBike = (vehicleInfo, e) => {
    const storeVehicleInfo = vehicleInfo;
    const isValid = validateModalInp(storeVehicleInfo);
    if (isValid) {
      delete vehicleInfo.e
      dispatch(
        updateVehicleInfoAction({ ...{ vehicleNumber }, ...vehicleInfo })
      );
      localStorage.setItem("isFindMyCar", false);
      localStorage.setItem("mobileNo", vehicleInfo.mobileNo);
      localStorage.setItem("pincode", vehicleInfo.pincode);
      localStorage.setItem("vehicleType", "2W");
      navigate(dealerDetails?.dealer_code ?
        `/View-Plans-Bike?id=${uuid}&Franchise_ID=${dealerDetails?.dealer_code}`
        : "/View-Plans-Bike?id=" + uuid, {
        state: {
          businessType: ROLLOVER,
          vehicleNumber: vehicleNumber,
          isFindMyCar: false,
        },
      });
    }
  };

  const validateModalInp = (obj) => {

    const newErrors = {};

    if (obj.prevPolicyExpLastMonth !== true) {
      if (obj.isClaimPrevPolicy == null) {
        newErrors.isClaimPrevPolicy = "First Name is required";
      }
    }

    if (obj.isCngLpgVehicleType === true) {
      if (obj.cngKitValue == "") {
        newErrors.cngKitValue = "CNG/LPG Kit Value is required";
      }
    }

    if (obj.isPrevPolicyExp == null) {
      newErrors.isPrevPolicyExp = "First Name is required";
    }

    if (obj.isPrevPolicyExp === true) {
      if (obj.prevPolicyExpLastMonth === null) {
        newErrors.prevPolicyExpLastMonth = "First Name is required";
      }
    }

    let regexMobile = /^[2-9]{1}[0-9]{9}$/;
    if (!obj.mobileNo) {
      newErrors.phoneNumber = "Phone Number is required";
    }

    if (obj.mobileNo && !regexMobile.test(obj.mobileNo)) {
      newErrors.phoneNumber = "Mobile number is Invalid";
    }

    if (!obj.pincode) {
      newErrors.pincode = "Pin code is required";
    }

    let pinregEx = /^[1-9]{1}[0-9]{2}[0-9]{3}$/;
    if (obj.pincode && !pinregEx.test(obj.pincode)) {
      newErrors.pincode = "Invalid pin code";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  var today = new Date();
  var curHr = today.getHours();

  const bottomContent = [
    {
      classOne: "content_left_modal",
      classTwo: "bottom-text-modal-inp",
      classThree: "footer_hyper_link underline_text",
      contentTwo: `Not Your car?`,
    },
  ];

  const bottomContentBike = [
    {
      classOne: "content_left_modal",
      classTwo: "bottom-text-modal-inp",
      classThree: "footer_hyper_link underline_text",
      contentOne: `Sorry! This is not my bike.`,
      contentTwo: `Check my bike`,
    },
  ];

  const leftContent = [
    {
      title: `RTO Registration Number:`,
      desc: vehicleNumber,
    },
    {
      title: `Registration Date:`,
      desc: regDate || "",
    },
    {
      title: "Company Model:",
      desc: `${getVehicleData?.model_details?.[0]?.Brand || ""} ${getVehicleData?.model_details?.[0]?.Model || ""} ${getVehicleData?.model_details?.[0]?.Variant_Name || ""
        } ${getVehicleData?.model_details?.[0]?.fuelType || ""} (${getVehicleData?.model_details?.[0]?.displacement || ""
        } CC)`,
    },
  ];

  const vehicleDetails = {
    regDate: regDate,
    vehicleNumber: vehicleNumber,
    make: getVehicleData?.model_details?.[0]?.Brand,
    model: `${getVehicleData?.model_details?.[0]?.Model || ""}`,
    fuelType: `${getVehicleData?.model_details?.[0]?.fuelType || ""}`,
    varient: `${getVehicleData?.model_details?.[0]?.Variant_Name || ""}`,
    suggested_pincode: getVehicleData?.model_details?.[0]?.suggested_pincode || "",
    displacement: `(${getVehicleData?.model_details?.[0]?.displacement || ""} CC)`,
  }

  const handleFindMyCarRoute = () => {
    localStorage.setItem("businessType", FINDMYCAR);
    navigate(dealerDetails?.dealer_code ?
      `/Find-My-Car?Franchise_ID=${dealerDetails?.dealer_code}`
      : "/Find-My-Car", {
      state: {
        vehicleNumber: vehicleNumber,
        businessType: FINDMYCAR,
      },
    });
  };

  const handleFindMyBikeRoute = () => {
    localStorage.setItem("businessType", FINDMYCAR);
    navigate(dealerDetails?.dealer_code ?
      `/Find-My-Bike?Franchise_ID=${dealerDetails?.dealer_code}`
      : "/Find-My-Bike", {
      state: {
        vehicleNumber: vehicleNumber,
        businessType: FINDMYCAR,
      },
    });
  };

  const handleNewCarRoute = () => {
    localStorage.setItem("businessType", NEW);
    navigate(dealerDetails?.dealer_code ?
      `/Find-My-Car?Franchise_ID=${dealerDetails?.dealer_code}`
      : "/Find-My-Car", {
      state: {
        vehicleNumber: vehicleNumber,
        businessType: NEW,
      },
    });
  };

  const handleNewBikeRoute = () => {
    localStorage.setItem("businessType", NEW);
    navigate(dealerDetails?.dealer_code ?
      `/Find-My-Bike?Franchise_ID=${dealerDetails?.dealer_code}`
      : "/Find-My-Bike", {
      state: {
        vehicleNumber: vehicleNumber,
        businessType: NEW,
      },
    });
  };

  const [RegNumberErr, setRegNumberErr] = useState(false);

  useEffect(() => {
    setRegNumberErr(false)
  }, [ShowProduct])

  useEffect(() => {

    if (loading) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }

    // Cleanup function to reset the overflow property when the component unmounts
    return () => {
      document.body.style.overflow = '';
    };
  }, [loading]);

  const carTab = [
    {
      id: 1,
      heading: "Don't know my car number",
      tabHead: "Find my Car details",
      event: (e) => handleFindMyCarRoute(),
    },
    {
      id: 2,
      heading: "I bought a new car",
      tabHead: "Check my premium",
      event: (e) => handleNewCarRoute(),
    },
  ]

  const bikeTab = [
    {
      id: 1,
      heading: "Don't know my bike number",
      tabHead: "Find my Bike details",
      event: (e) => handleFindMyBikeRoute(),
    },
    {
      id: 2,
      heading: "I bought a new bike",
      tabHead: "Check my premium",
      event: (e) => handleNewBikeRoute(),
    },
  ]

  return (
    <>

      {/* <div id="cover-spin" style={{ display: loading ? "" : "none" }}>
        <img src={loader2} className="gifLoader"></img>
      </div> */}

      {/* {loading ? ("<Loader/>") : null} */}

      <div>
        {productValue != "bike" && (
          <div className="form-vehicleNumber-home-main" data-aos={ProductTabClicked ? "fade-left" : ""} >
            <div className="form-vehicleNumber-home getRegNo_form_part_one"   >
              <p className="getRegNo_form_head">
                Customize your <span>car</span> cover at <span>your price.</span>
              </p>
              <div className="getRegNo_form_inp_container">
                <p className="small_text small_text_position ">
                  Get your policy in 2 minutes.
                </p>

                {/* HIDING IN MOBILE */}
                <form action="" onSubmit={(e) => { e.preventDefault(); }} className="modal-inputs-parent" >
                  <div className="getRegNo_form_inp_parts hideInMobile ">
                    <div className="input_container">

                      <InputFields
                        type="text"
                        placeholder="My car number (MH 01 AB 1234)"
                        className="inputs-form"
                        errClassName="input-err"
                        errState={RegNumberErr}
                        errMsg={(RegNumberErr && vehicleNumber.length > 0) ? "Please Enter a Valid Number" : "Please enter your car registration number."}
                        onChangeEvent={(e) => { handleVehicleNumber(e.target.value); setRegNumberErr(false) }}
                        value={vehicleNumber.trim()}
                      />
                    </div>
                    <div className="input_container_content">
                      <p className="small_text">Don't know my car number.</p>
                      <p
                        onClick={handleFindMyCarRoute}
                        className="footer_hyper_link underline_text"
                      >
                        Find my Car details.
                      </p>
                    </div>
                  </div>

                  <div className="getRegNo_form_inp_parts hideInMobile ">
                    <div className="input_container input_container_btn ">
                      <PopUp
                        type="submit"
                        text="Check Premium"
                        className="button GetRegNo_btn"
                        leftClassOne="content_left_modal"
                        leftClassTwo="content_left_modal_head"
                        leftClassThree="content_left_modal_desc"
                        name="getregnumberpopup"
                        leftSideTitle="Your Car Details"
                        leftSideData={leftContent}
                        bottomContentLeft={bottomContent}
                        // rightSideHead={rightHeading}
                        vehicleNumber={vehicleNumber}
                        renderContent="policyDetails"
                        clickEvent={handleRoute}
                        setRegNumberErr={setRegNumberErr}
                        businessType="rollover"
                        errors={errors}
                        setLoading={setLoading}
                        loading={loading}
                        vehicleDetails={vehicleDetails}
                        setOpenCarDetailsPopUp={setOpenCarDetailsPopUp}
                        vehicleType="4W"
                        dealerDetails={dealerDetails}
                        handleFindMyCarRoute={handleFindMyCarRoute}
                        handleFindMyBikeRoute={handleFindMyBikeRoute}
                        component={
                          <ModalInp
                            type="submit"
                            clickEvent={handleRoute}
                            vehicleNumber={vehicleNumber}
                            errors={errors}
                            businessType="rollover"
                            vehicleType="4W"
                          />

                        }
                      />
                      {/* <ButtonLoader /> */}
                      {/* <Botton
                      text="Renew Existing Policy"
                      event={(e) => console.log("RenewBtn CLicked")}
                      className="button GetRegNo_btn secondry_btn "
                    /> */}
                    </div>

                    <div className="input_container_content">
                      <p className="small_text">I bought a new car.</p>
                      <p
                        className="footer_hyper_link underline_text "
                        onClick={handleNewCarRoute}
                      >
                        Check my Premium.
                      </p>
                    </div>
                  </div>
                </form>

                {/* ------------- */}

                {/* SHOW IN MOBILE */}

                <div className="getRegNo_form_inp_parts hideInDesktop ">
                  <div className="input_container inp-container-mobile ">
                    <InputFields
                      type="text"
                      placeholder="My car number (MH 01 AB 1234)"
                      className="inputs-form"
                      errClassName="input-err"
                      errState={RegNumberErr}
                      errMsg="Please enter your car registration number."
                      onChangeEvent={(e) => handleVehicleNumber(e.target.value)}
                      value={vehicleNumber}
                    />
                  </div>
                  <div className="input_container input_container_btn ">
                    <PopUp
                      text="Check Premium"
                      className="button GetRegNo_btn"
                      leftClassOne="content_left_modal"
                      leftClassTwo="content_left_modal_head"
                      leftClassThree="content_left_modal_desc"
                      name="getregnumberpopup"
                      leftSideTitle="Your Car Details"
                      leftSideData={leftContent}
                      vehicleDetails={vehicleDetails}
                      bottomContentLeft={bottomContent}
                      // rightSideHead={rightHeading}
                      vehicleNumber={vehicleNumber}
                      renderContent="policyDetails"
                      clickEvent={handleRoute}
                      setRegNumberErr={setRegNumberErr}
                      businessType="rollover"
                      errors={errors}
                      setLoading={setLoading}
                      loading={loading}
                      mobileCLickEvent={mobileCLickEvent}
                      setOpenCarDetailsPopUp={setOpenCarDetailsPopUp}
                      vehicleType="4W"
                      dealerDetails={dealerDetails}
                      handleFindMyCarRoute={handleFindMyCarRoute}
                      handleFindMyBikeRoute={handleFindMyBikeRoute}
                      component={
                        <ModalInp
                          clickEvent={handleRoute}
                          vehicleNumber={vehicleNumber}
                          businessType="rollover"
                          errors={errors}
                          vehicleType="4W"
                        />
                      }
                    />
                    {/* <Botton
                      text="Renew Existing Policy"
                      event={(e) => console.log("RenewBtn CLicked")}
                      className="button GetRegNo_btn secondry_btn "
                    /> */}
                  </div>
                </div>

                <div className="phone-tabs-block hideInDesktop ">
                  <p>or</p>
                  {
                    carTab.map((item, key) => (
                      <div key={key} className="phone-redirect-tab" onClick={item.event} >
                        <p>{item.heading}</p>
                        <p>{item.tabHead} <img src={rightArrow} /> </p>
                      </div>
                    ))
                  }
                </div>

                {/* ------------ */}

                <div className="bottom_getRegno_  bottom_getRegno_mobile">
                  <p className="small_text  small_text_flex">
                    I need help.
                    <span className="footer_hyper_link underline_text ">
                      Request a call from Expert.
                    </span>
                  </p>
                  <p className="small_text  small_text_flex">
                    OR Call us.
                    <a href={dealerDetails?.mobile_no ? `tel:${dealerDetails?.mobile_no}` : "tel:9811223458"} className="footer_hyper_link underline_text ">
                      {dealerDetails?.mobile_no ? dealerDetails?.mobile_no : "9811223458"}
                    </a>
                  </p>
                </div>
              </div>
            </div>

            <div className="getRegNo_form_part getRegNo_form_part_two getRegNo_form_part_two-creative hideInMobile ">
              <div className="irdai_container">
                <div className="irdai_container_head showInMobileIrdai ">
                  <img src={irdai} alt="" srcSet="" />
                  <p className="small_text irdai_small_text">
                    Licensed approved by IRDAI Direct Broker Licence No. 762
                  </p>
                </div>
                <div className="getRegNo_highlight_container">
                  <div className="getRegNo_highlight_container_part">
                    {irdaiContentOne?.map((index, key) => (
                      <div key={key} className="highlight_container_part_child">
                        <p className="numbers_getregno">
                          {index.number}
                          <span className="numbers_getregno_blue">
                            {index.inSpan}
                          </span>
                        </p>
                        <p className="small_text irdai_small_text">
                          {index.description}
                        </p>
                      </div>
                    ))}
                  </div>
                  <div className="getRegNo_highlight_container_part">
                    {irdaiContentTwo?.map((index, key) => (
                      <div key={key} className="highlight_container_part_child">
                        <p className="numbers_getregno">
                          {index.number}
                          <span className="numbers_getregno_blue">
                            {index.inSpan}
                            {key === 1 ? (
                              <> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </>
                            ) : (
                              ""
                            )}
                          </span>
                        </p>
                        <p className="small_text irdai_small_text">
                          {index.description}
                        </p>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
        }

        {productValue == "bike" && (
          <div className="form-vehicleNumber-home-main" data-aos="fade-left" >
            <div className="form-vehicleNumber-home getRegNo_form_part_one"  >
              <p className="getRegNo_form_head">
                Customize your <span>bike</span> cover at <span>your price.</span>
              </p>
              <div className="getRegNo_form_inp_container">
                <p className="small_text small_text_position ">
                  Get your policy in 2 minutes.
                </p>

                {/* HIDING IN MOBILE */}
                <form action="" onSubmit={(e) => { e.preventDefault(); }} className="modal-inputs-parent" >
                  <div className="getRegNo_form_inp_parts hideInMobile ">
                    <div className="input_container">
                      <InputFields
                        type="text"
                        placeholder="My bike number (MH 01 AB 1234)"
                        className="inputs-form"
                        errClassName="input-err"
                        errState={RegNumberErr}                            // Validation error msg change.
                        errMsg={(RegNumberErr && vehicleNumber.length > 0) ? "Please Enter a Valid Number" : "Please enter your car registration number."}
                        onChangeEvent={(e) => handleVehicleNumber(e.target.value)}
                        value={vehicleNumber.trim()}
                      />
                    </div>
                    <div className="input_container_content">
                      <p className="small_text">Don't know my bike number.</p>
                      <p
                        onClick={handleFindMyBikeRoute}
                        className="footer_hyper_link underline_text"
                      >
                        Find my bike details.
                      </p>
                    </div>
                  </div>

                  <div className="getRegNo_form_inp_parts hideInMobile ">
                    <div className="input_container input_container_btn ">
                      <PopUp
                        type="submit"
                        text="Check Premium"
                        className="button GetRegNo_btn"
                        leftClassOne="content_left_modal"
                        leftClassTwo="content_left_modal_head"
                        leftClassThree="content_left_modal_desc"
                        name="getregnumberpopup"
                        leftSideTitle="Your bike Details"
                        leftSideData={leftContent}
                        bottomContentLeft={bottomContentBike}
                        // rightSideHead={rightHeading}
                        vehicleNumber={vehicleNumber}
                        renderContent="policyDetails"
                        clickEvent={handleRouteBike}
                        setRegNumberErr={setRegNumberErr}
                        businessType="rollover"
                        errors={errors}
                        setLoading={setLoading}
                        loading={loading}
                        vehicleDetails={vehicleDetails}
                        setOpenCarDetailsPopUp={setOpenCarDetailsPopUp}
                        vehicleType="2W"
                        dealerDetails={dealerDetails}
                        handleFindMyCarRoute={handleFindMyCarRoute}
                        handleFindMyBikeRoute={handleFindMyBikeRoute}
                        component={
                          <ModalInp
                            clickEvent={handleRouteBike}
                            vehicleNumber={vehicleNumber}
                            errors={errors}
                            businessType="rollover"
                            vehicleType="2W"
                          />
                        }
                      />
                      {/* <ButtonLoader /> */}
                      {/* <Botton
                      text="Renew Existing Policy"
                      event={(e) => console.log("RenewBtn CLicked")}
                      className="button GetRegNo_btn secondry_btn "
                    /> */}
                    </div>
                    <div className="input_container_content">
                      <p className="small_text">I bought a new bike.</p>
                      <p
                        className="footer_hyper_link underline_text "
                        onClick={handleNewBikeRoute}
                      >
                        Check my Premium.
                      </p>
                    </div>
                  </div>
                </form>
                {/* ------------- */}

                {/* SHOW IN MOBILE */}

                <div className="getRegNo_form_inp_parts hideInDesktop ">
                  <div className="input_container inp-container-mobile ">
                    <InputFields
                      type="text"
                      placeholder="My bike number (MH 01 AB 1234)"
                      className="inputs-form"
                      errClassName="input-err"
                      errState={RegNumberErr}
                      errMsg="Please enter your bike registration number."
                      onChangeEvent={(e) => handleVehicleNumber(e.target.value)}
                      value={vehicleNumber}
                    />
                  </div>
                  <div className="input_container input_container_btn ">
                    <PopUp
                      text="Check Premium"
                      className="button GetRegNo_btn"
                      leftClassOne="content_left_modal"
                      leftClassTwo="content_left_modal_head"
                      leftClassThree="content_left_modal_desc"
                      name="getregnumberpopup"
                      leftSideTitle="Your Bike Details"
                      leftSideData={leftContent}
                      vehicleDetails={vehicleDetails}
                      bottomContentLeft={bottomContentBike}
                      // rightSideHead={rightHeading}
                      vehicleNumber={vehicleNumber}
                      renderContent="policyDetails"
                      clickEvent={handleRouteBike}
                      setRegNumberErr={setRegNumberErr}
                      businessType="rollover"
                      errors={errors}
                      setLoading={setLoading}
                      loading={loading}
                      mobileCLickEvent={mobileCLickEvent}
                      setOpenCarDetailsPopUp={setOpenCarDetailsPopUp}
                      vehicleType="2W"
                      dealerDetails={dealerDetails}
                      handleFindMyCarRoute={handleFindMyCarRoute}
                      handleFindMyBikeRoute={handleFindMyBikeRoute}
                      component={
                        <ModalInp
                          clickEvent={handleRouteBike}
                          vehicleNumber={vehicleNumber}
                          businessType="rollover"
                          errors={errors}
                          loading={loading}
                          vehicleType="2W"
                        />
                      }
                    />
                    {/* <Botton
                      text="Renew Existing Policy"
                      event={(e) => console.log("RenewBtn CLicked")}
                      className="button GetRegNo_btn secondry_btn "
                    /> */}
                  </div>
                </div>

                <div className="phone-tabs-block hideInDesktop ">
                  <p>or</p>
                  {
                    bikeTab.map((item, key) => (
                      <div key={key} className="phone-redirect-tab" onClick={item.event} >
                        <p>{item.heading}</p>
                        <p>{item.tabHead} <img src={rightArrow} /> </p>
                      </div>
                    ))
                  }
                </div>

                {/* <div className="getRegNo_form_inp_parts hideInDesktop ">
                  <div className="input_container_content">
                    <p className="small_text flex-text ">
                      Don't know my bike number.{" "}
                      <span
                        className="footer_hyper_link underline_text "
                        onClick={handleFindMyBikeRoute}
                      >
                        Find my Bike details.
                      </span>{" "}
                    </p>
                  </div>
                  <div className="input_container_content">
                    <p className="small_text">I bought a new bike.</p>
                    <p
                      className="footer_hyper_link underline_text "
                      onClick={handleNewBikeRoute}
                    >
                      Check my Premium.
                    </p>
                  </div>
                </div> */}

                {/* ------------ */}

                <div className="bottom_getRegno_  bottom_getRegno_mobile">
                  <p className="small_text  small_text_flex">
                    I need help.
                    <span className="footer_hyper_link underline_text ">
                      Request a call from Expert.
                    </span>
                  </p>
                  <p className="small_text  small_text_flex">
                    OR Call us.
                    <a href={dealerDetails?.mobile_no ? `tel:${dealerDetails?.mobile_no}` : "tel:9811223458"} className="footer_hyper_link underline_text ">
                      {dealerDetails?.mobile_no ? dealerDetails?.mobile_no : "9811223458"}
                    </a>
                  </p>
                </div>
              </div>
            </div>
            <div className="getRegNo_form_part getRegNo_form_part_two hideInMobile ">
              <div className="irdai_container">
                <div className="irdai_container_head showInMobileIrdai ">
                  <img src={irdai} alt="" srcSet="" />
                  <p className="small_text irdai_small_text">
                    Licensed approved by IRDAI Direct Broker Licence No. 762
                  </p>
                </div>
                <div className="getRegNo_highlight_container">
                  <div className="getRegNo_highlight_container_part">
                    {irdaiContentOne?.map((index, key) => (
                      <div key={key} className="highlight_container_part_child">
                        <p className="numbers_getregno">
                          {index.number}
                          <span className="numbers_getregno_blue">
                            {index.inSpan}
                          </span>
                        </p>
                        <p className="small_text irdai_small_text">
                          {index.description}
                        </p>
                      </div>
                    ))}
                  </div>
                  <div className="getRegNo_highlight_container_part">
                    {irdaiContentTwo?.map((index, key) => (
                      <div key={key} className="highlight_container_part_child">
                        <p className="numbers_getregno">
                          {index.number}
                          <span className="numbers_getregno_blue">
                            {index.inSpan}
                            {key === 1 ? (
                              <> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </>
                            ) : (
                              ""
                            )}
                          </span>
                        </p>
                        <p className="small_text irdai_small_text">
                          {index.description}
                        </p>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
        }
      </div >
    </>
  );
};

export default FormContainer;
