import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Link, useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { closestMatch, distance } from "closest-match";
import caution from "../../Assets/Images/cautionImg.svg"
import "./ProposalForm.css";
import ProposalOne from "./ProposalOne";
import DealerPanKyc from "./DealerPanKyc.jsx";
import InputFields from "../InputFields/InputFields";
import ProposalTwo from "./ProposalTwo";
import PropsalThree from "./PropsalThree";
import PropsalFour from "./PropsalFour";
import PremiumContainer from "./PremiumContainer";
import PremiumContainerBike from "./PremiumContainerBike";
import Botton from "../Button/Button";
import KycForm from "./KycForm";
import Stepper from "../Stepper/Stepper";
import Collapsible from "../Collapsible/Collapsible";
import { paymentWebhookAction, paymentSendURLAction } from "../../redux/Actions/paymentWebhookAction";
import ButtonLoader from "../Pages/Loader/Button Loader/ButtonLoader";
import { getCompanyName } from "../../redux/Reducers/companyNameReducer";
import { alladdOnDataDataAction } from "../../redux/Reducers/addOnsDataReducer";
import { transactionURLAction } from "../../redux/Actions/TransactionAction";
import { previousfilenameAction } from "../../redux/Reducers/bodyDataReducer.js"
import shareLink from "../../../src/Assets/Images/shareLink.svg";
import confrmationShareLink from "../../../src/Assets/Images/confrmationShareLink.svg"
import { restTransactionReducer } from "../../redux/Reducers/transactionReducer.js";
import { phonevalidationAction } from "../../redux/Actions/shareLinkAction.js";
// import { NEW, carDetailsforProposal } from "../../utils/commonUtilsFinal";
import {
  sbiKyc,
  sbiKycAuto,
  sbiFullQuoteAction,
  sbiPolicyGenerateAction,
} from "../../redux/Actions/sbiApiAction";
import {
  iciciKyc,
  iciciKycAuto,
  iciciQuickQuoteAction,
  iciciQuickQuoteActionWithAddon
} from "../../redux/Actions/iciciApiAction";
import {
  // hdfcKyc,
  hdfcKycAuto,
  hdfcQuickQuoteAction,
} from "../../redux/Actions/hdfcApiAction";
import {
  hdfcKycAutoReducer,
  addhdfcAutoKycDataAction
} from "../../redux/Reducers/hdfcApiReducer";
import {
  addManualKycDataAction,
  addAutoKycDataAction
} from "../../redux/Reducers/iciciApiReducer";
import { allBodyDataAction } from "../../redux/Reducers/bodyDataReducer";
import { shareLinkAction } from "../../redux/Actions/shareLinkAction.js";
import {
  carDetailsforProposal,
  sbiAutoKycDataMethod,
  rtoState,
  ICICI,
  SBI,
  ACKO,
  SHREERAM,
  HDFC,
  DIGIT,
  ZUNO,
  TATA,
  UNITED
} from "../../utils/commonUtilsFinal.js";
import {
  getStateAction,
  getCityAction,
  getInsurerList,
} from "../../redux/Actions/stateDetails";
import Inspection from "./Inspection";
import { sbiQuickQuoteAction } from "../../redux/Actions/sbiApiAction";
import { shreeRamQuickQuoteAction } from "../../redux/Actions/shreeRamApiAction";
import { digitQuickQuoteAction } from "../../redux/Actions/digitApiAction";
// zuno
import {
  zunoQuickQuoteAction,
  zunoKycCustomerAction,
  zunoManualKycAction,
  zunoInsertBodydAction,
  zunoQuickQuoteActionWithAddOn
} from "../../redux/Actions/zunoApiAction";
import {
  resetManualKycQuoteAction,
  addZunoAutoKycDataAction,
  zunoKycCustomerReducer
} from "../../redux/Reducers/zunoApiReducer";
// tata
import {
  tataAutoKycAction,
  tataManualKycAction,
  tataProposalAction,
  tataQuickQuoteAction,
  tataOtpVerifyAction,
  tataOnlinePaymentAction
} from "../../redux/Actions/tataApiAction";
import {
  resettataManualKycAction,
  tataAutoKycReducer,
  tataProposalReducer,
} from "../../redux/Reducers/tataApiReducer";
// united
import {
  unitedQuickQuoteAction,
  unitedAutoKycAction
} from "../../redux/Actions/unitedApiAction";
import {
  unitedAutoKycReducer
} from "../../redux/Reducers/unitedApiReducer";
import loader2 from "../../Assets/Loader/Loader.gif";
import Navbar from "../Navbar/Navbar";
import backIcon from "../../Assets/Images/backIcon.svg";
import { digitQuickQuoteActionWithAddon } from "../../redux/Actions/digitApiAction";
import { ackoQuickQuoteAction, ackocheckPaymentStatusAction, ackoGetLeadsAction } from "../../redux/Actions/ackoApiAction";
import Modal from "react-modal";
import OtpModal from "../Modal/OtpModal";
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

const ProposalForm = (props) => {
  let bodyData = useSelector((state) => {
    return state.bodyData;
  });
  let DuplicateMobileEmail = localStorage.getItem('MobileExist')

  let ShreLinkData = useSelector((state) => {
    return state.shareLink;
  });

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const InspectionStatus = useSelector((state) => {
    return state.inspectDetails?.data?.isInspectionReq;
  });
  const customStylesForMobile = {
    content: {
      top: "auto",
      left: "0",
      right: "0",
      bottom: "0",
      margin: "0",
      transform: "none",
      borderRadius: "10px 10px 0 0",
      padding: "20px",
      height: "auto",
    },
  };
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };

  const {
    insurerLogo,
    bottomFooter,
    collapsibleTitle,
    headerCollapsible,
  } = props;
  let dealerCode = localStorage.getItem('dealer_code')
  const CompanyName = useSelector((state) => {
    return state.companyName?.data?.companyName;
  });
  const search = useLocation().search;
  const id = new URLSearchParams(search).get("id");
  const transactionId = new URLSearchParams(search).get("transaction_id");
  const zunoKycId = new URLSearchParams(search).get("kId");
  const [StepperValue, setStepperValue] = useState(1);
  const [ShowKycInputs, setShowKycInputs] = useState(false);
  const [ShowForm, setShowForm] = useState(dealerCode && CompanyName == "acko" ? "dealerKyc" : "ownerDetail"); //FormDetails kycDetails ownerDetail PersonalDetails AddressDetails VehicleDetails
  const [ShowCityDropdown, setShowCityDropdown] = useState(false);
  // make it true when auto kyc rejected
  const [AadharImgName, setAadharImgName] = useState("");
  const [AadharImgBackName, setAadharImgBackName] = useState("");
  const [PanImgName, setPanImgName] = useState("");
  const [CINImgName, setCINImgName] = useState("");
  const [ProfileImgName, setProfileImgName] = useState("");
  const [isAutoKyc, setIsAutoKyc] = useState(true); // todo - true
  const [isKycSuccess, setIsKycSuccess] = useState(false);
  const [Edit, setEdit] = React.useState(false);
  const [isDisabledButton, setIsDisabledButton] = useState(false);
  const [errors, setErrors] = useState({});
  const [errors2, setErrors2] = useState({});
  const [Image, setImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const [triggerApi, setTriggerApi] = useState(true);
  const [DobClone, setDobClone] = useState(null);
  // const [DobClone, setDobClone] = useState(null);
  const [ShowDobCalendar, setShowDobCalendar] = useState(false)
  const [StoreTataOtp, setStoreTataOtp] = useState(null);
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [ValidatePersonalDetails, setValidatePersonalDetails] = useState(false);
  const [validDatEditProposalform, setValidDatEditProposalform] = useState(false)
  const [carNoPopup, setCarNopopup] = useState(false)
  const [addonsforIcici, setaddonsforIcici] = useState(null);
  const [newTest, setNewTest] = useState(null)
  const [newTestDigit, setnewTestDigit] = useState(null)
  const [modalIsOpenfail, setmodalIsOpenfail] = useState(false)
  const [modalIsOpenAdhar, setmodalIsOpenAdhar] = useState(false)
  const [modalIsOpens, setmodalIsOpen] = useState(false)
  const [modalIsOpenfailAdhar, setmodalIsOpenfailAdhar] = useState(false)
  const [failCheker, setFailCheker] = useState(false)
  const [shreeRamKyc, setShreeRamKyc] = useState(false)
  const [shreeRamKycCorp, setShreeRamKyccorp] = useState(false)
  const [ShowSuccesMsg, setShowSuccesMsg] = useState(false);
  const [ShowPaymentLink, setShowPaymentLink] = useState(false);
  const [checkphone, setcheckphone] = useState(false)
  const [checEmail, setcheckEmail] = useState(false)
  const [phonestatussloading, SetPhonestatussloading] = useState(false)
  const [panDobConataier, setpanDobConataier] = useState(localStorage.getItem('dealer_code'))
  const [panDobValue, setPanDobValue] = useState({
    pan: "",
    dob: ""
  })
  const [Day, setDay] = useState(null);
  const [Month, setMonth] = useState(null);
  const [ShowKYClist, setShowKYClist] = useState(false);
  const [Year, setYear] = useState(null);

  function openModal() {
    setIsOpen(true);
  }
  console.log(ShowForm, "ShowFormShowForm");

  function closeModal(otp) {
    // console.log(otp, "otpotp");
    if (otp?.length == 6) {
      setIsOpen(false);
    }
  }
  const phonevalidationFlag = useSelector((state) => {
    return state.isPhoneNumberValid
  })
  useEffect(() => {
    setcheckphone(phonevalidationFlag?.data?.data?.multipleNumer)

  }, [])
  useEffect(() => {
    if (bodyData?.data?.PolicyTypeValue !== "Bundled" && bodyData?.data?.typeOfBusiness !== "New" && bodyData?.data?.vehicleNumber?.length < 9) {
      setCarNopopup(true)
      setTimeout(() => {
        navigate("/View-Plans?id=" + id);
      }, 4000)
    }
  }, [bodyData?.data?.vehicleNumber])
  const HnaldeCloseNotFoundQouetModal = () => {
    closePaaymentLinkModal();
  }
  // getting selected product
  const location = useLocation();
  let allCarDetails = location?.state?.allCarDetails;
  let vehicleType = allCarDetails?.vehicleType;
  let CompanyImage = location?.state?.logo;
  const fullUrl = `${window.location.protocol}//${window.location.host}/#${location.pathname}?id=${id}`;



  const [AutoKYCrejected, setAutoKYCrejected] = useState(
    CompanyName == "sbi" || CompanyName == "icici" || CompanyName == "digit" || CompanyName == "zuno"
      || CompanyName == "acko" || CompanyName == "hdfc" || CompanyName == "tata" || CompanyName == "united"
      ? false
      : true
  ); //true

  let kycAutoData = useSelector((state) => {
    return state.abiKycAutoData;
  });

  let iciciKycAutoData = useSelector((state) => {
    return state.iciciKycAutoData;
  });

  let hdfcKycAutoData = useSelector((state) => {
    return state.hdfcKycAutoData;
  });

  const [hdfcKycData, sethdfcKycData] = useState("");

  let kycManualData = useSelector((state) => {
    return state.sbiKycData;
  });

  let iciciKycManualData = useSelector((state) => {
    return state.iciciKycData;
  });
  // united
  const unitedAutoKyc = useSelector((state) => {
    return state?.unitedAutoKyc;
  });

  let sbiFullQuoteData = useSelector((store) => {
    return store?.sbiFullQuote;
  });

  let iciciFullQuoteData = useSelector((store) => {
    return store?.iciciProposal;
  });

  let hdfcFullQuoteData = useSelector((store) => {
    return store?.hdfcProposal;
  });

  const getPreviousAddonData = useSelector((state) => {
    return state?.addOnData?.data;
  });

  const sbiQuickQuoteData = useSelector((state) => {
    return state?.sbiQuickQuote;
  });

  const iciciQuickQuoteData = useSelector((state) => {
    return state?.iciciQuickQuote;
  });

  const hdfcQuickQuoteData = useSelector((state) => {
    return state?.hdfcQuickQuote;
  });

  // Zuno auto & manual
  let zunoKycAutoData = useSelector((state) => {
    return state.zunoKyc;
  });

  let zunoKycManualData = useSelector((state) => {
    return state.zunoManualKyc;
  });

  // tata api
  const tataQuickQuote = useSelector((state) => {
    return state?.tataQuickQuote;
  });

  const tataProposalRes = useSelector((state) => {
    return state?.tataProposalQuote;
  });

  const tataAutoKyc = useSelector((state) => {
    return state?.tataAutoKyc;
  });

  // shreRam quote response
  const shreeRamQuote = useSelector((state) => {
    return state?.shreeRamQuickQuote;
  });

  const digitQuote = useSelector((state) => {
    return state?.digitQuickQuote;
  });

  const iciciQuote = useSelector((state) => {
    return state?.iciciQuickQuote;
  });

  const hdfcQuote = useSelector((state) => {
    return state?.hdfcQuickQuote;
  });

  const ackoQuote = useSelector((state) => {
    return state?.ackoQuickQuote;
  });

  // ZUNO
  const zunoQQuote = useSelector((state) => {
    return state?.zunoQuickQuote;
  });

  // TATA
  const tataQQuote = useSelector((state) => {
    return state?.tataQuickQuote;
  });

  let quoteData = {};

  if (CompanyName == SBI) {
    // companyImg = sbi
    quoteData = sbiQuickQuoteData;
  } else if (CompanyName == SHREERAM) {
    // companyImg = shreeRam
    quoteData = shreeRamQuote;
  } else if (CompanyName == DIGIT) {
    // companyImg = digit
    quoteData = digitQuote;
  } else if (CompanyName == ICICI) {
    // companyImg = icici
    quoteData = iciciQuote
  } else if (CompanyName == HDFC) {
    // companyImg = hdfc
    quoteData = hdfcQuote
  } else if (CompanyName == ACKO) {
    // companyImg = acko
    quoteData = ackoQuote
  } else if (CompanyName == ZUNO) {
    // companyImg = zuno
    quoteData = zunoQQuote
  } else if (CompanyName == TATA) {
    // companyImg = tata
    quoteData = tataQQuote?.data?.data?.quote
  }

  let test = [];
  let dealer_code = localStorage.getItem('dealer_code')

  let vehicleStructureDetails = carDetailsforProposal(
    CompanyName,
    quoteData,
    bodyData,
    test,
  );

  useEffect(() => {
    const asyncFn = async () => {
      if (bodyData?.data?.vehicleNumber?.length < 9) {
        setLoading(false);
      } else {
        setLoading(true);
      }

      if (transactionId) {
        //call api to get data 
        let bodyData = {
          transactionId: transactionId,
          status: "getpaymentdata",
        };
        let payResp = await dispatch(paymentSendURLAction(bodyData));
        if (payResp?.payload?.data?.paymentinfo[0].status == "success" &&
          payResp?.payload?.data?.paymentinfo[0].policy_number) {
          let { company_name, policy_transaction_id, policy_number } = payResp?.payload?.data?.paymentinfo[0];
          if (company_name == ACKO) {
            navigate(`/Payment-Redirect?companyName=${company_name}&policy_number=${policy_number}&proposal_id=${policy_transaction_id}`)
          } else {
            navigate(
              `/Payment-Redirect?companyName=${company_name}&transaction_id=${policy_transaction_id}&PolicyNumber=${policy_number}`
            );
          }
        } else {
          let bodyDataRes = JSON.parse(payResp?.payload?.data?.paymentinfo[0].body_data)
          mappingAllData[0] = bodyDataRes.data;
          await dispatch(alladdOnDataDataAction({
            ...bodyDataRes.data,
            ...{
              CertificateOfIncorporationFile: payResp?.payload?.data?.paymentinfo[0].cin_file,
              kycProfileImg: payResp?.payload?.data?.paymentinfo[0].profile_img_file,
              kycAadharImg: payResp?.payload?.data?.paymentinfo[0].aadhar_file,
              kycAadharImgBack: payResp?.payload?.data?.paymentinfo[0].aadhar_back_file,
              kycPanImg: payResp?.payload?.data?.paymentinfo[0].pan_file,
            }
          }));
          await dispatch(
            allBodyDataAction({
              ...bodyDataRes.data,
              ...{
                CertificateOfIncorporationFile: payResp?.payload?.data?.paymentinfo[0].cin_file,
                kycProfileImg: payResp?.payload?.data?.paymentinfo[0].profile_img_file,
                kycAadharImg: payResp?.payload?.data?.paymentinfo[0].aadhar_file,
                kycAadharImgBack: payResp?.payload?.data?.paymentinfo[0].aadhar_back_file,
                kycPanImg: payResp?.payload?.data?.paymentinfo[0].pan_file,
              }
            })
          );
          setFormData({
            ...bodyDataRes.data,
            ...{
              CertificateOfIncorporationFile: payResp?.payload?.data?.paymentinfo[0].cin_file,
              kycProfileImg: payResp?.payload?.data?.paymentinfo[0].profile_img_file,
              kycAadharImg: payResp?.payload?.data?.paymentinfo[0].aadhar_file,
              kycAadharImgBack: payResp?.payload?.data?.paymentinfo[0].aadhar_back_file,
              kycPanImg: payResp?.payload?.data?.paymentinfo[0].pan_file,
            }
          })
          await dispatch(getCompanyName({
            companyName: payResp?.payload?.data?.paymentinfo[0].company_name
          }))
          if (payResp?.payload?.data?.paymentinfo[0].company_name == "sbi") {
            if (bodyDataRes.data?.carOwner?.toUpperCase() === "isCompany".toUpperCase()) {
              setCoverages([bodyDataRes.data?.coverage[0]]);
            } else {
              setCoverages(bodyDataRes.data?.coverage[0]);
            }
            await dispatch(
              sbiQuickQuoteAction({

                data: {
                  ...bodyDataRes.data
                },
              })
            );
          } else if (payResp?.payload?.data?.paymentinfo[0].company_name == "icici") {
            if (bodyDataRes.data?.carOwner?.toUpperCase() === "isCompany".toUpperCase()) {
              setCoverages([bodyDataRes.data?.coverage[0]]);
            } else {
              setCoverages(bodyDataRes.data?.coverage[0]);
            }
            await dispatch(
              iciciQuickQuoteAction({
                data: {
                  ...bodyDataRes.data
                },
              })
            );
            if (payResp?.payload?.data?.paymentinfo[0].kyc_data) {
              let kycData = JSON.parse(payResp?.payload?.data?.paymentinfo[0].kyc_data);
              await dispatch(
                addAutoKycDataAction({
                  ...kycData.iciciKycAutoData.data
                })
              );
              await dispatch(
                addManualKycDataAction({
                  ...kycData.iciciKycManualData.data
                })
              );
            }
          } else if (payResp?.payload?.data?.paymentinfo[0].company_name == "hdfc") {
            if (bodyDataRes.data?.carOwner?.toUpperCase() === "isCompany".toUpperCase()) {
              setCoverages([bodyDataRes.data?.coverage[0]]);
            } else {
              setCoverages(bodyDataRes.data?.coverage[0]);
            }
            await dispatch(
              hdfcQuickQuoteAction({
                data: {
                  ...bodyDataRes.data
                },
              })
            );
            if (payResp?.payload?.data?.paymentinfo[0].kyc_data) {
              let kycData = JSON.parse(payResp?.payload?.data?.paymentinfo[0].kyc_data);
              await dispatch(
                addAutoKycDataAction({
                  ...kycData.hdfcKycAutoData.data
                })
              );
            }

          } else if (payResp?.payload?.data?.paymentinfo[0].company_name == "digit") {
            if (bodyDataRes.data?.carOwner?.toUpperCase() === "isCompany".toUpperCase()) {
              setCoverages([bodyDataRes.data?.coverage[0]]);
            } else {
              setCoverages(bodyDataRes.data?.coverage[0]);
            }
            await dispatch(
              digitQuickQuoteAction({
                data: {
                  ...bodyDataRes.data
                },
              })
            );
          } else if (payResp?.payload?.data?.paymentinfo[0].company_name == "acko") {
            if (bodyDataRes.data?.carOwner?.toUpperCase() === "isCompany".toUpperCase()) {
              setCoverages([bodyDataRes.data?.coverage[0]]);
            } else {
              setCoverages(bodyDataRes.data?.coverage[0]);
            }
            await dispatch(
              ackoQuickQuoteAction({
                data: {
                  ...bodyDataRes.data,
                  ...{ coverage: [bodyDataRes.data?.coverage[0]] },
                },
              })
            );
          } else if (payResp?.payload?.data?.paymentinfo[0].company_name == "shreeram") {

            if (bodyDataRes.data?.carOwner?.toUpperCase() === "isCompany".toUpperCase()) {
              setCoverages([bodyDataRes.data?.coverage[0]]);
            } else {
              setCoverages(bodyDataRes.data?.coverage[0]);
            }
            await dispatch(
              shreeRamQuickQuoteAction({
                data: {
                  ...bodyDataRes.data,
                  ...{
                    CertificateOfIncorporationFile: payResp?.payload?.data?.paymentinfo[0].cin_file,
                    kycProfileImg: payResp?.payload?.data?.paymentinfo[0].profile_img_file,
                    kycAadharImg: payResp?.payload?.data?.paymentinfo[0].aadhar_file,
                    kycAadharImgBack: payResp?.payload?.data?.paymentinfo[0].aadhar_back_file,
                    kycPanImg: payResp?.payload?.data?.paymentinfo[0].pan_file,
                  }

                },
              })
            );
          }
          setShowForm("FormDetails");
          setLoading(false);
        }
        return () => { }
      }
      // else if (zunoKycId) { // Zuno zunoKycId after online manual kyc
      //   // get body data 
      //   let zunoData = {
      //     kycId: zunoKycId,
      //     type: "get",
      //   };
      //   let kycDataRes = await dispatch(zunoInsertBodydAction(zunoData));

      //   let bodyDataAllRes = JSON.parse(kycDataRes?.payload?.data?.paymentinfo[0].bodyData)
      //   let bodyDataRes = bodyDataAllRes?.zunoBody
      //   await dispatch(
      //     allBodyDataAction({
      //       ...bodyDataRes.data
      //     })
      //   );
      //   await dispatch(
      //     zunoQuickQuoteAction({
      //       data: {
      //         ...bodyDataRes.data
      //       },
      //     })
      //   );
      //   await dispatch(getCompanyName({
      //     companyName: "zuno"
      //   }))
      //   setFormData(bodyDataRes.data)
      //   setShowForm("FormDetails");
      //   setLoading(false);
      //   return () => { }
      // } 
      else if (id) {
        let data = {
          uuid: id,
          type: "fetch"
        };

        let resp = await dispatch(transactionURLAction(data));

        if (Array.isArray(resp?.payload?.data?.txninfo)) {
          if (resp?.payload?.data?.txninfo[0].body_data &&
            resp?.payload?.data?.txninfo[0].company_name) {
            if (resp?.payload?.data?.txninfo[0].status == "success" &&
              resp?.payload?.data?.txninfo[0].policy_number) {
              let { company_name, policy_transaction_id, policy_number } = resp?.payload?.data?.txninfo[0];
              if (company_name == ACKO) {
                navigate(`/Payment-Redirect?companyName=${company_name}&policy_number=${policy_number}&proposal_id=${policy_transaction_id}`)
              } else if (company_name == SHREERAM) {
                //call payment api
                let statusData = {
                  transactionid: policy_transaction_id,
                  type: "getpaymentdata",
                };
                const paymentResponse = await dispatch(
                  paymentWebhookAction(statusData)
                );
                const { paymentinfo } = paymentResponse?.payload?.data;
                const dataObject = JSON.parse(paymentinfo[0]?.payment_response_data);
                navigate(`/Payment-Redirect?companyName=shreeram&policyURL=${encodeURIComponent(dataObject.PolicyURL)}&PolicyNumber=${dataObject.PolicyNumber}&ResponseCode=${dataObject.ResponseCode}&ProposalNumber=${dataObject.ProposalNumber}&transaction_id=${dataObject.transaction_id}`)
              } else if (company_name == DIGIT) {
                //call payment api
                let statusData = {
                  transactionid: policy_number,
                  type: "getpaymentdata",
                };
                const paymentResponse = await dispatch(
                  paymentWebhookAction(statusData)
                );
                const { paymentinfo } = paymentResponse?.payload?.data;
                const dataObject = JSON.parse(paymentinfo[0]?.body_data);
                navigate(`/Payment-Redirect?companyName=digit&PolicyNumber=${dataObject?.digit?.quote?.['Motor-Create Quote']?.policyNumber}&appID=${dataObject?.digit?.quote?.['Motor-Create Quote']?.applicationId}&transactionNumber=${dataObject?.digit?.quote?.['Motor-Create Quote']?.enquiryId}`)
              } else {
                navigate(
                  `/Payment-Redirect?companyName=${company_name}&transaction_id=${policy_transaction_id}&PolicyNumber=${policy_number}`
                );
              }
            } else {

              let bodyDataRes = JSON.parse(resp?.payload?.data?.txninfo[0]?.body_data)
              let formDataRes = JSON.parse(resp?.payload?.data?.txninfo[0]?.form_data)
              let carDets = JSON.parse(resp?.payload?.data?.txninfo[0]?.car_details_obj)
              setmobileNo(carDets?.mobileNo)
              setpincode(carDets?.pincode)

              let leadsData = {
                address_line_1: "",
                address_line_2: "",
                chassis_no: "",
                engine_no: "",
                rto_state: "",
                policy_holder_name: "",
                policy_number: "",
                insurer: "",
                firstName: "",
                lastName: "",
                email: ""
              }
              if (carDets?.vehicleNumber?.length > 4) {
                let getAckoLeadsDetails = await dispatch(ackoGetLeadsAction({
                  regNo: carDets?.vehicleNumber
                }));
                if (getAckoLeadsDetails?.payload?.data.length) {
                  let name = getAckoLeadsDetails?.payload?.data[getAckoLeadsDetails.payload.data.length - 1]?.policy_holder_name.split(" ");
                  let lastName = name.pop();
                  let firstName = name.join(" ");
                  leadsData = {
                    address_line_1: getAckoLeadsDetails?.payload?.data[getAckoLeadsDetails.payload.data.length - 1]?.address_line_1,
                    address_line_2: getAckoLeadsDetails?.payload?.data[getAckoLeadsDetails.payload.data.length - 1]?.address_line_2,
                    chassis_no: getAckoLeadsDetails?.payload?.data[getAckoLeadsDetails.payload.data.length - 1]?.chassis_no,
                    engine_no: getAckoLeadsDetails?.payload?.data[getAckoLeadsDetails.payload.data.length - 1]?.engine_no,
                    rto_state: getAckoLeadsDetails?.payload?.data[getAckoLeadsDetails.payload.data.length - 1]?.rto_state?.toUpperCase(),
                    policy_holder_name: getAckoLeadsDetails?.payload?.data[getAckoLeadsDetails.payload.data.length - 1]?.policy_holder_name,
                    policy_number: getAckoLeadsDetails?.payload?.data[getAckoLeadsDetails.payload.data.length - 1]?.policy_number,
                    insurer: getAckoLeadsDetails?.payload?.data[getAckoLeadsDetails.payload.data.length - 1]?.insurer,
                    email: getAckoLeadsDetails?.payload?.data[getAckoLeadsDetails.payload.data.length - 1]?.email,
                    firstName: firstName || "",
                    lastName: lastName || "",
                  }
                }
              }
              if (!resp?.payload?.data?.txninfo[0].form_data) {
                setFormData({
                  ...propsalData,
                  ... {
                    addressOne: leadsData?.address_line_1,
                    addressTwo: leadsData?.address_line_2,
                    chassisNumber: leadsData?.chassis_no,
                    engineNumber: leadsData?.engine_no,
                    state: leadsData?.rto_state,
                    prevPolicyNumber: leadsData?.policy_number,
                    prevPolicyNameInsurer: leadsData?.insurer,
                    firstName: leadsData?.firstName || "",
                    lastName: leadsData?.lastName || "",
                    email: leadsData?.email
                  }
                })
              } else {
                setFormData({
                  ...formDataRes,
                  ... { mobileNo: carDets.mobileNo },
                  ...{ coverage: [bodyDataRes?.coverage[0]] },
                  ...{
                    CertificateOfIncorporationFile: resp?.payload?.data?.txninfo[0].cin_file,
                    kycProfileImg: resp?.payload?.data?.txninfo[0].profile_img_file,
                    kycAadharImg: resp?.payload?.data?.txninfo[0].aadhar_file,
                    kycAadharImgBack: resp?.payload?.data?.txninfo[0].aadhar_back_file,
                    kycPanImg: resp?.payload?.data?.txninfo[0].pan_file,
                    mobileNo: carDets.mobileNo,
                    addressOne: formDataRes?.addressOne || leadsData?.address_line_1,
                    addressTwo: formDataRes?.addressTwo || leadsData?.address_line_2,
                    chassisNumber: formDataRes?.chassisNumber || leadsData?.chassis_no,
                    engineNumber: formDataRes?.engineNumber || leadsData?.engine_no,
                    state: formDataRes?.state || leadsData?.rto_state,
                    prevPolicyNumber: formDataRes?.prevPolicyNumber || leadsData?.policy_number,
                    prevPolicyNameInsurer: formDataRes?.prevPolicyNameInsurer || leadsData?.insurer,
                    firstName: formDataRes?.firstName || leadsData?.firstName,
                    lastName: formDataRes?.lastName || leadsData?.lastName,
                    email: formDataRes?.email || leadsData?.email,
                  }
                })
              }

              await dispatch(allBodyDataAction({
                ...bodyDataRes,
                ...{ coverage: [bodyDataRes?.coverage[0]] },
                ...{
                  CertificateOfIncorporationFile: resp?.payload?.data?.txninfo[0].cin_file,
                  kycProfileImg: resp?.payload?.data?.txninfo[0].profile_img_file,
                  kycAadharImg: resp?.payload?.data?.txninfo[0].aadhar_file,
                  kycAadharImgBack: resp?.payload?.data?.txninfo[0].aadhar_back_file,
                  kycPanImg: resp?.payload?.data?.txninfo[0].pan_file,
                  mobileNo: carDets.mobileNo,
                }
              }));
              await dispatch(alladdOnDataDataAction({
                ...bodyDataRes,
                ...{ coverage: [bodyDataRes?.coverage[0]] },
                ...{
                  CertificateOfIncorporationFile: resp?.payload?.data?.txninfo[0].cin_file,
                  kycProfileImg: resp?.payload?.data?.txninfo[0].profile_img_file,
                  kycAadharImg: resp?.payload?.data?.txninfo[0].aadhar_file,
                  kycAadharImgBack: resp?.payload?.data?.txninfo[0].aadhar_back_file,
                  kycPanImg: resp?.payload?.data?.txninfo[0].pan_file,
                  mobileNo: carDets.mobileNo
                }
              }));
              mappingAllData[0] = {
                ...bodyDataRes,
                ... { mobileNo: carDets.mobileNo },
                ...{ coverage: [bodyDataRes?.coverage[0]] },
                ...{
                  CertificateOfIncorporationFile: resp?.payload?.data?.txninfo[0].cin_file,
                  kycProfileImg: resp?.payload?.data?.txninfo[0].profile_img_file,
                  kycAadharImg: resp?.payload?.data?.txninfo[0].aadhar_file,
                  kycAadharImgBack: resp?.payload?.data?.txninfo[0].aadhar_back_file,
                  kycPanImg: resp?.payload?.data?.txninfo[0].pan_file,
                  mobileNo: carDets.mobileNo
                }
              };
              await dispatch(getCompanyName({
                companyName: resp?.payload?.data?.txninfo[0].company_name
              }))
              setNewTest([bodyDataRes?.NewcoveragePlan])
              setnewTestDigit(bodyDataRes?.NewcoveragePlanDig)
              let compname = resp?.payload?.data?.txninfo[0].company_name;

              let kycDataRes = ''// for zuno online manual kyc
              if (compname == "sbi") {
                setCoverages([bodyDataRes.coverage[0]]);
                // if (bodyDataRes.carOwner?.toUpperCase() === "isCompany".toUpperCase()) {
                //   setCoverages([bodyDataRes.coverage[0]]);
                // } else {
                //   setCoverages([bodyDataRes?.coverage[0]]);
                // }
                await dispatch(
                  sbiQuickQuoteAction({
                    data: {
                      ...bodyDataRes,
                      ...{ coverage: [bodyDataRes?.coverage[0]] },
                    },
                  })
                );
              } else if (compname == "icici") {
                setCoverages(bodyDataRes?.quotePackageNameICICI ? bodyDataRes?.NewcoveragePlan ? [bodyDataRes?.NewcoveragePlan[0]] : [bodyDataRes?.coverage[0]] : [bodyDataRes?.coverage[0]]);

                // if (bodyDataRes.carOwner?.toUpperCase() === "isCompany".toUpperCase()) {

                //   setCoverages([bodyDataRes.coverage[0]]);
                // } else {
                //   setCoverages([bodyDataRes?.coverage[0]]);
                // }

                await dispatch(
                  iciciQuickQuoteAction({
                    data: {
                      ...bodyDataRes,
                      ...{ coverage: bodyDataRes?.quotePackageNameICICI ? bodyDataRes?.NewcoveragePlan ? [bodyDataRes?.NewcoveragePlan[0]] : [bodyDataRes?.coverage[0]] : [bodyDataRes?.coverage[0]] },
                    },
                  })
                );
                if (resp?.payload?.data?.txninfo[0].kyc_auto_data) {
                  let kycData = JSON.parse(resp?.payload?.data?.txninfo[0].kyc_auto_data);
                  await dispatch(
                    addAutoKycDataAction({
                      ...kycData.data
                    })
                  )
                }
                if (resp?.payload?.data?.txninfo[0].kyc_manual_data) {
                  let kycData = JSON.parse(resp?.payload?.data?.txninfo[0].kyc_manual_data);
                  await dispatch(
                    addManualKycDataAction({
                      ...kycData.data
                    })
                  );
                }
              } else if (compname == "hdfc") {

                if (bodyDataRes?.carOwner?.toUpperCase() === "isCompany".toUpperCase()) {
                  setCoverages([bodyDataRes?.coverage[0]]);
                } else {
                  setCoverages(bodyDataRes?.coverage[0]);
                }
                await dispatch(
                  hdfcQuickQuoteAction({
                    data: {
                      ...bodyDataRes,
                      ...{ coverage: [bodyDataRes?.coverage[0]] },
                    },
                  })
                );
                if (resp?.payload?.data?.txninfo[0].kyc_manual_data) {
                  await sethdfcKycData(JSON.parse(resp?.payload?.data?.txninfo[0].kyc_manual_data));
                }
                if (resp?.payload?.data?.txninfo[0].stepper_value) {
                  kycDataRes = "asbc"
                  setShowForm("FormDetails");
                  setStepperValue(resp?.payload?.data?.txninfo[0].stepper_value + 1)
                }

              } else if (compname == "digit") {
                // if (bodyDataRes?.carOwner?.toUpperCase() === "isCompany".toUpperCase()) {
                //   setCoverages(bodyDataRes?.NewcoveragePlanDig ? [bodyDataRes?.NewcoveragePlanDig[0]] : [bodyDataRes?.coverage[0]]);
                // } else {
                //   setCoverages(bodyDataRes?.NewcoveragePlanDig ? [bodyDataRes?.NewcoveragePlanDig[0]] : [bodyDataRes?.coverage[0]]);
                // }                
                setCoverages(bodyDataRes?.quotePackageNameDigit ? bodyDataRes?.NewcoveragePlanDig ? [bodyDataRes?.NewcoveragePlanDig[0]] : [bodyDataRes?.coverage[0]] : [bodyDataRes?.coverage[0]]);
                await dispatch(
                  digitQuickQuoteAction({
                    data: {
                      ...bodyDataRes,
                      ...{ coverage: bodyDataRes?.quotePackageNameDigit ? bodyDataRes?.NewcoveragePlanDig ? [bodyDataRes?.NewcoveragePlanDig[0]] : [bodyDataRes?.coverage[0]] : [bodyDataRes?.coverage[0]] },
                    },
                  })
                );
              } else if (compname == "acko") {
                //check payment status
                let paymentStatus = await dispatch(ackocheckPaymentStatusAction({
                  data: {
                    transaction_id: id
                  },
                })
                );
                if (paymentStatus?.payload?.data?.redicrectLink) {
                  window.location.replace(paymentStatus?.payload?.data?.redicrectLink);
                  return;
                }
                if (bodyDataRes.data?.carOwner?.toUpperCase() === "isCompany".toUpperCase()) {
                  setCoverages([bodyDataRes?.coverage[0]]);
                } else {
                  setCoverages([bodyDataRes?.coverage[0]]);
                }
                await dispatch(
                  ackoQuickQuoteAction({
                    data: {
                      ...bodyDataRes,
                      ... { mobileNo: carDets.mobileNo },
                      ...{ coverage: [bodyDataRes?.coverage[0]] },
                    },
                  })
                );
              } else if (compname == "shreeram") {
                if (bodyDataRes?.carOwner?.toUpperCase() === "isCompany".toUpperCase()) {
                  setCoverages([bodyDataRes?.coverage[0]]);
                } else {
                  setCoverages([bodyDataRes?.coverage[0]]);
                }
                await dispatch(
                  shreeRamQuickQuoteAction({
                    data: {
                      ...bodyDataRes,
                      ...{ coverage: [bodyDataRes?.coverage[0]] },
                      ...{
                        CertificateOfIncorporationFile: resp?.payload?.data?.txninfo[0].cin_file,
                        kycProfileImg: resp?.payload?.data?.txninfo[0].profile_img_file,
                        kycAadharImg: resp?.payload?.data?.txninfo[0].aadhar_file,
                        kycAadharImgBack: resp?.payload?.data?.txninfo[0].aadhar_back_file,
                        kycPanImg: resp?.payload?.data?.txninfo[0].pan_file,
                      }
                    },
                  })
                );
              } else if (compname == "zuno") {
                if (bodyDataRes?.carOwner?.toUpperCase() === "isCompany".toUpperCase()) {
                  setCoverages([bodyDataRes?.coverage[0]]);
                } else {
                  setCoverages([bodyDataRes?.coverage[0]]);
                }
                await dispatch(
                  zunoQuickQuoteAction({
                    data: {
                      ...bodyDataRes,
                      ...{ coverage: [bodyDataRes?.coverage[0]] },
                    },
                  })
                );
                if (resp?.payload?.data?.txninfo[0].kyc_auto_data) {
                  let kycData = JSON.parse(resp?.payload?.data?.txninfo[0].kyc_auto_data);
                  await dispatch(
                    addZunoAutoKycDataAction({
                      ...kycData.data
                    })
                  )
                }
                let zunoData = {
                  trackId: id,
                  type: "get",
                };
                kycDataRes = await dispatch(zunoInsertBodydAction(zunoData));
                if (kycDataRes?.payload?.data?.paymentinfo?.length > 0) {
                  setShowForm("FormDetails");
                  setStepperValue(resp?.payload?.data?.txninfo[0].stepper_value + 1);
                }
              } else if (compname == "tata") {

                if (bodyDataRes.data?.carOwner?.toUpperCase() === "isCompany".toUpperCase()) {
                  setCoverages([bodyDataRes?.coverage[0]]);
                } else {
                  setCoverages([bodyDataRes?.coverage[0]]);
                }
                await dispatch(
                  tataQuickQuoteAction({
                    data: {
                      ...bodyDataRes,
                      ...{ coverage: [bodyDataRes?.coverage[0]] },
                    },
                  })
                );
              } else if (compname == "united") {

                if (bodyDataRes.data?.carOwner?.toUpperCase() === "isCompany".toUpperCase()) {
                  setCoverages([bodyDataRes?.coverage[0]]);
                } else {
                  setCoverages([bodyDataRes?.coverage[0]]);
                }
                await dispatch(
                  unitedQuickQuoteAction({
                    data: {
                      ...bodyDataRes,
                      ...{ coverage: [bodyDataRes?.coverage[0]] },
                    },
                  })
                );
              }
              setTimeout(() => {

              }, 1500)
              //due to zuno manual kyc redirect
              let previousCompany = localStorage.getItem('previousValue')

              if (kycDataRes == '') {
                if (resp?.payload?.data?.txninfo[0].form_value) {
                  if (previousCompany !== "null" && previousCompany !== "undefined" && previousCompany !== resp?.payload?.data?.txninfo[0].company_name) {
                    setShowForm("ownerDetail")
                    setStepperValue(1)
                    // handleFormReveal("ownerDetail")
                  } else {
                    setShowForm(resp?.payload?.data?.txninfo[0].form_value)
                    setStepperValue(resp?.payload?.data?.txninfo[0].stepper_value)
                    // handleFormReveal(resp?.payload?.data?.txninfo[0].stepper_value)
                  }

                } else {
                  if (dealer_code && resp?.payload?.data?.txninfo[0].company_name == "acko") {
                    setShowForm("dealerKyc")
                    // handleFormReveal("dealerKyc")
                  } else {
                    setShowForm("ownerDetail")
                    // handleFormReveal("ownerDetail")
                  }
                  setStepperValue(1)

                }
                // setShowForm((previousCompany !== null && previousCompany !== "null") && previousCompany !== resp?.payload?.data?.txninfo[0].company_name ? dealer_code && resp?.payload?.data?.txninfo[0].company_name ? dealer_code == "acko" ? "dealerKyc" : "ownerDetail" : resp?.payload?.data?.txninfo[0].form_value ? resp?.payload?.data?.txninfo[0].form_value : "dealerKyc")
                // setStepperValue((previousCompany !== null && previousCompany !== "null") && previousCompany !== resp?.payload?.data?.txninfo[0].company_name ? dealer_code && resp?.payload?.data?.txninfo[0].company_name ? dealer_code == "acko" ? "dealerKyc" : 1 : resp?.payload?.data?.txninfo[0].stepper_value ? resp?.payload?.data?.txninfo[0].stepper_value : 1)
                //handleFormReveal(resp?.payload?.data?.txninfo[0].form_value ? resp?.payload?.data?.txninfo[0].form_value : "ownerDetail")
              }
            }
          } else {
            // update
            let data = {
              uuid: id,
              bodyData: bodyData.data,
              companyName: CompanyName,
              formData,
              formValue: ShowForm,
              stepperValue: StepperValue,
              type: "update_proposal"
            };
            await dispatch(transactionURLAction(data));
            let cover = JSON.parse(JSON.stringify(bodyData?.data?.coverage))
            let isFalsePaCoverObject = cover[0]?.find(
              (item) => item.name === "paCover"
            );
            if (
              isFalsePaCoverObject &&
              formData?.carOwner?.toUpperCase() === "isCompany".toUpperCase()
            ) {
              isFalsePaCoverObject.checked = false;
            }
            setCoverages(cover);
          }
        }
        setLoading(false);
      } else {
        let cover = JSON.parse(JSON.stringify(bodyData?.data?.coverage))
        let isFalsePaCoverObject = cover[0].find(
          (item) => item.name === "paCover"
        );
        if (
          isFalsePaCoverObject &&
          formData?.carOwner?.toUpperCase() === "isCompany".toUpperCase()
        ) {
          isFalsePaCoverObject.checked = false;
        }
        setCoverages(cover);
        setLoading(false);
        return () => { }
      }
    };
    asyncFn();
  }, [])

  useEffect(() => {
    let divElement = document.getElementById("PropsalFormContainer_child-formFields");

    if (window.innerWidth < 610) {
      if (divElement) {
        divElement.scrollIntoView({ behavior: 'smooth' });
      }
    } else {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
    return () => { };
  }, [ShowForm, ShowKycInputs]);

  const stateListResponse = useSelector((state) => {
    return state?.getStateDetails?.data?.data?.rtocity;
  });

  const cityListResponse = useSelector((state) => {
    return state?.getCityDetails?.data?.data?.rtocity;
  });

  let stateList = stateListResponse?.map((data, index) => ({
    label: data?.state_name,
    value: data?.state_name,
  }));

  let cityList = cityListResponse?.map((data, index) => ({
    label: data?.city_name,
    value: data?.city_name,
  }));

  let result = cityList?.map((city) => city?.value);
  let sbiAutoKycData = sbiAutoKycDataMethod(kycAutoData, result);

  let rtoStateName = rtoState.filter((data) => {
    return (
      data?.stateId?.toUpperCase() == sbiAutoKycData?.stateCode?.toUpperCase()
    );
  });

  let propsalData = {
    carOwner: "individualOwner",
    // ownerName: "",
    firstName: sbiAutoKycData?.firstName || "",
    fathersName: null,
    lastName: sbiAutoKycData?.lastName || "",
    companyName: sbiAutoKycData?.companyName || "",
    email: sbiAutoKycData?.email || "",
    // phoneNumber: sbiAutoKycData?.phoneNumber || "",
    appointeeName: "",
    appointeeRelation: "",
    paExclusionReason: "",
    companyName: sbiAutoKycData?.companyName || "",
    email: sbiAutoKycData?.email || "",
    // phoneNumber: sbiAutoKycData?.phoneNumber || "",
    isNilDepInPrevPolicy: "",
    phoneNumber: (bodyData?.data?.mobileNo ? Number(bodyData?.data?.mobileNo) : (sbiAutoKycData?.phoneNumber ? sbiAutoKycData?.phoneNumber : "")),
    alternatePhoneNumber: "",
    gstNumber: "",
    gender: "",
    maritalStatus: "",
    // dob: sbiAutoKycData?.dob.setDate(sbiAutoKycData?.dob.getDate() + 1) || "",
    dob: sbiAutoKycData?.dob || "",
    nomineeName: "",
    nomineeAge: "",
    nomineeRelation: "",
    addressOne: sbiAutoKycData?.addressOne || "",
    addressTwo: sbiAutoKycData?.addressTwo || "",
    state: rtoStateName[0]?.state_name || "",
    city: sbiAutoKycData?.closestMatchCity || "",
    pincode: (bodyData?.data?.pincode ? Number(bodyData?.data?.pincode) : (sbiAutoKycData?.pincode ? sbiAutoKycData?.pincode : "")),
    prevPolicyNumber: "",
    prevOdPolicyNumber: "",
    prevPolicyNameInsurer: "",
    prevOdPolicyNameInsurer: "",
    chassisNumber: "",
    engineNumber: "",
    carIsInLoan: "",
    isSelfInspection: null,
    kycAadharNumber: "",
    CertificateOfIncorporationNumber: "",
    CertificateOfIncorporationFileFileType: "",
    CertificateOfIncorporationFile: "",
    kycPanNumber: sbiAutoKycData?.kycPanNumber || "",
    kycProfileImg: "",
    kycProfileImgFileType: "",
    kycAadharImg: "",
    kycAadharImgBack: "",
    kycAadharImgFileType: "",
    kycAadharImgBackFileType: "",
    kycPanImgFileType: "",
    kycPanImg: "",
    carHypothecationCompany: "",
    typeOfKycDocument: "",
    isCarPUC: "Yes",
    kycTitle: sbiAutoKycData?.kycTitle,
    fatherTitle: sbiAutoKycData?.fatherTitle,
    fatherFirstName: sbiAutoKycData?.fatherFirstName,
    fatherLastName: sbiAutoKycData?.fatherLastName,
    ckycResponseNumber: sbiAutoKycData?.ckycResponseNumber || "",
    ckycUniqueId: sbiAutoKycData?.ckycUniqueId || "",
    // Share Link
    phoneNumberforShareLink: "",
    emailforShareLink: "",
  };

  const [formData, setFormData] = useState(propsalData);

  const [phoneNoforSharelink, setphoneNoforSharelink] = useState(formData.phoneNumber);
  const [emailShareLink, setemailSharelink] = useState(formData.email);

  // handle here carowner
  let [coverages, setCoverages] = useState();

  useEffect(() => {
    async function callQuickQuoteAPi() {

      if (formData?.carOwner?.toUpperCase() === "isCompany".toUpperCase()) {
        let newCoverage = coverages && bodyData?.data?.coverage[0]?.map((item) => {
          if (item.name == 'paCover') {
            return ({ ...item, checked: false })
          } else {
            return item
          }
        })

        let newCoverageforDigand = newTestDigit && newTestDigit?.map((item) => {
          if (item?.name == 'paCover') {
            return ({ ...item, checked: false })
          } else {
            return item
          }
        })

        let newCoverageforICICI = newTest && newTest[0]?.map((item) => {
          if (item?.name == 'paCover') {
            return ({ ...item, checked: false })
          } else {
            return item
          }
        })

        setTriggerApi(false);
        setLoading(true);
        if (CompanyName == "sbi") {
          let response = await dispatch(
            sbiQuickQuoteAction({
              data: {
                ...bodyData.data,
                ...{ coverage: formData.carOwner == 'isCompany' ? [newCoverage] : coverages },
              },
            })
          );
          setLoading(false);

        } else if (CompanyName == "shreeram") {
          let response = await dispatch(
            shreeRamQuickQuoteAction({
              data: {
                ...bodyData.data,
                ...{ coverage: formData.carOwner == 'isCompany' ? [newCoverage] : coverages },
              },
            })
          );
          setLoading(false);
        } else if (CompanyName == "digit") {
          let response = await dispatch(
            digitQuickQuoteAction({
              data: {
                ...bodyData.data,
                ...{ coverage: bodyData?.data?.quotePackageNameDigit && newCoverageforDigand ? newCoverageforDigand : coverages },
              },
            })
          );
          setLoading(false);
        } else if (CompanyName == "zuno") {
          let response = await dispatch(
            zunoQuickQuoteAction({
              data: {
                ...bodyData.data,
                ...{ coverage: formData.carOwner == 'isCompany' ? [newCoverage] : coverages },

              },
            })
          );
          setLoading(false);
        } else if (CompanyName == "icici") {
          await dispatch(
            iciciQuickQuoteAction({
              data: {
                ...bodyData.data,
                ...{ coverage: bodyData?.data?.quotePackageNameICICI && newCoverageforICICI ? newCoverageforICICI : coverages },
              },
            })
          );
          setLoading(false);
        } else if (CompanyName == "hdfc") {
          await dispatch(
            hdfcQuickQuoteAction({
              data: {
                ...bodyData.data,
                ...{ coverage: formData.carOwner == 'isCompany' ? [newCoverage] : coverages },
              },
            })
          );
          setLoading(false);
        } else if (CompanyName == "acko") {
          await dispatch(
            ackoQuickQuoteAction({
              data: {
                ...bodyData.data,
                ...{ coverage: formData.carOwner == 'isCompany' ? [newCoverage] : coverages },

              },
            })
          );
          setLoading(false);
        } else if (CompanyName == "tata") {
          let response = await dispatch(
            tataQuickQuoteAction({
              data: {
                ...bodyData.data,
                ...{ coverage: formData.carOwner == 'isCompany' ? [newCoverage] : coverages },
              },
            })
          );
          setLoading(false);
        } else if (CompanyName == "united") {
          let response = await dispatch(
            unitedQuickQuoteAction({
              data: {
                ...bodyData.data,
                ...{ coverage: formData.carOwner == 'isCompany' ? [newCoverage] : coverages },
              },
            })
          );
          setLoading(false);
        }
      } else if (
        formData?.carOwner?.toUpperCase() === "individualOwner".toUpperCase() &&
        !triggerApi
      ) {
        setLoading(true);
        if (CompanyName == "sbi") {
          let response = await dispatch(
            sbiQuickQuoteAction({
              data: {
                ...bodyData.data,
                ...{ coverage: [getPreviousAddonData?.coverage[0]] },
              },
            })
          );
          if (response?.payload?.body?.data?.quote) {
            setLoading(false);
            setTriggerApi(true);
          }
        } else if (CompanyName == "shreeram") {
          let response = await dispatch(
            shreeRamQuickQuoteAction({
              data: {
                ...bodyData.data,
                ...{ coverage: [getPreviousAddonData?.coverage[0]] },
              },
            })
          );
          setLoading(false);
          setTriggerApi(true);
        } else if (CompanyName == "digit") {
          let response = await dispatch(
            digitQuickQuoteAction({
              data: {
                ...bodyData.data,
                ...{ coverage: bodyData?.data?.quotePackageNameDigit && newTestDigit ? newTestDigit : [getPreviousAddonData?.coverage[0]] },
              },
            })
          );
          setLoading(false);
          setTriggerApi(true);
        } else if (CompanyName == "zuno") {
          let response = await dispatch(
            zunoQuickQuoteAction({
              data: {
                ...bodyData.data,
                ...{ coverage: [getPreviousAddonData?.coverage[0]] },
              },
            })
          );
          setLoading(false);
          setTriggerApi(true);
        } else if (CompanyName == "icici") {
          await dispatch(
            iciciQuickQuoteAction({
              data: {
                ...bodyData.data,
                ...{ coverage: bodyData?.data?.quotePackageNameICICI && newTest ? newTest[0] : [getPreviousAddonData?.coverage[0]] },
              },
            })
          );
          setLoading(false);
          setTriggerApi(true);
        } else if (CompanyName == "hdfc") {
          await dispatch(
            hdfcQuickQuoteAction({
              data: {
                ...bodyData.data,
                ...{ coverage: [getPreviousAddonData?.coverage[0]] },
              },
            })
          );
          setLoading(false);
          setTriggerApi(true);
        } else if (CompanyName == "acko") {
          await dispatch(
            ackoQuickQuoteAction({
              data: {
                ...bodyData.data,
                ...{ coverage: [getPreviousAddonData?.coverage[0]] },
              },
            })
          );
          setLoading(false);
          setTriggerApi(true);
        } else if (CompanyName == "tata") {
          let response = await dispatch(
            tataQuickQuoteAction({
              data: {
                ...bodyData.data,
                ...{ coverage: [getPreviousAddonData?.coverage[0]] },
              },
            })
          );
          setLoading(false);
          setTriggerApi(true);
        } else if (CompanyName == "united") {
          let response = await dispatch(
            unitedQuickQuoteAction({
              data: {
                ...bodyData.data,
                ...{ coverage: [getPreviousAddonData?.coverage[0]] },
              },
            })
          );
          setLoading(false);
          setTriggerApi(true);
        }
      }
    }
    if (bodyData) {
      callQuickQuoteAPi();
    } else {
      return () => { }
    }
  }, [bodyData?.data?.carOwner]);

  // call here apecific kyc API
  const customerKyc = async (CompanyName) => {
    switch (CompanyName) {
      case "sbi":
        await dispatch(
          sbiKycAuto({
            data: {
              ...formData,
              ...{
                dob: formData?.dob?.toString(),
                ...{
                  requestId:
                    sbiQuickQuoteData?.data?.body?.data?.body?.RequestHeader
                      ?.requestID,
                },
              },
            },
          })
        );
        break;
      case "icici":
        await dispatch(
          iciciKycAuto({
            data: {
              ...formData,
              ...{
                dob: formData?.dob,
                ...{
                  correlationId:
                    iciciQuickQuoteData?.data.data?.body?.CorrelationId,
                },
              },
            },
          })
        );
        break;
      case "hdfc":
        await dispatch(
          hdfcKycAuto({
            data: {
              ...formData,
              ...{
                dob: formData?.dob,
                trackingid: id
              },
            },
          })
        );
        break;
      case "zuno":
        await dispatch(
          zunoKycCustomerAction({
            data: {
              ...formData,
              ...{
                dob: formData?.dob,
                trackingIdfront: id
              },
            },
          })
        );
        break;
      case "united":
        await dispatch(
          unitedAutoKycAction({
            data: {
              ...formData,
              ...{
                dob: formData?.dob
              },
            },
          })
        );
        break;
      default:
        break;
    }
  };

  let [mobileNo, setmobileNo] = useState(localStorage.getItem("mobileNo"));
  let [pincode, setpincode] = useState(localStorage.getItem("pincode"));

  let carVechicleNumber = localStorage.getItem("vehicleNumber")
  const mappingAllData = [bodyData]?.map((data) => {
    return {
      isNilDepInPrevPolicy: formData?.isNilDepInPrevPolicy,
      carOwner: formData?.carOwner,
      firstName: formData?.firstName,
      fathersName: formData?.fathersName,
      lastName: formData?.lastName,
      appointeeName: formData?.appointeeName,
      appointeeRelation: formData?.appointeeRelation,
      paExclusionReason: formData?.paExclusionReason,
      companyName: formData?.companyName,
      email: formData?.email,
      phoneNumber: formData?.phoneNumber,
      alternatePhoneNumber: formData?.alternatePhoneNumber,
      gstNumber: formData?.gstNumber,
      gender: formData?.gender,
      maritalStatus: formData?.maritalStatus,
      dob: formData?.dob?.toString(),
      nomineeName: formData?.nomineeName,
      nomineeAge: formData?.nomineeAge,
      nomineeRelation: formData?.nomineeRelation,
      addressOne: formData?.addressOne,
      addressTwo: formData?.addressTwo,
      state: formData?.state,
      city: formData?.city,
      pincode: formData?.pincode ? formData?.pincode : pincode,
      prevPolicyNumber: formData?.prevPolicyNumber,
      prevPolicyNameInsurer: formData?.prevPolicyNameInsurer,
      prevOdPolicyNumber: formData?.prevOdPolicyNumber,
      prevOdPolicyNameInsurer: formData?.prevOdPolicyNameInsurer,
      chassisNumber: formData?.chassisNumber
        ? formData?.chassisNumber
        : data?.data?.chassisNumber,
      engineNumber: formData?.engineNumber
        ? formData?.engineNumber
        : data?.data?.engineNumber,
      carIsInLoan: formData?.carIsInLoan,
      kycAadharNumber: formData?.kycAadharNumber,
      kycPanNumber: formData?.kycPanNumber,
      kycProfileImg: formData?.kycProfileImg,
      kycProfileImgFileType: formData?.kycProfileImgFileType,
      kycAadharImg: formData?.kycAadharImg,
      kycAadharImgBack: formData?.kycAadharImgBack,
      kycAadharImgFileType: formData?.kycAadharImgFileType,
      kycAadharImgBackFileType: formData?.kycAadharImgBackFileType,
      kycPanImgFileType: formData?.kycPanImgFileType,
      kycPanImg: formData?.kycPanImg,
      CertificateOfIncorporationNumber:
        formData?.CertificateOfIncorporationNumber,
      CertificateOfIncorporationFileFileType:
        formData?.CertificateOfIncorporationFileFileType,
      CertificateOfIncorporationFile: formData?.CertificateOfIncorporationFile,
      carHypothecationCompany: formData?.carHypothecationCompany,
      isCarPUC: formData?.isCarPUC,
      typeOfKycDocument: formData?.typeOfKycDocument,
      ckycResponseNumber: formData?.ckycResponseNumber,
      ckycUniqueId: formData?.ckycUniqueId,
      // viewPlans datax
      PolicyTypeValue: data?.data?.PolicyTypeValue,
      basicOwnDamge: data?.data?.basicOwnDamge,
      basicTPprice: data?.data?.basicTPprice,
      cc: data?.data?.cc,
      coverage:
        formData?.carOwner?.toUpperCase() === "individualOwner".toUpperCase()
          ? [getPreviousAddonData?.coverage[0]]
          : coverages,
      grossPremium: data?.data?.grossPremium,
      gstPrice: data?.data?.gstPrice,
      idvValue: data?.data?.idvValue,
      isClaimPrevPolicy: data?.data?.isClaimPrevPolicy,
      isCngLpgVehicleType: data?.data?.isCngLpgVehicleType,
      odInternalCngLpg: data?.data?.odInternalCngLpg,
      tpInternalCngLpg: data?.data?.tpInternalCngLpg,
      isPrevPolicyExp: data?.data?.isPrevPolicyExp,
      make: data?.data?.make,
      posp: data?.data?.posp,
      maxIdv: data?.data?.maxIdv,
      mfgDate: data?.data?.policyRegDate,
      minIdv: data?.data?.minIdv,
      model: data?.data?.model,
      ncbDiscount: data?.data?.ncbDiscount,
      netPayable: data?.data?.netPayable,
      newNcb: data?.data?.newNcb,
      onBLurIdbValue: data?.data?.onBLurIdbValue,
      ownDamge: data?.data?.ownDamge,
      paCover: data?.data?.paCover,
      paidDriverAmt: data?.data?.paidDriverAmt,
      policyExpDate: data?.data?.policyExpDate,
      policyRegDate: data?.data?.policyRegDate,
      prevNcb: data?.data?.prevNcb,
      prevPolicyExpLastMonth: data?.data?.prevPolicyExpLastMonth,
      prevPolicyODexpDate: data?.data?.prevPolicyODexpDate,
      prevPolicyType: data?.data?.prevPolicyType,
      seatingCapacity: data?.data?.seatingCapacity,
      totalAddonsPrice: data?.data?.totalAddonsPrice,
      totalTPprice: data?.data?.totalTPprice,
      typeOfBusiness: data?.data?.typeOfBusiness,
      varient: data?.data?.varient,
      vehicleNumber: data?.data?.vehicleNumber,
      isFindMyCar: data?.data?.isFindMyCar,
      fuelType: data?.data?.fuelType,
      nonElectricalAccesories: data?.data?.nonElectricalAccesories,
      electricalAccesories: data?.data?.electricalAccesories,
      pcCoverSumInsured: data?.data?.pcCoverSumInsured,
      cngKitValue: data?.data?.cngKitValue,
      mobileNo,
      isEngProtectInPrevPolicy: data?.data?.isEngProtectInPrevPolicy,
      isNilDepInPrevPolicy: data?.data?.isNilDepInPrevPolicy,
      quotePackageName: data?.data?.quotePackageName,
      quotePackageNameDigit: data?.data?.quotePackageNameDigit,
      quotePackageNameICICI: data?.data?.quotePackageNameICICI,
      numberOfClaim: data?.data?.numberOfClaim,
      isInvoiceCoverInPrevPolicy: data?.data?.isInvoiceCoverInPrevPolicy,
    };
  });

  const isPaCoverChecked = bodyData?.data?.coverage ? (bodyData?.data?.coverage[0]?.find(item => item.id === "checkbox1")?.checked || false) : false;
  let kycGender = "";

  //when auto kyc successs set form data in the proposal form
  useEffect(() => {
    if (sbiAutoKycData?.kycStatus === "CKYCSuccess") {
      kycGender =
        sbiAutoKycData?.gender?.toUpperCase() === "M" ? "Male" : "Female";
      setFormData((prevItems) => ({
        ...prevItems,
        gender: kycGender,
      }));
      mappingAllData[0].gender = kycGender;
    }

    setFormData((prevItems) => ({
      ...prevItems,
      firstName: sbiAutoKycData?.firstName,
      lastName: sbiAutoKycData?.lastName,
      companyName: sbiAutoKycData?.companyName,
      email:
        sbiAutoKycData?.email.length > 0
          ? sbiAutoKycData?.email
          : formData?.email,
      phoneNumber:
        sbiAutoKycData?.phoneNumber
          ? sbiAutoKycData?.phoneNumber
          : formData?.phoneNumber,
      dob: sbiAutoKycData?.dob,
      addressOne: sbiAutoKycData?.addressOne,
      addressTwo: sbiAutoKycData?.addressTwo,
      state: rtoStateName[0]?.state_name,
      city: kycAutoData?.data?.body?.data?.data?.A99ResponseData
        ?.CKYCDownloadResult?.CKYCCorAddCity,
      pincode: sbiAutoKycData?.pincode ? sbiAutoKycData?.pincode : formData?.pincode,
      kycPanNumber: sbiAutoKycData?.kycPanNumber,
      kycTitle: sbiAutoKycData?.kycTitle,
      ckycResponseNumber: sbiAutoKycData?.ckycResponseNumber,
      ckycUniqueId: sbiAutoKycData?.ckycUniqueId,
    }));

    mappingAllData[0].firstName = sbiAutoKycData?.firstName;
    mappingAllData[0].lastName = sbiAutoKycData?.lastName;
    mappingAllData[0].companyName = sbiAutoKycData?.companyName;
    mappingAllData[0].email =
      sbiAutoKycData?.email.length > 0
        ? sbiAutoKycData?.email
        : formData?.email;
    mappingAllData[0].phoneNumber =
      sbiAutoKycData?.phoneNumber
        ? sbiAutoKycData?.phoneNumber
        : formData?.phoneNumber;
    mappingAllData[0].dob = sbiAutoKycData?.dob;
    mappingAllData[0].addressOne = sbiAutoKycData?.addressOne;
    mappingAllData[0].addressTwo = sbiAutoKycData?.addressTwo;
    mappingAllData[0].state = rtoStateName[0]?.state_name;
    mappingAllData[0].city =
      kycAutoData?.data?.body?.data?.data?.A99ResponseData?.CKYCDownloadResult?.CKYCCorAddCity;
    mappingAllData[0].pincode = sbiAutoKycData?.pincode;
    mappingAllData[0].kycPanNumber = sbiAutoKycData?.kycPanNumber;
    mappingAllData[0].kycTitle = sbiAutoKycData?.kycTitle;
    mappingAllData[0].ckycResponseNumber = sbiAutoKycData?.ckycResponseNumber;
    mappingAllData[0].ckycUniqueId = sbiAutoKycData?.ckycUniqueId;
  }, [sbiAutoKycData?.kycStatus === "CKYCSuccess"]);

  useEffect(() => {
    setFormData((previtems) => ({
      ...previtems,
      firstName: hdfcKycData?.data?.name || "",
      companyName: hdfcKycData?.data?.name || "",
      fathersName: null || "",
      lastName: null || "",
      pincode: hdfcKycData?.data?.permanentPincode || "",
      kycPanNumber: hdfcKycData?.data?.pan || "",
      addressOne: hdfcKycData?.data?.permanentAddress1 || "",
      addressTwo: hdfcKycData?.data?.permanentAddress2 || "",
      state: hdfcKycData?.data?.statename || "",
      city: hdfcKycData?.data?.permanentCity || "",
      dob: formData.carOwner !== "isComapny" ? hdfcKycData?.data?.dob : hdfcKycData?.data?.doi || "",
    }));
  }, [hdfcKycData])

  const [ShowNilDepState, setShowNilDepState] = useState(null)

  //set policy number / quoatation number
  useEffect(() => {
    dispatch(
      allBodyDataAction({
        ...mappingAllData[0],
        coverage: [bodyData?.data?.coverage[0]],
        PolicyNumber:
          sbiFullQuoteData?.data?.body?.data?.quote?.PolicyObject?.QuotationNo,
      })
    );
    if (sbiFullQuoteData?.data?.body?.data?.quote) {
      localStorage.setItem(
        "sbiFullQuoteData",
        JSON.stringify(sbiFullQuoteData)
      );
    }
    setemailSharelink(formData?.email);
  }, [sbiAutoKycData?.kycStatus === "CKYCSuccess", !isAutoKyc, formData]);



  const hideInsurerInCaseBundleShriRam = shreeRamQuote?.data?.data?.quote?.GetQuotResult?.hideInsurerInBundleCase

  const handleInputChange = (event, constName) => {
    const { name, value, checked } = event?.target || {};
    // console.log(errors, "errorserrors");
    if (name && errors) {
      delete errors[name]


    }
    if (
      name === "kycAadharImg" ||
      name === "kycProfileImg" ||
      name === "kycPanImg" ||
      name === "kycAadharImgBack" ||
      name === "CertificateOfIncorporationFile"
    ) {
      const file = event.target.files[0];
      let uploadedFileName = "";
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          const base64Data = e.target.result.split(",")[1];
          const fileExtension = "." + file.type.split("/").pop();

          setFormData((prevItems) => ({
            ...prevItems,
            [name]: base64Data,
            [`${name}FileType`]: fileExtension,
            kycProfileImg: base64Data,
            base64DataFileType: fileExtension,
            kycProfileImgFileType: fileExtension

          }));
        };

        if (file) {
          setShowSuccesMsg(true)
        }

        reader.readAsDataURL(file);
        uploadedFileName = file.name;
        const uploadedFileType = file.type;

        if (uploadedFileName.length > 40) {
          uploadedFileName = "..." + uploadedFileName.slice(-7);
        }

        if (name === "kycAadharImg") {
          setAadharImgName(uploadedFileName);
        } else if (name === "kycAadharImgBack") {
          dispatch(previousfilenameAction(uploadedFileName));
          // setAadharImgBackName(uploadedFileName);
        } else if (name === "kycPanImg") {
          setPanImgName(uploadedFileName);
          setShowSuccesMsg(true);
        } else if (name === "kycProfileImg") {
          setImage(URL.createObjectURL(file));
          setProfileImgName(uploadedFileName);
        } else if (name == "CertificateOfIncorporationFile") {
          setCINImgName(uploadedFileName);
        }
      }



    } else if (name === "carOwner") {
      setFormData((prevItems) => ({
        ...prevItems,
        carOwner: checked ? "isCompany" : "individualOwner",
      }));
    } else if (name === "phoneNumber") {
      if (value.length <= 10) {
        setcheckphone(false)
        localStorage.removeItem("MobileExist");
        setFormData((prevItems) => ({
          ...prevItems,
          phoneNumber: value,
        }));
      }
    } else if (name == "email") {
      localStorage.removeItem("MobileExist");
      setFormData((prevItems) => ({
        ...prevItems,
        email: value,
      }));
    }
    else if (name == "nomineeAge") {
      if (value < 125) {
        setFormData((prevItems) => ({
          ...prevItems,
          nomineeAge: value,
        }));
      }
    } else if (name == "pincode") {
      if (value < 999999) {
        setFormData((prevItems) => ({
          ...prevItems,
          pincode: value,
        }));
      }
    } else if (name == "kycAadharNumber") {
      if (value < 999999999999) {
        setFormData((prevItems) => ({
          ...prevItems,
          kycAadharNumber: value,
        }));
      }
    }

    else if (name === "kycPanNumber") {
      let newValu = value.replace(/[\!\@\#\$\%\^\&\*\)\(\+\=\.\<\>\{\}\[\]\:\;\'\"\|\~\`\_\-\\\?]/g, '')
      if (value.length < 13) {
        setFormData((prevItems) => ({
          ...prevItems,
          kycPanNumber: newValu?.toUpperCase(),
        }));
      }
    } else if (name === "firstName") {    //here adding a validation for firstName & lastName for Numeric and special char
      let newValu = value.replace(/[0-9\!\@\#\$\%\^\&\*\)\(\+\=\.\<\>\{\}\[\]\:\;\'\"\|\~\`\_\-\\\?]/g, '');
      setFormData((prevItems) => ({
        ...prevItems,
        firstName: newValu?.toUpperCase(),
      }));
    } else if (name === "lastName") {
      let newValu = value.replace(/[0-9\!\@\#\$\%\^\&\*\)\(\+\=\.\<\>\{\}\[\]\:\;\'\"\|\~\`\_\-\\\?]/g, '');
      setFormData((prevItems) => ({
        ...prevItems,
        lastName: newValu?.toUpperCase(),
      }));
    } else if (name === "companyName") {
      setFormData((prevItems) => ({
        ...prevItems,
        companyName: value?.toUpperCase(),
      }));
    } else if (name === "isNilDepInPrevPolicy") {

      if (value === "true") {
        setShowNilDepState(true)
        setFormData((prevItems) => ({
          ...prevItems,
          isNilDepInPrevPolicy: value,
        }));
      } else {
        setShowNilDepState(false)
        setFormData((prevItems) => ({
          ...prevItems,
          isNilDepInPrevPolicy: value,
        }));
      }
    } else if (CompanyName === SHREERAM && hideInsurerInCaseBundleShriRam === true && name === "prevPolicyNumber") {
      if (name === "prevPolicyNumber") {
        setFormData((prevItems) => ({
          ...prevItems,
          prevPolicyNumber: value,
          prevOdPolicyNumber: value,
        }))
      }
    } else if (name == "phoneNumberforShareLink") {
      if (value?.length <= 10) {
        setphoneNoforSharelink(value)

        // setFormData((prevItems) => ({
        //   ...prevItems,
        //   phoneNumberforShareLink: value,
        // }));

      }

    }
    else if (name == "emailforShareLink") {
      setemailSharelink(value)
      // setFormData((prevItems) => ({
      //   ...prevItems,
      //   emailforShareLink: value,
      // }));
    }

    else {
      setFormData((prevItems) => ({
        ...prevItems,
        [name]: value,
      }));
    }
  };

  const handleDatePickerValue = (newDate) => {
    setFormData((prevData) => ({
      ...prevData,
      dob: newDate,
    }));
  };

  const handlePopUps = () => {
    if (ShowCityDropdown) {
      setShowCityDropdown(false);
    }
  };

  const HandleEditProposalDetails = (fromName, state) => {

    if (fromName === "ownerDetails") {
      setShowForm("ownerDetail");
      setEdit(true);
    } else if (fromName === "personalDetails") {
      setShowForm("PersonalDetails");
      setEdit(true);
    } else if (fromName === "addressDetails") {
      setShowForm("AddressDetails");
      setEdit(true);
    } else if (fromName === "vehicleDetails") {
      setStepperValue(4);
      setShowForm("VehicleDetails");
      setEdit(true);
    } else if (formData == "dealerKyc") {
      setShowForm("dealerKyc");
      setEdit(true);
    }
  };

  const handleBackBtn = (formName) => {
    setEdit(false);
    switch (formName) {
      case "PersonalDetails":
        setStepperValue(StepperValue - 1);
        setShowForm("ownerDetail");
        break;

      case "AddressDetails":
        if (CompanyName !== ACKO && formData.carOwner !== "isCompany") { //here change some code
          setStepperValue(StepperValue - 1);
          setShowForm("PersonalDetails");
        } else {
          setShowForm("ownerDetail");
        }
        break;

      case "vehicleDetails":
        setStepperValue(StepperValue - 1);
        setShowForm("AddressDetails");
        break;

      case "kycDetails":
        setStepperValue(4);
        setShowForm("VehicleDetails");
        break;

      case "InspectionDetails":
        setStepperValue(5);
        setShowForm("kycDetails");
        break;

      case "FormDetails":
        if (CompanyName == ACKO || CompanyName == "tata") {
          setStepperValue(4);
          setShowForm("VehicleDetails");
        } else {
          if (InspectionStatus && mappingAllData[0]?.PolicyTypeValue !== "Third Party") {
            setShowForm("InspectionDetails");
            setStepperValue(StepperValue - 1);
          } else {
            setStepperValue(5);
            setShowForm("kycDetails");
          }
        }
        break;

      default:
        break;
    }
  };


  useEffect(() => {
    if (ShowSuccesMsg) {
      setTimeout(() => {
        setShowSuccesMsg(false);
      }, 1000);
    }
  }, [ShowSuccesMsg]);

  const makeKyc = async (e) => {
    // e.preventDefault();
    //if company name to kyc else 
    if (CompanyName == "sbi") {
      const isValidKyc = validateKycDetails();
      if (isValidKyc) {
        setIsDisabledButton(true);
        if (isAutoKyc) {
          await customerKyc("sbi");
          setIsDisabledButton(false);
        }
        if (!isAutoKyc) {
          await dispatch(sbiKyc(bodyData));
          setIsDisabledButton(false);
        }
      }
    } else if (CompanyName == "icici") {

      const isValidKyc = validateKycDetails();
      if (isValidKyc) {
        setIsDisabledButton(true);
        if (isAutoKyc) {
          await customerKyc("icici");
          setIsDisabledButton(false);
        }
        if (!isAutoKyc) {
          await dispatch(
            iciciKyc({
              data: {
                ...bodyData.data,
                ...{
                  correlationId:
                    iciciQuickQuoteData?.data.data?.body?.CorrelationId,
                  dob: formData?.dob?.toString(),
                },
              },
            })
          );
          setIsDisabledButton(false);
        }
      } else {
        setIsDisabledButton(false);
      }
    } else if (CompanyName == "hdfc") {
      const isValidKyc = validateKycDetails();
      if (isValidKyc) {
        setIsDisabledButton(true);
        if (isAutoKyc) {
          await customerKyc("hdfc");
          setIsDisabledButton(false);
        }
      }
    } else if (CompanyName == "zuno") {
      const isValidKyc = validateKycDetails();
      if (isValidKyc) {
        setIsDisabledButton(true);
        if (isAutoKyc) {
          await customerKyc("zuno");
          setIsDisabledButton(false);
        }
      }
    } else if (CompanyName == "digit") {
      const isValidKyc = validateKycDetails();
      if (isValidKyc) {
        let data = {
          uuid: id,
          bodyData: bodyData.data,
          companyName: CompanyName,
          formData,
          formValue: "FormDetails",
          stepperValue: 7,
          type: "update_proposal",
        };
        await dispatch(transactionURLAction(data));
        setShowForm("FormDetails");
        setIsDisabledButton(true);
        if (isAutoKyc) {
          setIsDisabledButton(false);
        }
        if (!isAutoKyc) {
          await dispatch(sbiKyc(bodyData));
          setIsDisabledButton(false);
        }
      }
    } else if (CompanyName == "shreeram") {
      setFormData((prevItems) => ({
        ...prevItems,
        typeOfKycDocument: "C",
      }));

      const isValidKyc = validateKycDetails();
      if (isValidKyc) {
        handleFormReveal("kycDetails", "", "");
      }
    } else if (CompanyName == "united") {
      const isValidKyc = validateKycDetails();
      if (isValidKyc) {
        setIsDisabledButton(true);
        if (isAutoKyc) {
          await customerKyc("united");
          setIsDisabledButton(false);
        }
      }
    } else {
      setIsDisabledButton(false);
      const isValidKyc = validateKycDetails();
      //shreeram digit proceed to confirm details here aand handle other thimgs
      if (isValidKyc) {
        handleFormReveal("kycDetails");
      }
      if (!isAutoKyc) {
        try {
          let maniualKycResponse = await dispatch(
            sbiKyc({
              data: {
                ...bodyData.data,
                ...{
                  requestId:
                    sbiQuickQuoteData?.data?.body?.data?.body?.RequestHeader
                      ?.requestID,
                },
              },
            })
          );
          setIsDisabledButton(false);
          if (maniualKycResponse?.payload?.message) {
            toast.error("KYC " + maniualKycResponse?.payload?.message || "Something Went Wrong", {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          }
        } catch (error) {
          toast.error("Internal Server Error", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      }
    }
  };

  useEffect(() => {
    if (
      !kycAutoData?.isLoading &&
      !kycAutoData?.isError &&
      kycAutoData?.data?.body?.data?.data
    ) {
      const { A99SearchDownloadResponse, A99ResponseData } =
        kycAutoData?.data?.body?.data?.data;
      if (A99ResponseData?.CKYCDownloadResult?.CKYCStatus == "CKYCSuccess") {
        setIsDisabledButton(false);
        handleFormReveal("kycDetails");
      } else {
        kycAutoData = "";
        if (formData?.carOwner !== "individualOwner") {
          setFormData((prevItems) => ({
            ...prevItems,
            typeOfKycDocument: "Certificate of Incorporation",
          }));
        } else {
          setFormData((prevItems) => ({
            ...prevItems,
            typeOfKycDocument: "Aadhar Card",
          }));
        }
        setAutoKYCrejected(true);
        setIsAutoKyc(false);
        setIsDisabledButton(false);
      }
    } else if (
      (kycAutoData?.isError && !kycAutoData?.data?.body?.data?.data) ||
      kycAutoData?.data?.message?.toUpperCase() ===
      "Service Unavailable".toUpperCase() ||
      kycAutoData?.data?.message?.toUpperCase() ===
      "Internal Server Error".toUpperCase()
    ) {
      kycAutoData = "";
      setFormData((prevItems) => ({
        ...prevItems,
        typeOfKycDocument: "Aadhar Card",
      }));
      setAutoKYCrejected(true);
      setIsAutoKyc(false);
      setIsDisabledButton(false);
    }
  }, [kycAutoData]);


  useEffect(() => {
    if (
      !iciciKycAutoData?.isLoading &&
      !iciciKycAutoData?.isError &&
      iciciKycAutoData?.data?.body?.data
    ) {
      if (iciciKycAutoData?.data?.body?.data?.statusMessage == "Failed") {
        if (formData.carOwner == "individualOwner") {
          if (formData?.typeOfKycDocument == "PAN Card") {
            setFormData((prevItems) => ({
              ...prevItems,
              typeOfKycDocument: "Aadhar Card",
            }));
            setmodalIsOpenfail(true)

          } else if (formData?.typeOfKycDocument == "Aadhar Card") {
            setFormData((prevItems) => ({
              ...prevItems,
              typeOfKycDocument: "Aadhar Card",
            }));
            setmodalIsOpenfail(false)
            setAutoKYCrejected(true);
            setIsAutoKyc(false);
            setIsDisabledButton(false);
          }
        }
        else {
          if (formData?.typeOfKycDocument == "PAN Card") {
            setFormData((prevItems) => ({
              ...prevItems,
              typeOfKycDocument: "Certificate of Incorporation",
            }));
            setmodalIsOpenfail(true)
          } else if (formData?.typeOfKycDocument == "Certificate of Incorporation") {
            setFormData((prevItems) => ({
              ...prevItems,
              typeOfKycDocument: "C",
            }));
            setmodalIsOpenfail(false)
            setAutoKYCrejected(true);
            setIsAutoKyc(false);
            setIsDisabledButton(false);
          }

        }
        // setAutoKYCrejected(true);
        // setIsAutoKyc(false);
        // setIsDisabledButton(false);
        // iciciKycAutoData = "";
      } else {
        let data = {
          uuid: id,
          bodyData: bodyData.data,
          companyName: CompanyName,
          formData,
          formValue: "FormDetails",
          stepperValue: 7,
          type: "update_proposal",
          kycAutoData: iciciKycAutoData,
        };
        dispatch(transactionURLAction(data));
        setIsDisabledButton(false);
        handleFormReveal("kycDetails");
      }
    } else if (
      (iciciKycAutoData?.isError && !iciciKycAutoData?.data?.body?.data) ||
      iciciKycAutoData?.data?.message?.toUpperCase() ===
      "Service Unavailable".toUpperCase() ||
      iciciKycAutoData?.data?.message?.toUpperCase() ===
      "Internal Server Error".toUpperCase()
    ) {
      iciciKycAutoData = "";
      setFormData((prevItems) => ({
        ...prevItems,
        typeOfKycDocument: "Aadhar Card",
      }));
      setAutoKYCrejected(true);
      setIsAutoKyc(false);
      setIsDisabledButton(false);
    }
  }, [iciciKycAutoData]);


  useEffect(() => {
    if (
      kycManualData?.data?.body?.data?.data &&
      kycManualData?.data?.body?.data?.data?.status == "Success"
    ) {
      setIsKycSuccess(true);
      setIsDisabledButton(false);
      handleFormReveal("kycDetails");
    }

    if (isKycSuccess) {
      setIsKycSuccess(false);
      setIsDisabledButton(false);
      handleFormReveal("kycDetails");
    }
    if (
      kycManualData?.data?.body?.data?.data?.status?.toUpperCase() === "FAILURE"
    ) {
      toast.error("KYC " + kycManualData?.data?.body?.data?.data?.status, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  }, [kycManualData]);

  // validatePhoneNumber
  const validatePhoneisExist = () => {
    const newErrors = {};
    if (checkphone) {
      newErrors.phoneNumber = "This Mobile number is already associated with another account.";
    }
    setErrors2({ ...errors, newErrors });
    return Object?.keys(newErrors)?.length === 0;

  }
  useEffect(() => {
    if (
      iciciKycManualData?.data?.body?.data &&
      iciciKycManualData?.data?.body?.data?.statusMessage != "Failed"
    ) {
      let data = {
        uuid: id,
        bodyData: bodyData.data,
        companyName: CompanyName,
        formData,
        formValue: "FormDetails",
        stepperValue: 7,
        type: "update_proposal",
        kycAutoData: iciciKycAutoData,
        kycManualData: iciciKycManualData
      };
      dispatch(transactionURLAction(data));
      setIsKycSuccess(true);
      setIsDisabledButton(false);
      handleFormReveal("kycDetails");
    }

    if (isKycSuccess) {
      setIsKycSuccess(false);
      setIsDisabledButton(false);
      handleFormReveal("kycDetails");
    }
    if (iciciKycManualData?.data?.body?.data?.statusMessage == "Failed") {
      toast.error("KYC " + iciciKycManualData?.data?.body?.data?.message, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });

    }
  }, [iciciKycManualData]);

  // zuno Auto & Manual
  useEffect(() => {
    (async function () {
      if (
        !zunoKycAutoData?.isLoading &&
        !zunoKycAutoData?.isError &&
        zunoKycAutoData?.data?.data?.quote
      ) {
        if (zunoKycAutoData?.data?.data?.quote?.data?.KYC_Status !== "1") {
          setLoading(true);
          let zunoData = {
            trackId: id,
            kycId: zunoKycAutoData?.data?.data?.quote?.data?.VISoF_KYC_Req_No,
            data: {
              zunoBody: bodyData,
            },
            type: "insert",
          };
          await dispatch(
            zunoInsertBodydAction(
              zunoData
            ));
          let zunoPortalKyc = zunoKycAutoData?.data?.data?.quote?.data?.IC_KYC_REG_URL
          dispatch(zunoKycCustomerReducer.actions.resetZunoAutoKycAction());
          window.location.replace(zunoPortalKyc);
        } else {
          let data = {
            uuid: id,
            bodyData: bodyData.data,
            companyName: CompanyName,
            formData,
            formValue: "FormDetails",
            stepperValue: 7,
            type: "update_proposal",
            kycAutoData: zunoKycAutoData,
          };
          dispatch(transactionURLAction(data));
          setIsDisabledButton(false);
          handleFormReveal("kycDetails");
        }
      } else if (
        (zunoKycAutoData?.isError && !zunoKycAutoData?.data?.data?.quote) ||
        zunoKycAutoData?.data?.message?.toUpperCase() ===
        "Service Unavailable".toUpperCase() ||
        zunoKycAutoData?.data?.message?.toUpperCase() ===
        "Internal Server Error".toUpperCase()
      ) {
        toast.error("Something went wrong. Please try again.", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    })();
  }, [zunoKycAutoData]);

  useEffect(() => {
    (async function () {
      if (
        !hdfcKycAutoData?.isLoading &&
        !hdfcKycAutoData?.isError &&
        hdfcKycAutoData?.data?.data?.quote
      ) {
        if (hdfcKycAutoData?.data?.data?.quote?.data?.iskycVerified !== 1) {
          let hdfcPortalKyc = hdfcKycAutoData?.data?.data?.quote?.data?.redirect_link
          dispatch(hdfcKycAutoReducer.actions.resethdfcKycAutoAction());
          window.location.replace(hdfcPortalKyc);
          setAutoKYCrejected(true);
          setIsAutoKyc(false);
          setIsDisabledButton(false);
        } else {
          let data = {
            uuid: id,
            type: "fetch"
          };
          let resp = await dispatch(transactionURLAction(data));
          await sethdfcKycData(JSON.parse(resp?.payload?.data?.txninfo[0].kyc_auto_data));
          setIsDisabledButton(false);
          handleFormReveal("kycDetails");
        }
      } else if (
        (hdfcKycAutoData?.isError && !hdfcKycAutoData?.data?.data?.quote) ||
        hdfcKycAutoData?.data?.message?.toUpperCase() ===
        "Service Unavailable".toUpperCase() ||
        hdfcKycAutoData?.data?.message?.toUpperCase() ===
        "Internal Server Error".toUpperCase()
      ) {
        window.location.replace(hdfcKycAutoData?.data?.data?.quote?.data?.redirect_link);
      }
    })();
  }, [hdfcKycAutoData]);

  // united
  useEffect(() => {
    (async function () {
      if (
        !unitedAutoKyc?.isLoading &&
        !unitedAutoKyc?.isError &&
        unitedAutoKyc?.data?.data?.quote
      ) {
        let data = {
          uuid: id,
          bodyData: bodyData.data,
          companyName: CompanyName,
          formData,
          formValue: "FormDetails",
          stepperValue: 7,
          type: "update_proposal",
          kycAutoData: unitedAutoKyc,
        };
        dispatch(transactionURLAction(data));
        if (unitedAutoKyc?.data?.data?.quote?.kyc_verification_status == "N") {
          setLoading(true);
          let unitedPortalKyc = unitedAutoKyc?.data?.data?.quote?.url
          dispatch(unitedAutoKycReducer.actions.resetunitedAutoKycAction());
          window.location.replace(unitedPortalKyc);
        } else {
          setIsDisabledButton(false);
          handleFormReveal("kycDetails");
        }
      } else if (
        (unitedAutoKyc?.isError && !unitedAutoKyc?.data?.data?.quote) ||
        unitedAutoKyc?.data?.message?.toUpperCase() ===
        "Service Unavailable".toUpperCase() ||
        unitedAutoKyc?.data?.message?.toUpperCase() ===
        "Internal Server Error".toUpperCase()
      ) {
        toast.error("Something went wrong. Please try again.", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    })();
  }, [unitedAutoKyc]);

  useEffect(() => {
    dispatch(getStateAction("statename"));
    return () => { };
  }, []);

  useEffect(() => {
    dispatch(getCityAction(formData?.state));
    return () => { };
  }, [formData?.state]);

  useEffect(() => {
    dispatch(getInsurerList("insurename"));
  }, []);

  const getInsurerListResponse = useSelector((state) => {
    return state?.getInsurerDetails?.data?.data?.result;
  });

  const insurerList = getInsurerListResponse?.map((data) => {
    return {
      label: data?.name_of_insurance_company,
      value: data?.name_of_insurance_company,
    };
  });

  const handleFinancier = (value) => {
    setFormData((prevItems) => ({
      ...prevItems,
      carHypothecationCompany: value?.value,
    }));
  };

  const handleStateValue = (value) => {
    delete errors?.state
    setFormData((prevItems) => ({
      ...prevItems,
      state: value?.value,
      city: null
    }));
  };

  const handleCityValue = (value) => {
    delete errors?.city
    setFormData((prevItems) => ({
      ...prevItems,
      city: value?.value,
    }));
  };

  const handleBundleInsurer = (value) => {
    delete errors?.prevPolicyNameInsurer;
    setFormData((prevItems) => ({
      ...prevItems,
      prevPolicyNameInsurer: value?.value,
    }));
    if (CompanyName === SHREERAM && hideInsurerInCaseBundleShriRam === true) {
      // if (name === "prevPolicyNameInsurer") {
      setFormData((prevItems) => ({
        ...prevItems,
        prevPolicyNameInsurer: value?.value,
        prevOdPolicyNameInsurer: value?.value,
      }))
      // }
    }
  };

  const handleOdInsurer = (value) => {
    delete errors?.prevOdPolicyNameInsurer
    setFormData((prevItems) => ({
      ...prevItems,
      prevOdPolicyNameInsurer: value?.value,
    }));
  };
  const validateInput = () => {
    console.log(Day, typeof (Day), "DayDayDay")
    const newErrors = {};

    let regpan = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/;
    var regaadhar = /^[2-9]{1}[0-9]{11}$/;

    if (!formData?.kycPanNumber) {
      newErrors.kycPanNumber = "PAN Card is required";
    }

    if (formData?.dob === "null-null-null") {
      if (formData?.carOwner !== "isCompany") {
        newErrors.dob = "Date of Birth is required";
      } else {
        newErrors.dob = "Date of Incorporation is required";
      }
    }


    if ((formData?.dob !== "null-null-null")) {
      if (!Day || +Day < 1 || Day?.length < 2) {

        Day ? newErrors.dob = "Invalid Format of Date" : newErrors.dob = "Date  is required";

      } else if (!Month || +Month < 1 || Month?.length < 2) {
        Month ? newErrors.dob = "Invalid Format of Month" : newErrors.dob = "Month is required";

      } else if (!Year || +Year < 1900) {
        Year ? newErrors.dob = "Invalid Format of Year" : newErrors.dob = "Year is required";
      }
    }

    //set validation for city field and  addr
    if (formData.kycPanNumber) {
      if (!regpan.test(formData.kycPanNumber)) {
        newErrors.kycPanNumber = "Pan number is in Invalid Format";
      }
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleStepperRoute = async (formName) => {
    if (localStorage.getItem('stepperValue') == "ownerDetail" && DuplicateMobileEmail !== "false") {
      SetPhonestatussloading(true)
      let isPhonValid = await dispatch(
        phonevalidationAction({
          data: {
            phoneNumber: formData?.phoneNumber,
            email: formData?.carOwner == "individualOwner" && bodyData?.data?.PolicyTypeValue == "Third Party" ? formData.email : null
          }
        })
      );
      SetPhonestatussloading(false)
      localStorage.setItem("MobileExist", "false")
      if (isPhonValid?.payload?.data?.multipleNumer == false && !isPhonValid?.payload?.data?.multipleEmail) {
        setcheckphone(isPhonValid?.payload?.data?.multipleNumer)
        setcheckEmail(isPhonValid?.payload?.data?.multipleEmail)
        SettperValue()

      }
      else {
        if (isPhonValid?.payload?.data?.multipleNumer) {
          setcheckphone(isPhonValid?.payload?.data?.multipleNumer)
          setErrors({ ...errors, phoneNumber: "This Mobile number is already associated with another account." })
          setShowForm("ownerDetail");
        } if (isPhonValid?.payload?.data?.multipleEmail) {
          setcheckEmail(isPhonValid?.payload?.data?.multipleEmail)
          setErrors({ ...errors, email: "This email ID is already associated with another account. " })
          setShowForm("ownerDetail");
        }
      }
    } else {
      SettperValue()
    }
    function SettperValue() {
      let isValid = "";
      let isPersonalValid = "";
      let isValidVehicleDetails = "";
      let isValidAdd = "";
      switch (formName) {
        case "dealerKyc":
          setShowForm("dealerKyc");
          break;
        case "ownerDetail":
          if (dealer_code && CompanyName == "acko") {
            let ValidatePan = validateInput()
            if (ValidatePan) {
              setShowForm("ownerDetail");
            }

          } else {
            setShowForm("ownerDetail");
          }
          break;

        case "PersonalDetails":
          isValid = validatePersonalDetails();
          let isValidPhone = validatePhoneisExist()
          if (isValid && isValidPhone && !phonevalidationFlag?.data?.data?.multipleNumer) {
            setShowForm("PersonalDetails");
            setStepperValue(2);
          }
          break;

        case "AddressDetails":
          isPersonalValid = validateGendernMarritalDetails();
          isValid = validatePersonalDetails();
          let isValidPhone2 = validatePhoneisExist()
          if (formData.carOwner !== "isCompany" && CompanyName != ACKO) {
            if (isPersonalValid && isValidPhone2 && !phonevalidationFlag?.data?.data?.multipleNumer) {
              setShowForm("AddressDetails");
              setStepperValue(3);
            }
          } else {
            if (isValid && isValidPhone2 && !phonevalidationFlag?.data?.data?.multipleNumer) {
              setShowForm("AddressDetails");
              setStepperValue(3);
            }
          }

          break;

        case "VehicleDetails":

          isValidAdd = validateAddressDetails();
          isPersonalValid = validateGendernMarritalDetails();
          isValid = validatePersonalDetails();
          if (isValidAdd && (CompanyName == "acko" ? true : isPersonalValid) && isValid && !phonevalidationFlag?.data?.data?.multipleNumer) {
            setShowForm("VehicleDetails");
            setStepperValue(4);
          }
          break;

        case "kycDetails":
        
          isValidVehicleDetails = validateVehicleDetails();
          isPersonalValid = validateGendernMarritalDetails();
          isValid = validatePersonalDetails();
          if (isValidVehicleDetails && isPersonalValid && isValid && !phonevalidationFlag?.data?.data?.multipleNumer ) {
            // setShowForm("kycDetails");
            let x=ValidateEngineChassis(false,"kycDetails")
            setStepperValue(5);
          }
          break;

        default:
          break;
      }
    }
    localStorage.setItem('stepperValue', formName)
  };
  async function ValidateEngineChassis(data,formValue){
    SetPhonestatussloading(true)
    let EngineChassis = await dispatch(
      phonevalidationAction({
        data: {
          engine: formData?.engineNumber,
          chassisNumber: formData.chassisNumber
        }

      })
    );
    SetPhonestatussloading(false)
    if(EngineChassis?.payload?.data?.multipleChassisNumber == false && !EngineChassis?.payload?.data?.multipleEngine){
      setShowForm(formValue);
      if(data){
        await dispatch(transactionURLAction(data));
      }
     
    }else {
      const newErrors = { ...errors };
      if (EngineChassis?.payload?.data?.multipleEngine) {
        // setcheckphone(isPhonValid?.payload?.data?.multipleNumer)
        newErrors.engineNumber = "An active policy is linked to this engine number.";
      } if (EngineChassis?.payload?.data?.multipleChassisNumber) {
        // setcheckEmail(isPhonValid?.payload?.data?.multipleEmail)
        newErrors.chassisNumber = "An active policy is linked to this chassis number.";
      }
      setErrors(newErrors);

    }
  }
  const handleFormReveal = async (formName, e, editFormName) => {
    if (e) {
      e.preventDefault();
    }
    switch (formName) {
      case "ownerDetail":
        let data2 = {
          uuid: id,
          bodyData: bodyData.data,
          companyName: CompanyName,
          formData,
          formValue: CompanyName == ACKO ? "AddressDetails" : "PersonalDetails",
          stepperValue: StepperValue + 1,
          type: "update_proposal"
        };
        if (DuplicateMobileEmail == "false") {
          let isValid = validatePersonalDetails();
          if (isValid) {
            await dispatch(transactionURLAction(data2));
            setShowForm(CompanyName == ACKO ? "AddressDetails" : "PersonalDetails");
            setStepperValue(StepperValue + 1);
          }
        } else {
          let isValidPhone = validatePhoneisExist()
          let isValid = validatePersonalDetails();
          if (isValid && isValidPhone) {

            SetPhonestatussloading(true)
            let isPhonValid = await dispatch(
              phonevalidationAction({
                data: {
                  phoneNumber: formData?.phoneNumber,
                  email: formData?.carOwner == "individualOwner" && bodyData?.data?.PolicyTypeValue == "Third Party" ? formData.email : null
                }

              })
            );
            SetPhonestatussloading(false)
            if (isPhonValid?.payload?.data?.multipleNumer == false && !isPhonValid?.payload?.data?.multipleEmail) {
              setcheckphone(isPhonValid?.payload?.data?.multipleNumer)
              setcheckEmail(isPhonValid?.payload?.data?.multipleEmail)
              await dispatch(transactionURLAction(data2));
              localStorage.setItem("MobileExist", "false")
              setShowForm(CompanyName == ACKO ? "AddressDetails" : "PersonalDetails");
              setStepperValue(StepperValue + 1);


            }
            else {
              if (isPhonValid?.payload?.data?.multipleNumer) {
                setcheckphone(isPhonValid?.payload?.data?.multipleNumer)
                setErrors({ ...errors, phoneNumber: "This Mobile number is already associated with another account." })
              } if (isPhonValid?.payload?.data?.multipleEmail) {
                setcheckEmail(isPhonValid?.payload?.data?.multipleEmail)
                setErrors({ ...errors, email: "This email ID is already associated with another account. " })
              }
        console.log(errors,"safkafhasjkfhsajkh")

            }
          }

        }


        break;
      case "PersonalDetails":

        let isPersonalValid = validateGendernMarritalDetails();
        if (formData.carOwner !== "isCompany") {
          if (isPersonalValid) {
            let data = {
              uuid: id,
              bodyData: bodyData.data,
              companyName: CompanyName,
              formData,
              formValue: "AddressDetails",
              stepperValue: StepperValue + 1,
              type: "update_proposal"
            };
            await dispatch(transactionURLAction(data));
            setShowForm("AddressDetails");
            setStepperValue(StepperValue + 1);
          }
        } else {
          let isValid = validatePersonalDetails();
          if (isValid) {
            let data = {
              uuid: id,
              bodyData: bodyData.data,
              companyName: CompanyName,
              formData,
              formValue: "AddressDetails",
              stepperValue: StepperValue + 1,
              type: "update_proposal"
            };
            await dispatch(transactionURLAction(data));
            setShowForm("AddressDetails");
            setStepperValue(StepperValue + 1);
          }
        }

        break;
      case "AddressDetails":

        const isValidAdd = validateAddressDetails();
        if (isValidAdd) {
          let data = {
            uuid: id,
            bodyData: bodyData.data,
            companyName: CompanyName,
            formData,
            formValue: "VehicleDetails",
            stepperValue: 4,
            type: "update_proposal"
          };
          await dispatch(transactionURLAction(data));
          setShowForm("VehicleDetails");
          setStepperValue(4);
        }
        break;
      case "vehicleDetails":

     

        const isValidVehicleDetails = validateVehicleDetails();
        if (isValidVehicleDetails) {
          SetPhonestatussloading(true)
          let EngineChassis = await dispatch(
            phonevalidationAction({
              data: {
                engine: formData?.engineNumber,
                chassisNumber: formData.chassisNumber
              }

            })
          );
          SetPhonestatussloading(false)
          console.log(EngineChassis, "EngineChassis");
          if (EngineChassis?.payload?.data?.multipleChassisNumber == false && !EngineChassis?.payload?.data?.multipleEngine) {
            // setcheckphone(isPhonValid?.payload?.data?.multipleNumer)
            // setcheckEmail(isPhonValid?.payload?.data?.multipleEmail)
            // await dispatch(transactionURLAction(data2));
            // localStorage.setItem("MobileExist", "false")
            // setShowForm(CompanyName == ACKO ? "AddressDetails" : "PersonalDetails");
            // setStepperValue(StepperValue + 1);
            if (CompanyName == ACKO || CompanyName == TATA) {
              let data = {
                uuid: id,
                bodyData: bodyData.data,
                companyName: CompanyName,
                formData,
                formValue: "FormDetails",
                stepperValue: 6,
                type: "update_proposal"
              };
              await dispatch(transactionURLAction(data));
              setShowForm("FormDetails");
              setStepperValue(6);
            } else {
              let data = {
                uuid: id,
                bodyData: bodyData.data,
                companyName: CompanyName,
                formData,
                formValue: "kycDetails",
                stepperValue: 5,
                type: "update_proposal"
              };
              await dispatch(transactionURLAction(data));
              setShowForm("kycDetails");
              setStepperValue(5);
            }
          }
          else {
            const newErrors = { ...errors };
            if (EngineChassis?.payload?.data?.multipleEngine) {
              // setcheckphone(isPhonValid?.payload?.data?.multipleNumer)
              newErrors.engineNumber = "This Engine number is already associated with another account.";
            } if (EngineChassis?.payload?.data?.multipleChassisNumber) {
              // setcheckEmail(isPhonValid?.payload?.data?.multipleEmail)
              newErrors.chassisNumber = "This Chassis Number is already associated with another account.";
            }
            setErrors(newErrors);


          }
          console.log(errors,"errorserrors")

        }
        break;
      case "kycDetails":
        if (InspectionStatus && !Edit) {
          if (CompanyName != ICICI && CompanyName != ZUNO) {
            let data = {
              uuid: id,
              bodyData: bodyData.data,
              companyName: CompanyName,
              formData,
              formValue: "InspectionDetails",
              stepperValue: StepperValue + 1,
              type: "update_proposal"
            };
            await dispatch(transactionURLAction(data));
            setShowForm("InspectionDetails");
            setStepperValue(StepperValue + 1);
          }
        } else {
          let data;
          if (CompanyName != ICICI && CompanyName != ZUNO && CompanyName != HDFC) {
            data = {
              uuid: id,
              bodyData: bodyData.data,
              companyName: CompanyName,
              formData,
              formValue: "FormDetails",
              stepperValue: StepperValue + 1,
              type: "update_proposal"
            };

          }
          if (!Edit) {
            setShowForm("FormDetails");
            await dispatch(transactionURLAction(data));
          }
          if (editFormName === "editOwnerDetail") {

            const validOwnerForm = validatePersonalDetails();
            if (DuplicateMobileEmail == "false" && validOwnerForm) {
              await dispatch(transactionURLAction(data));
              setShowForm("FormDetails");
            } else {
              let isValidPhone = validatePhoneisExist()
              if (validOwnerForm && isValidPhone) {
                SetPhonestatussloading(true)
                let isPhonValid = await dispatch(
                  phonevalidationAction({
                    data: {
                      phoneNumber: formData?.phoneNumber,
                      email: formData?.carOwner == "individualOwner" && bodyData?.data?.PolicyTypeValue == "Third Party" ? formData.email : null
                    }

                  })
                );
                SetPhonestatussloading(false)
                if (isPhonValid?.payload?.data?.multipleNumer == false && !isPhonValid?.payload?.data?.multipleEmail) {
                  setcheckphone(isPhonValid?.payload?.data?.multipleNumer)
                  setcheckEmail(isPhonValid?.payload?.data?.multipleEmail)
                  await dispatch(transactionURLAction(data));
                  setShowForm("FormDetails");
                  localStorage.setItem("MobileExist", "false")
                }
                else {
                  if (isPhonValid?.payload?.data?.multipleNumer) {
                    setcheckphone(isPhonValid?.payload?.data?.multipleNumer)
                    setErrors({ ...errors, phoneNumber: "This Mobile number is already associated with another account." })
                  } if (isPhonValid?.payload?.data?.multipleEmail) {
                    setcheckEmail(isPhonValid?.payload?.data?.multipleEmail)
                    setErrors({ ...errors, email: "This email ID is already associated with another account. " })
                  }


                }
              }
            }


          }
          else if (editFormName === 'editPersonalDetails') {
            const validPersonalForm = validateGendernMarritalDetails()
            if (validPersonalForm) {
              setShowForm("FormDetails");
              await dispatch(transactionURLAction(data));
            }
          } else if (editFormName === 'editAddressDetails') {
            const validAddress = validateAddressDetails()
            if (validAddress) {
              setShowForm("FormDetails");
              await dispatch(transactionURLAction(data));
            }
          } else if (editFormName == 'editvehicleDetails') {
            const validateVehicle = validateVehicleDetails()
            if (validateVehicle) {
              ValidateEngineChassis(data,"FormDetails")
              
            }
          }
          // setShowForm("FormDetails");
          // setStepperValue(StepperValue + 1);
        }
        break;
      case "InspectionDetails":
        let data = {
          uuid: id,
          bodyData: bodyData.data,
          companyName: CompanyName,
          formData,
          formValue: "FormDetails",
          stepperValue: StepperValue + 1,
          type: "update_proposal"
        };
        await dispatch(transactionURLAction(data));
        setShowForm("FormDetails");
        setStepperValue(StepperValue + 1);
        break;
      default:
        break;
    }
    // setEdit(false);
  };

  // VALIDATION FUNCTION FOR ALTERNATE FORMS

  console.log(ShowForm, "ShowForm");


  const validateAddressDetails = () => {
    const newErrors = {};
    if (!formData.addressOne) {
      newErrors.addressOne = "Address Line 1 is required";
    }
    if (!formData.addressTwo) {
      newErrors.addressTwo = "Address Line 2 is required";
    }
    if (!formData.state) {
      newErrors.state = "State is required";
    }
    if (!formData.city) {
      newErrors.city = "City is required";
    }
    if (!formData.pincode) {
      newErrors.pincode = "Pin code is required";
    }

    let pinregEx = /^[1-9]{1}[0-9]{2}[0-9]{3}$/;
    if (formData.pincode && !pinregEx.test(formData.pincode)) {
      newErrors.pincode = "Invalid pin code";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const validateVehicleDetails = () => {
    const newErrors = {};

    if (
      mappingAllData.length &&
      mappingAllData[0]?.typeOfBusiness?.toLowerCase() != "new"
    ) {
      if (!formData.prevPolicyNumber && (!mappingAllData[0]?.prevPolicyExpLastMonth)) {
        newErrors.prevPolicyNumber = "Previous policy Number is required";
      }
      if (!formData.prevPolicyNameInsurer && (!mappingAllData[0]?.prevPolicyExpLastMonth)) {
        newErrors.prevPolicyNameInsurer = "Insurer is required";
      }
    }
    if (!formData.chassisNumber) {
      newErrors.chassisNumber = "Chassis Number is required";
    }

    if (!formData.chassisNumber && CompanyName === "hdfc" && formData.chassisNumber.length !== 17) {
      newErrors.chassisNumber = "Chassis Number should be 17 digit ";
    }

    if (!formData.engineNumber) {
      newErrors.engineNumber = "Engine Number is required";
    }
    if (!formData.carIsInLoan) {
      newErrors.carIsInLoan = "Please select one option";
    }
    if (!formData.carHypothecationCompany && formData.carIsInLoan == "Yes") {
      newErrors.carHypothecationCompany = "Please select one option";
    }
    // if (formData.isCarPUC === "No") {
    //   newErrors.isCarPUC = "To proceed further you should have Valid PUC";
    // }

    if (
      (mappingAllData[0]?.prevPolicyType == "Bundled (3Y TP + 1Y OD)" ||
        mappingAllData[0]?.prevPolicyType == "Bundled") &&
      mappingAllData[0]?.PolicyTypeValue == "Own Damage"
    ) {
      if (!formData?.prevOdPolicyNumber) {
        newErrors.prevOdPolicyNumber = "Previous Own Damage Number is required";
      }

      if (!formData?.prevOdPolicyNameInsurer) {
        newErrors.prevOdPolicyNameInsurer =
          "Previous Own Damage Insurer is required";
      }
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;

  };



  const validatePersonalDetails = () => {
    const newErrors = {};
    if (formData.carOwner !== "isCompany") {
      if (!formData.firstName) {
        newErrors.firstName = "First Name is required";
      }
      if (!formData.lastName) {
        newErrors.lastName = "Last Name is required";
      }
    }

    if (!formData.email) {
      newErrors.email = "Email is required";
    }

    let regexEmail = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,3}$/;
    if (formData.email && !regexEmail.test(formData.email)) {
      newErrors.email = "Email is Invalid";
    }

    let regexMobile = /^[2-9]{1}[0-9]{9}$/;
    if (!formData.phoneNumber) {
      newErrors.phoneNumber = "Phone Number is required";
    }


    if (formData.phoneNumber && !regexMobile.test(formData.phoneNumber)) {
      newErrors.phoneNumber = "Mobile number is Invalid";
    }

    if (formData.carOwner === "isCompany") {
      if (!formData.companyName) {
        newErrors.companyName = "Company Name is required";
      }

      if (!formData.dob && (CompanyName == ICICI)) {
        newErrors.dob = "Date of Incorporation is required";
      }

      if (!formData.dob && (CompanyName == DIGIT)) {
        newErrors.dob = "Date of Incorporation is required";
      }

      if (!formData.kycPanNumber && (CompanyName == "tata")) {
        newErrors.CertificateOfIncorporationNumber = "Pan number is required";
      }
    }

    if (!isPaCoverChecked && CompanyName === SHREERAM) {
      if (!formData.paExclusionReason) {
        newErrors.paExclusionReason = "PA Exclusion reason is required";
      }
    }

    if (checkphone) {
      newErrors.phoneNumber = "This Mobile number is already associated with another account. ";
    }

    setErrors(newErrors);
    return Object?.keys(newErrors)?.length === 0;
  };

  const validateGendernMarritalDetails = () => {
    const newErrors = {};
    if (!formData.gender) {
      newErrors.gender = "Gender is required";
    }

    if (!formData.maritalStatus) {
      newErrors.maritalStatus = "Marital Status is required";
    }

    if (!formData.nomineeName) {
      newErrors.nomineeName = "Nominee Name is required";
    }

    if (!formData.nomineeAge) {
      newErrors.nomineeAge = "Age is required";
    }

    if (!formData?.nomineeRelation) {
      newErrors.nomineeRelation = "Nominee Relation is required";
    }

    if (!formData.dob) {
      newErrors.dob = "Date of Birth is required"
    }

    if (formData.dob) {
      var userDOB = new Date(formData.dob);
      var currentDate = new Date();
      var age = currentDate.getFullYear() - userDOB.getFullYear();

      if (
        currentDate.getMonth() < userDOB.getMonth() ||
        (currentDate.getMonth() === userDOB.getMonth() &&
          currentDate.getDate() < userDOB.getDate())
      ) {
        age--;
      }

      if (age >= 18) {
        console.log("The user is 18 years or older. dobValidation");
      } else {
        newErrors.dob = "You must be 18+ to proceed further";
      }
    }


    if (formData?.nomineeAge < 18 && CompanyName === SHREERAM && formData?.nomineeAge !== "") {
      if (!formData?.appointeeName) {
        newErrors.appointeeName = "Appointee Name is required";
      }
      if (!formData?.appointeeRelation) {
        newErrors.appointeeRelation = "Appointee Relation is required";
      }
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;

  };

  const ValidateShareLinkDetails = () => {
    const newErrors = {};
    if (!phoneNoforSharelink) {
      newErrors.phoneNumberforShareLink = "Phone Number is required";
    }
    let regexMobile = /^[2-9]{1}[0-9]{9}$/;
    if (phoneNoforSharelink && !regexMobile.test(phoneNoforSharelink)) {
      newErrors.phoneNumberforShareLink = "Mobile number is Invalid";
    }
    let regexEmail = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,3}$/;
    if (emailShareLink && !regexEmail.test(emailShareLink)) {
      newErrors.emailforShareLink = "Email is Invalid";
    }
    if (!emailShareLink) {
      newErrors.emailforShareLink = "Email is required";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;

  };

  const validateKycDetails = () => {
    const newErrors = {};

    if (
      AutoKYCrejected &&
      CompanyName == "icici" &&
      formData.carOwner == "isCompany" &&
      !formData.kycPanNumber
    ) {
      newErrors.kycPanNumber = "Pan number is required";
    }
    if (
      AutoKYCrejected &&
      CompanyName == "icici" &&
      formData.carOwner !== "isCompany" &&
      !formData.kycPanNumber && !formData?.kycAadharNumber
    ) {
      newErrors.kycPanNumber = "Pan number is required";
    }

    let regpan = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/;
    if (

      formData.typeOfKycDocument &&  ///some change for validation for icici
      formData.typeOfKycDocument == "Pan Card" &&
      formData.kycPanNumber &&
      !regpan.test(formData.kycPanNumber)
    ) {
      newErrors.kycPanNumber = "Pan number is in invalid format";
    }
    if (!AutoKYCrejected && (CompanyName == "icici") && !formData?.kycPanNumber && formData?.typeOfKycDocument == "PAN Card") {
      newErrors.kycPanNumber = "Enter PAN Number";
    }
    if ((CompanyName == "digit" || CompanyName == "zuno") && !formData?.kycPanNumber) {
      newErrors.kycPanNumber = "Enter PAN Number";
    }

    var regaadhar = /^[2-9]{1}[0-9]{11}$/;
    if (

      formData.typeOfKycDocument &&     ///some change for validation for icici
      formData.typeOfKycDocument == "Aadhar Card" &&
      CompanyName !== "icici" &&
      !formData.kycAadharNumber
    ) {
      newErrors.kycAadharNumber = "Aadhar number is required";
    }

    if (
      formData.kycAadharNumber &&
      !regaadhar.test(formData.kycAadharNumber)
    ) {
      // invalid aadhar card number
      newErrors.kycAadharNumber = "Aadhar number is in invalid format";
    }
    if (AutoKYCrejected && CompanyName == "icici" && !formData?.kycAadharNumber && formData?.carOwner !== "isCompany") {
      newErrors.kycAadharNumber = "Enter Aadhar Number";
    }
    if (!AutoKYCrejected && CompanyName == "icici" && !formData?.kycAadharNumber && formData?.carOwner !== "isCompany" && formData?.typeOfKycDocument == "Aadhar Card") {
      newErrors.kycAadharNumber = "Enter Aadhar Number";
    }



    if (
      AutoKYCrejected && formData.carOwner == "individualOwner" &&
      !formData.kycAadharImg && formData.typeOfKycDocument == "Aadhar Card"
    ) {
      newErrors.kycAadharImg = "Aadhar Front Image is required";
    }

    if (
      AutoKYCrejected && formData.carOwner == "individualOwner" &&
      !formData.kycAadharImgBack && CompanyName !== "tata" && formData.typeOfKycDocument == "Aadhar Card"
    ) {
      newErrors.kycAadharImgBack = "Aadhar Back Image is required";
    }

    if (
      !AutoKYCrejected &&
      CompanyName !== "sbi" &&
      CompanyName !== "icici" &&
      CompanyName !== "digit" &&
      CompanyName !== "zuno" &&
      CompanyName !== "acko" &&
      CompanyName !== "hdfc" &&
      CompanyName !== "united" &&
      !formData.kycPanImg
    ) {
      newErrors.kycPanImg = "Pan Image is required";
    }

    if (
      AutoKYCrejected &&
      CompanyName == "icici" &&
      formData.carOwner == "isCompany" &&
      !formData.kycPanImg
    ) {
      newErrors.kycPanImg = "Pan Image is required";
    }

    if (!AutoKYCrejected && !formData.kycAadharNumber &&
      CompanyName == "icici" &&
      formData.carOwner !== "isCompany" &&
      !formData.kycPanNumber) {
      newErrors.kycPanNumber = "Pan Number is required";
    }

    if (
      CompanyName == "shreeram" &&
      formData.carOwner == "isCompany" &&
      !formData.kycPanNumber
    ) {
      newErrors.kycPanNumber = "Pan Number is required";
    }

    if (
      !AutoKYCrejected &&
      CompanyName == "shreeram" &&
      formData.carOwner == "isCompany" &&
      !formData.CertificateOfIncorporationFile
    ) {
      newErrors.CertificateOfIncorporationFile =
        "Certificate of Incorporation is required";
    }

    if (
      AutoKYCrejected &&
      CompanyName == "icici" &&
      formData.carOwner == "isCompany" &&
      !formData.CertificateOfIncorporationFile
    ) {
      newErrors.CertificateOfIncorporationFile =
        "Certificate of Incorporation is required";
    }

    if (
      AutoKYCrejected && CompanyName == "icici" && formData.carOwner == "isCompany" &&
      !formData.CertificateOfIncorporationNumber
    ) {
      newErrors.CertificateOfIncorporationNumber =
        "Certificate of Incorporation is required";
    }

    if (!AutoKYCrejected &&
      CompanyName == "icici" &&
      formData.carOwner == "isCompany" &&
      !formData.CertificateOfIncorporationNumber && formData?.typeOfKycDocument == "Certificate of Incorporation"
    ) {
      newErrors.CertificateOfIncorporationNumber =
        "Certificate of Incorporation is required";
    }




    if (
      ((!AutoKYCrejected || CompanyName !== "sbi") && CompanyName !== "icici") &&
      (!formData.CertificateOfIncorporationNumber && formData.carOwner == "isCompany" && CompanyName !== "digit" && CompanyName !== "zuno")
    ) {
      if (CompanyName == "shreeram" && failCheker) {
        newErrors.CertificateOfIncorporationNumber =
          "Certificate of Incorporation is required";
      }
      if (CompanyName !== "shreeram") {
        newErrors.CertificateOfIncorporationNumber =
          "Certificate of Incorporation is required";
      }

    }

    if (CompanyName == "shreeram" && failCheker) {
      if (!formData.kycProfileImg) {
        newErrors.kycProfileImg = "Profile Image is required";
      }

      if (formData.carOwner !== "isCompany") {
        if (!formData.fathersName) {
          newErrors.fathersName = "Father Name is required";
        }

        if (!formData.kycPanImg || formData.kycPanImg === "undefined") {
          newErrors.kycPanImg = "Pan Image is required";
          newErrors.typeOfKycDocument = "Please upload Aadhar and Pan details";
        }

        if (!formData.kycPanNumber) {
          newErrors.kycPanNumber = "Pan number is required";
          newErrors.typeOfKycDocument = "Please upload Aadhar and Pan details";
        }

        if (!formData.kycAadharNumber) {
          newErrors.kycAadharNumber = "Aadhar number is required";
          newErrors.typeOfKycDocument = "Please upload Aadhar and Pan details";
        }

        if (!formData.kycAadharImg) {
          newErrors.kycAadharImg = "Aadhar Front Image is required";
          newErrors.typeOfKycDocument = "Please upload Aadhar and Pan details";
        }

        if (!formData.kycAadharImgBack || formData.kycAadharImgBack === "undefined") {
          newErrors.kycAadharImgBack = "Aadhar Back Image is required";
          newErrors.typeOfKycDocument = "Please upload Aadhar and Pan details";
        }

        if (formData.kycPanNumber && !regpan.test(formData.kycPanNumber)) {

          newErrors.kycPanNumber = "Pan number is in invalid format";
        }
        if (formData.kycAadharNumber && !regaadhar.test(formData.kycAadharNumber)) {

          newErrors.kycAadharNumber = "Adhar number is not correct";
        }
      }

      if (formData.carOwner == "isCompany") {

        if (!formData.kycPanImg) {
          newErrors.kycPanImg = "Pan Image is required";
          newErrors.typeOfKycDocument = "Please upload Certificate of Incorporation and Pan details";
        }

        if (!formData.kycPanNumber) {
          newErrors.kycPanNumber = "Pan number is required";
          newErrors.typeOfKycDocument = "Please upload Aadhar and Pan details";
        }
        if (CompanyName == "shreeram" && failCheker) {
          if (!formData.CertificateOfIncorporationFile) {
            newErrors.CertificateOfIncorporationFile =
              "Certificate of Incorporation is required";
            newErrors.typeOfKycDocument = "Please upload Certificate of Incorporation and Pan details";
          }

          if (!formData.CertificateOfIncorporationNumber) {
            newErrors.CertificateOfIncorporationNumber =
              "Certificate of Incorporation is required";
            newErrors.typeOfKycDocument = "Please upload Certificate of Incorporation and Pan details";
          }

        } else {
          if (!formData.CertificateOfIncorporationFile) {
            newErrors.CertificateOfIncorporationFile =
              "Certificate of Incorporation is required";
            newErrors.typeOfKycDocument = "Please upload Certificate of Incorporation and Pan details";
          }

          if (!formData.CertificateOfIncorporationNumber) {
            newErrors.CertificateOfIncorporationNumber =
              "Certificate of Incorporation is required";
            newErrors.typeOfKycDocument = "Please upload Certificate of Incorporation and Pan details";
          }
        }


      }
    }

    if (CompanyName == "digit" && formData.carOwner !== "isCompany") {
      // if (!formData.kycProfileImg) {
      //   newErrors.kycProfileImg = "Profile Image is required";
      // }
    }

    if (AutoKYCrejected && CompanyName == "sbi" && formData.carOwner == "isCompany") {

      if (!formData?.CertificateOfIncorporationFile) {
        newErrors.CertificateOfIncorporationFile = "Certificate Of Incorporation is required";
      }

      if (!formData?.CertificateOfIncorporationNumber) {
        newErrors.CertificateOfIncorporationNumber = "Certificate Of Incorporation Number is required";
      }

    }
    if (CompanyName == TATA && !formData.kycPanNumber) {
      newErrors.kycPanNumber = "Pan number is required";
    }

    if (formData.kycPanNumber) {
      if (!regpan.test(formData.kycPanNumber)) {
        newErrors.kycPanNumber = "Pan number is in Invalid Format";
      }
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  const validateKycDetails2 = () => {
    const newErrors = {};
    if (
      !formData.kycPanImgFileType
    ) {
      newErrors.kycPanImg = "Pan Image is required";
    }
    if (!formData.kycPanNumber) {
      newErrors.kycPanNumber = "Pan number is required";

    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  const handlekycdata = (() => {
    let chekValid = validateKycDetails2()
    if (chekValid) {
      if (formData?.kycPanImgFileType) {
        setShreeRamKyc(true)
        setShreeRamKyccorp(true)
        setFailCheker(true)
      }
    }
  })

  useEffect(() => {
    setDobClone(formData?.dob);
  }, [formData?.dob])

  const handleDobClone = (value, name, emptyString) => {
    setDobClone(new Date(value));
  }

  const cancelCalendarValue = (name) => {
    setDobClone(formData?.dob);
    setShowDobCalendar(false);
  }

  const saveCalendarValue = (name) => {
    let dobValue = ''

    if (DobClone !== undefined && DobClone !== "Invalid Date" && DobClone !== "") {
      dobValue = new Date(DobClone);
      // Set the time part to 00:00:00
      dobValue.setHours(0);
      dobValue.setMinutes(0);
      dobValue.setSeconds(0);

      setFormData((prevItems) => ({
        ...prevItems,
        dob: dobValue,
      }))

      setShowDobCalendar(false);
    }
  }

  // exclude zero dep in shreeram, if there is No Zero Dep in previous policy.

  useEffect(() => {
    async function triggerQuickQuote() {
      if (formData?.isNilDepInPrevPolicy === "true") {
        setTriggerApi(false);
        setLoading(true);
        if (CompanyName == "shreeram") {
          let response = await dispatch(
            shreeRamQuickQuoteAction({
              data: {
                ...bodyData.data,
                ...{ coverage: coverages },
              },
            })
          );
          setLoading(false);
        }
      } else if (formData?.isNilDepInPrevPolicy === "false") {
        setLoading(true);
        if (CompanyName == "shreeram") {
          let response = await dispatch(
            shreeRamQuickQuoteAction({
              data: {
                ...bodyData.data,
                ...{ coverage: [getPreviousAddonData?.coverage[0]] },
              },
            })
          );
          setLoading(false);
          setTriggerApi(true);
        }
      }
    }
    if (bodyData && CompanyName === SHREERAM) {
      triggerQuickQuote();
    } else {
      return () => { }
    }
  }, [bodyData?.data?.isNilDepInPrevPolicy]);

  const getOtpTata = (otp) => {
    if (otp.length == 6) {
      (async function () {
        setStoreTataOtp(otp);
        closeModal(otp);
        setLoading(true);
        let otpVerify = await dispatch(tataOtpVerifyAction({
          data: {
            proposalNo:
              tataProposalRes?.data?.data?.quote?.data[0]?.proposal_no,
            proposalBody:
              bodyData?.data,
            clientId:
              tataAutoKyc?.data?.data?.quote?.data?.client_id,
            otp:
              otp
          }
        }));
        if (otpVerify?.payload?.data?.quote?.message_txt == "Verification Successful") {
          // insert db
          let body = {
            companyname: CompanyName,
            transactionid: tataProposalRes.data.data.quote.data[0].proposal_no,
            bodydata: {
              tata: tataProposalRes?.data,
            },
            type: "initiate",
          };
          await dispatch(paymentWebhookAction(body));

          // start payment
          let onlinePayment = await dispatch(tataOnlinePaymentAction({
            data: {
              ...bodyData.data,
              ...{
                paymentId:
                  tataProposalRes.data.data.quote.data[0].payment_id,
              },
            }
          }));
          if (onlinePayment?.payload?.data?.quote?.message_txt == "Success") {
            let paymentLink = JSON.parse(onlinePayment?.payload?.data?.quote?.data)
            document.getElementById("myForm").action = paymentLink?.url;
            document.getElementsByName("pgiRequest")[0].value = paymentLink?.pgiRequest;
            dispatch(tataProposalReducer.actions.resettataProposalAction());
            document.getElementById("invisibleSubmitButtonTata").click(); // Programmatically click the invisible button
          } else {
            setLoading(false);
            toast.error(onlinePayment?.payload?.data?.quote?.message_txt ? onlinePayment?.payload?.data?.quote?.message_txt : "Something went wrong !", {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
            dispatch(tataAutoKycReducer.actions.resettataAutoKycAction());
            dispatch(tataProposalReducer.actions.resettataProposalAction());
          }
        } else {
          setLoading(false);
          toast.error(otpVerify?.payload?.data?.quote?.message_txt ? otpVerify?.payload?.data?.quote?.message_txt : "Something went wrong !", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          dispatch(tataAutoKycReducer.actions.resettataAutoKycAction());
          dispatch(tataProposalReducer.actions.resettataProposalAction());
          await setFormData((prevItems) => ({
            ...prevItems,
            kycAadharNumber: '',
          }))
        }
      })();
    }
  }

  const [confirmImg, setConfirmImg] = useState(true)

  const HandleSubmitShareLinkData = () => {
    const isValid = ValidateShareLinkDetails()
    // setFormData((prev) => ({
    //   ...prev,
    //   phoneNumber: phoneNoforSharelink,
    //   email: emailShareLink
    // }
    // ))

    let shareLinkObj = {
      firstName: formData?.firstName,
      lastName: formData?.lastName,
      email: emailShareLink,
      phoneNumber: phoneNoforSharelink,
      make: bodyData?.data?.make,
      model: bodyData?.data?.model,
      varient: bodyData?.data?.varient,
      fuelType: bodyData?.data?.fuelType,
      cc: bodyData?.data?.cc,
      vehicleNumber: bodyData?.data?.vehicleNumber,
      companyName: CompanyName,
      grossPremium: vehicleStructureDetails?.grossPremium,
      urlLink: fullUrl,
      coverage: formData?.coverage,
      PolicyTypeValue: bodyData?.data?.PolicyTypeValue
    }

    if (isValid) {
      dispatch(
        shareLinkAction({
          data: shareLinkObj,
        })
      );
    }
  }

  useEffect(() => {
    if (ShreLinkData?.data?.status == 1) {

      setConfirmImg(false);
      setTimeout(() => {
        setConfirmImg(true)
        closePaaymentLinkModal()
      }, 3000)
    }

  }, [ShreLinkData])
  console.log(ShowForm, "ShowFormShowForm");

  // ShreLink Handle Function
  function HandleShareLink() {
    setShowPaymentLink(true)
    setemailSharelink(formData?.email)
    setphoneNoforSharelink(formData?.phoneNumber)
  }

  const closePaaymentLinkModal = () => {
    setShowPaymentLink(false)
  }

  useEffect(() => {
    if (loading || loading1) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }
    return () => {
      document.body.style.overflow = "";
    };
  }, [loading, loading1]);
  console.log(ShowForm, "ShowFormShowForm")
  return (
    <div className="main-proposalForm-container">
      <Navbar />
      <ToastContainer />
      <Modal
        isOpen={carNoPopup}
        onRequestClose={closePaaymentLinkModal}
        style={window.innerWidth <= 610 ? customStylesForMobile : customStyles}
      >
        <div className="NoQuoetFondBoxmainContainer">
          <div style={{ display: "flex", justifyContent: "end", cursor: "pointer" }} onClick={HnaldeCloseNotFoundQouetModal}>
            {/* <img src={Close} alt="" /> */}
          </div>
          <div className="NoQuoetFondBox">
            <div>
              <img src={CompanyImage} alt="" />
            </div>
            <div className="secodboxfornoQuoet">
              <div><img src={caution} alt="" /></div>
              <div>Something Went Worng</div>
            </div >
            <h4>Vehicle Number is Missing!</h4>
            {/* <Botton className="NoQuoetBtn" text="Check  Number" event={(e) => HnaldeCloseNotFoundQouetModal(e)} /> */}
            {/* <div className="NoQuoetFondBoxboxthree">
              {CompanyName} is not providing the quote because selected vehicle is not mapped with the insurer.
            </div> */}
          </div>
        </div>
      </Modal>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
      >
        <OtpModal
          closeModal={closeModal}
          openModal={openModal}
          getOtp={getOtpTata}
        />
      </Modal>

      {/* payment link */}

      <Modal
        isOpen={ShowPaymentLink}
        onRequestClose={closePaaymentLinkModal}
        style={customStyles}
      >
        <div className="paymentLinkBox">
          {confirmImg ?
            <div className="paymentLinkBox_Container">
              <p className="link_Text">Reconfirm your contact details</p>
              <label className="label_shareLink">Mobile Number</label>
              <div className="inputGropOne_form inputGrop ">
                <InputFields
                  value={phoneNoforSharelink}
                  placeholder="Phone Number"
                  id="phoneNumberforShareLink"
                  type="number"
                  name="phoneNumberforShareLink"
                  hanldeInput={errors}
                  errState={errors?.phoneNumberforShareLink ? true : false}
                  errMsg={errors?.phoneNumberforShareLink}
                  errClassName="input-err"
                  onChangeEvent={(e) => {
                    let val = e.target.value;
                    if (val?.length <= 10) {
                      setphoneNoforSharelink(e.target.value)
                    }
                  }}

                />
              </div>
              <label className="label_shareLink">Email</label>
              <div className="inputGropOne_form inputGrop ">
                <InputFields
                  value={emailShareLink}
                  placeholder="Email ID"
                  id="email"
                  type="email"
                  name="emailforShareLink"
                  hanldeInput={errors}
                  errState={errors?.emailforShareLink ? true : false}
                  errMsg={errors?.emailforShareLink}
                  errClassName="input-err"
                  onChangeEvent={(e) => setemailSharelink(e.target.value)}

                />
              </div>
              <section className="btnContainer_pform btnContainer_paymentLink" >
                <Botton
                  text="Cancel"
                  className="button btn_pForm  secondry_btn"
                  event={() => closePaaymentLinkModal()}
                />{
                  ShreLinkData?.isLoading ?
                    <ButtonLoader className="btn_pForm" />
                    :
                    <Botton
                      text="Share Link"
                      type="submit"
                      className="button btn_pForm"
                      event={(e) =>
                        HandleSubmitShareLinkData(e)
                      }
                    />
                }
              </section>
            </div> : <div className="confirm_Container">
              <div className="confirmImg">
                <img src={confrmationShareLink} alt="" />
                <p className="confirmText">Proposal Link Sent!</p>
              </div >
            </div>}
        </div>
      </Modal>

      {/* <div id="cover-spin" style={{ display: loading ? "" : "none" }}>
        <img src={loader2} className="gifLoader"></img>
      </div>

      <div id="cover-spin" style={{ display: loading1 ? "" : "none" }}>
        <img src={loader2} className="gifLoader"></img>
      </div> */}

      <div
        className={
          !ShowKycInputs
            ? "PropsalFormContainer"
            : "PropsalFormContainer_padding PropsalFormContainer"
        }
      >
        <div className="stepperWithForm maxWidth">
          <div className={ShowForm === "FormDetails" ? "hideWithOpacity" : ""}>
            {!ShowKycInputs && (
              <Stepper
                ShowForm={ShowForm}
                StepperValue={StepperValue}
                InspectionStatus={InspectionStatus}
                handleStepperRoute={handleStepperRoute}
                formData={formData}
                ploicyTypeforIns={mappingAllData[0]?.PolicyTypeValue}
              />
            )}
          </div>
          <div
            className={
              ShowKycInputs === false
                ? "PropsalFormContainer_parent "
                : "padding_PropsalFormContainer_parent PropsalFormContainer_parent"
            }
            onClick={handlePopUps}
          >
            <>
              {
                ShowForm === "ownerDetail" ? (
                  <>
                    <p
                      className="mobile-back-fmc mobile-back-pform"
                      onClick={() => navigate("/View-Plans?id=" + id)}
                    >
                      <img src={backIcon} alt="" srcSet="" /> Back to Plans
                    </p>
                    <div className="breakupContainer PropsalFormContainer_child">
                      <div className="PremiumContainer-div" >
                        {vehicleType == "2W" ?
                          <PremiumContainerBike
                            CompanyImage={CompanyImage}
                            CompanyName={CompanyName}
                            PolicyTypeValue={bodyData?.data?.PolicyTypeValue}
                            mappingAllData={mappingAllData}
                            bodyData={bodyData}
                          />
                          : <PremiumContainer
                            CompanyImage={CompanyImage}
                            CompanyName={CompanyName}
                            PolicyTypeValue={bodyData?.data?.PolicyTypeValue}
                            mappingAllData={mappingAllData}
                            bodyData={bodyData}
                          />
                        }
                      </div>
                    </div>
                    <div id="PropsalFormContainer_child-formFields" className="formContainer PropsalFormContainer_child">
                      <ProposalOne
                        nameValue={formData.ownerName}
                        emailValue={formData.email}
                        phoneValue={formData.phoneNumber}
                        panValue={formData.kycPanNumber}
                        ownerValue={formData.carOwner}
                        gstValue={formData.gstNumber}
                        compNameValue={formData.companyName}
                        kycStatus={ShowKycInputs}
                        formData={formData}
                        errors={errors}
                        errors2={errors2}
                        Edit={Edit}
                        setEdit={setEdit}
                        bodyData={bodyData}
                        handleSubmitForm={handleFormReveal}
                        HandleInputChange={(e) => { handleInputChange(e); }}
                        companyName={CompanyName}
                        sbiAutoKycData={sbiAutoKycData}
                        dateValue={formData.dob}
                        handleBackBtn={handleBackBtn}
                        isPaCoverChecked={isPaCoverChecked}
                        ShowNilDepState={ShowNilDepState}
                        handleDobClone={handleDobClone}
                        saveCalendarValue={saveCalendarValue}
                        cancelCalendarValue={cancelCalendarValue}
                        ShowDobCalendar={ShowDobCalendar}
                        setShowDobCalendar={setShowDobCalendar}
                        DobClone={DobClone}
                        vehicleType={vehicleType}
                        phonestatussloading={phonestatussloading}
                        checkphone={checkphone}
                        panDobConataier={panDobConataier}
                        setpanDobConataier={setpanDobConataier}
                        kycPanNumber={formData.kycPanNumber}
                        setFormData={setFormData}
                        setErrors={setErrors}
                        setShowForm={setShowForm}
                      />
                    </div>
                  </>
                ) : ShowForm === "PersonalDetails" ? (
                  <>
                    <p
                      className="mobile-back-fmc mobile-back-pform"
                      onClick={() => handleBackBtn("PersonalDetails")}
                    >
                      <img src={backIcon} alt="" srcSet="" /> Back to Owner
                      Details
                    </p>
                    <div className="breakupContainer PropsalFormContainer_child">
                      <div className="PremiumContainer-div">
                        <PremiumContainer
                          CompanyImage={CompanyImage}
                          CompanyName={CompanyName}
                          PolicyTypeValue={bodyData?.data?.PolicyTypeValue}
                          mappingAllData={mappingAllData}
                          bodyData={bodyData}
                        />
                      </div>
                    </div>
                    <div id="PropsalFormContainer_child-formFields" className="formContainer PropsalFormContainer_child">
                      <ProposalTwo
                        HandleInputChange={(e) => { handleInputChange(e) }}
                        handleSubmitForm={handleFormReveal}
                        Edit={Edit}
                        // handleDatePickerValue={(e) => handleDatePickerValue(e)}
                        dateValue={formData.dob}
                        nomineeName={formData.nomineeName}
                        nomineeAge={formData.nomineeAge}
                        nomineeRelation={formData.nomineeRelation}
                        handleBackBtn={handleBackBtn}
                        formData={formData}
                        DobClone={DobClone}
                        errors={errors}
                        bodyData={bodyData}
                        companyName={CompanyName}
                        sbiAutoKycData={sbiAutoKycData}
                        handleDobClone={handleDobClone}
                        saveCalendarValue={saveCalendarValue}
                        cancelCalendarValue={cancelCalendarValue}
                        ShowDobCalendar={ShowDobCalendar}
                        setShowDobCalendar={setShowDobCalendar}
                      />
                    </div>
                  </>
                ) : ShowForm === "AddressDetails" ? (
                  <>
                    <p
                      className="mobile-back-fmc mobile-back-pform"
                      onClick={() => handleBackBtn("AddressDetails")}
                    >
                      <img src={backIcon} alt="" srcSet="" /> Back to Personal
                      Details
                    </p>
                    <div className="breakupContainer PropsalFormContainer_child">
                      <div className="PremiumContainer-div">
                        <PremiumContainer
                          CompanyImage={CompanyImage}
                          CompanyName={CompanyName}
                          PolicyTypeValue={bodyData?.data?.PolicyTypeValue}
                          mappingAllData={mappingAllData}
                          bodyData={bodyData}
                        />
                      </div>
                    </div>
                    <div id="PropsalFormContainer_child-formFields" className="formContainer PropsalFormContainer_child">
                      <PropsalThree
                        HandleInputChange={(e) => { handleInputChange(e); }}
                        handleStateValue={handleStateValue}
                        handleCityValue={handleCityValue}
                        handleSubmitForm={handleFormReveal}
                        handleCityDropdown={(e) =>
                          setShowCityDropdown(!ShowCityDropdown)
                        }
                        ShowCityDropdown={ShowCityDropdown}
                        stateList={stateList}
                        cityList={cityList}
                        state={formData?.state}
                        city={formData?.city}
                        pincode={formData?.pincode}
                        bodyData={bodyData}
                        addressOne={formData?.addressOne}
                        addressTwo={formData?.addressTwo}
                        handleBackBtn={handleBackBtn}
                        formData={formData}
                        Edit={Edit}
                        errors={errors}
                        setFormData={setFormData}
                      />
                    </div>
                  </>
                ) : ShowForm === "VehicleDetails" ? (
                  <>
                    <p
                      className="mobile-back-fmc mobile-back-pform"
                      onClick={() => handleBackBtn("vehicleDetails")}
                    >
                      <img src={backIcon} alt="" srcSet="" /> Back to Address
                      Details
                    </p>
                    <div className="breakupContainer PropsalFormContainer_child">
                      <div className="PremiumContainer-div">
                        <PremiumContainer
                          CompanyImage={CompanyImage}
                          CompanyName={CompanyName}
                          PolicyTypeValue={bodyData?.data?.PolicyTypeValue}
                          mappingAllData={mappingAllData}
                          bodyData={bodyData}
                        />
                      </div>
                    </div>
                    <div id="PropsalFormContainer_child-formFields" className="formContainer PropsalFormContainer_child">
                      <PropsalFour
                        HandleInputChange={(e) => { handleInputChange(e); }}
                        handleSubmitForm={handleFormReveal}
                        handleBundleInsurer={handleBundleInsurer}
                        handleOdInsurer={handleOdInsurer}
                        hideInsurerInCaseBundleShriRam={hideInsurerInCaseBundleShriRam}
                        prevPolicyNumber={formData.prevPolicyNumber}
                        prevPolicyNameInsurer={formData.prevPolicyNameInsurer}
                        chassisNumber={formData.chassisNumber}
                        engineNumber={formData.engineNumber}
                        carIsInLoan={formData.carIsInLoan}
                        carHypothecationCompany={formData.carHypothecationCompany}
                        handleBackBtn={handleBackBtn}
                        formData={formData}
                        bodyData={bodyData}
                        insurerList={insurerList}
                        handleFinancier={handleFinancier}
                        mappingAllData={mappingAllData}
                        Edit={Edit}
                        businessType={mappingAllData[0].typeOfBusiness}
                        errors={errors}
                        CompanyName={CompanyName}
                        phonestatussloading={phonestatussloading}
                      />
                    </div>
                  </>
                ) : ShowForm === "kycDetails" ? (
                  <>
                    <p
                      className="mobile-back-fmc mobile-back-pform"
                      onClick={() => handleBackBtn("kycDetails")}
                    >
                      <img src={backIcon} alt="" srcSet="" /> Back to Vehicle
                      Details
                    </p>
                    <div className="breakupContainer PropsalFormContainer_child">
                      <div className="PremiumContainer-div">
                        <PremiumContainer
                          CompanyImage={CompanyImage}
                          CompanyName={CompanyName}
                          PolicyTypeValue={bodyData?.data?.PolicyTypeValue}
                          mappingAllData={mappingAllData}
                          bodyData={bodyData}
                        />
                      </div>
                    </div>
                    <div id="PropsalFormContainer_child-formFields" className="formContainer PropsalFormContainer_child formContainer-collapsible">
                      <KycForm
                        handleKycToggle={makeKyc}
                        HandleInputChange={(e) => { handleInputChange(e) }}
                        handleBackBtn={handleBackBtn}
                        kycAadharNumber={formData.kycAadharNumber}
                        kycPanNumber={formData.kycPanNumber}
                        kycProfileImg={formData.kycProfileImg}
                        kycAadharImg={formData.kycAadharImg}
                        kycAadharImgBack={formData.kycAadharImgBack}
                        kycPanImg={formData.kycPanImg}
                        typeOfKycDocument={formData.typeOfKycDocument}
                        AadharImgName={AadharImgName}
                        AadharImgBackName={AadharImgBackName}
                        PanImgName={PanImgName}
                        CINImgName={CINImgName}
                        bodyData={bodyData}
                        ProfileImgName={ProfileImgName}
                        AutoKYCrejected={AutoKYCrejected}
                        setIsAutoKyc={setIsAutoKyc}
                        setmodalIsOpenfail={setmodalIsOpenfail}
                        setmodalIsOpenAdhar={setmodalIsOpenAdhar}
                        modalIsOpenAdhar={modalIsOpenAdhar}
                        modalIsOpenfail={modalIsOpenfail}
                        modalIsOpens={modalIsOpens}
                        setmodalIsOpen={setmodalIsOpen}
                        setAutoKYCrejected={setAutoKYCrejected}
                        isDisabledButton={isDisabledButton}
                        setIsDisabledButton={setIsDisabledButton}
                        setmodalIsOpenfailAdhar={setmodalIsOpenfailAdhar}
                        modalIsOpenfailAdhar={modalIsOpenfailAdhar}
                        CompanyName={CompanyName}
                        carOwner={formData.carOwner}
                        CertificateOfIncorporationNumber={
                          formData.CertificateOfIncorporationNumber
                        }
                        errors={errors}
                        Image={Image}
                        companyName={CompanyName}
                        formData={formData}
                        setErrors={setErrors}
                        setFormData={setFormData}
                        handlekycdata={handlekycdata}
                        setShreeRamKyccorp={setShreeRamKyccorp}
                        shreeRamKycCorp={shreeRamKycCorp}
                        shreeRamKyc={shreeRamKyc}
                        setShreeRamKyc={setShreeRamKyc}
                        failCheker={failCheker}
                        setFailCheker={setFailCheker}
                        ShowSuccesMsg={ShowSuccesMsg}
                        setShowSuccesMsg={setShowSuccesMsg}
                      />
                    </div>
                  </>
                ) : ShowForm === "InspectionDetails" ? (
                  <>
                    <p
                      className="mobile-back-fmc mobile-back-pform"
                      onClick={() => handleBackBtn("InspectionDetails")}
                    >
                      <img src={backIcon} alt="" srcSet="" /> Back to Kyc Details
                    </p>
                    <div className="breakupContainer PropsalFormContainer_child">
                      <div className="PremiumContainer-div">
                        <PremiumContainer
                          CompanyImage={CompanyImage}
                          CompanyName={CompanyName}
                          PolicyTypeValue={bodyData?.data?.PolicyTypeValue}
                          mappingAllData={mappingAllData}
                          bodyData={bodyData}
                        />
                      </div>
                    </div>
                    <div id="PropsalFormContainer_child-formFields" className="formContainer PropsalFormContainer_child">
                      <Inspection
                        HandleInputChange={handleInputChange}
                        handleSubmitForm={handleFormReveal}
                        handleBackBtn={handleBackBtn}
                        emailValue={formData.email}
                        phoneValue={formData.phoneNumber}
                        formData={formData}
                        handleCityDropdown={(e) =>
                          setShowCityDropdown(!ShowCityDropdown)
                        }
                        ShowCityDropdown={ShowCityDropdown}
                        stateList={stateList}
                        cityList={cityList}
                        state={formData.state}
                        city={formData.city}
                        pincode={formData.pincode}
                        addressOne={formData.addressOne}
                        addressTwo={formData.addressTwo}
                      />
                    </div>
                  </>
                ) : ShowForm === "FormDetails" ? (
                  <>
                    <div className="breakupContainer PropsalFormContainer_child">
                      <div className="PremiumContainer-div">
                        <PremiumContainer
                          CompanyImage={CompanyImage}
                          CompanyName={CompanyName}
                          PolicyTypeValue={bodyData?.data?.PolicyTypeValue}
                          mappingAllData={mappingAllData}
                          bodyData={bodyData}
                        />
                      </div>
                    </div>
                    <div id="PropsalFormContainer_child-formFields" className="formContainer PropsalFormContainer_child formContainer-collapsible">
                      <div className="titleContainer  ">
                        <p>Confirm your details</p>
                        <div className="shareLinkBtn" onClick={HandleShareLink}>
                          <img src={shareLink} alt="" />
                          <p>Share Link</p>
                        </div>
                      </div>
                      <Collapsible
                        data={formData}
                        setFormData={setFormData}
                        sbiAutoKycData={sbiAutoKycData}
                        mappingAllData={mappingAllData}
                        setEdit={setEdit}
                        Edit={Edit}
                        HandleEditProposalDetails={HandleEditProposalDetails}
                        handleBackBtn={handleBackBtn}
                        collapsibleTitle={collapsibleTitle}
                        headerCollapsible={headerCollapsible}
                        CompanyName={CompanyName}
                        hdfcKycDate={hdfcKycData?.data?.dob}
                        setAutoKYCrejected={setAutoKYCrejected}
                        tataOtpValue={StoreTataOtp}
                        closeModal={closeModal}
                        openModal={openModal}
                        setShowForm={setShowForm}
                      />
                    </div>
                  </>
                ) :
                  ShowForm === "dealerKyc" ? (
                    <>
                      <p
                        className="mobile-back-fmc mobile-back-pform"
                        onClick={() => navigate("/View-Plans?id=" + id)}
                      >
                        <img src={backIcon} alt="" srcSet="" /> Back to Plans
                      </p>
                      <div className="breakupContainer PropsalFormContainer_child">
                        <div className="PremiumContainer-div" >
                          {vehicleType == "2W" ?
                            <PremiumContainerBike
                              CompanyImage={CompanyImage}
                              CompanyName={CompanyName}
                              PolicyTypeValue={bodyData?.data?.PolicyTypeValue}
                              mappingAllData={mappingAllData}
                              bodyData={bodyData}

                            />
                            : <PremiumContainer
                              CompanyImage={CompanyImage}
                              CompanyName={CompanyName}
                              PolicyTypeValue={bodyData?.data?.PolicyTypeValue}
                              mappingAllData={mappingAllData}
                              bodyData={bodyData}

                            />
                          }
                        </div>
                      </div>
                      <div id="PropsalFormContainer_child-formFields" className="formContainer PropsalFormContainer_child">
                        <DealerPanKyc
                          compNameValue={formData.companyName}
                          kycStatus={ShowKycInputs}
                          formData={formData}
                          errors={errors}
                          errors2={errors2}
                          Edit={Edit}
                          setEdit={setEdit}
                          bodyData={bodyData}
                          vehicleType={vehicleType}
                          handleSubmitForm={handleFormReveal}
                          HandleInputChange={(e) => { handleInputChange(e); }}
                          companyName={CompanyName}
                          sbiAutoKycData={sbiAutoKycData}
                          dateValue={formData.dob}
                          handleBackBtn={handleBackBtn}
                          setShowForm={setShowForm}
                          panDobConataier={panDobConataier}
                          setpanDobConataier={setpanDobConataier}
                          kycPanNumber={formData.kycPanNumber}
                          setFormData={setFormData}
                          setErrors={setErrors}
                          setStepperValue={setStepperValue}
                          StepperValue={StepperValue}
                          setYear={setYear}
                          Year={Year}
                          setMonth={setMonth}
                          Month={Month}
                          setDay={setDay}
                          Day={Day}
                          setcheckphone={setcheckphone}
                          validateInput={validateInput}
                        />
                      </div>
                    </>

                  ) : ""}
            </>
          </div>
        </div>

        <div className="footer-parent-padding" >
          <div className="footer_bottom_container maxWidth  footer_bottom_container_pForm ">
            <p>{bottomFooter[0].contentOne}</p>
          </div>
          <div className="footer_bottom_containerTwo maxWidth ">
            <div className="footer_bottom_containerTwo_part">
              <p> {bottomFooter[0].contentTwo}</p>
              <p>{bottomFooter[0].contentThree}</p>
            </div>
            <div className="footer_bottom_containerTwo_part footer_bottom_containerTwo_part-mob">
              <p>{bottomFooter[0].contentFour}</p>
              <p>{bottomFooter[0].contentFive}</p>
            </div>
          </div>
        </div>
      </div>

      {/* hidden form for tata */}
      <form
        id="myForm"
        action=""
        method="post"
        hidden
      >
        <input type="hidden" name="pgiRequest" value="" />
        <input type="submit" id="invisibleSubmitButtonTata" value="Submit" />
      </form>


    </div>
  );
};

export default ProposalForm;
